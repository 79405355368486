import { useCallback, useEffect, useRef, useState } from "react";
import CardTwo from "../../components/dashBoardComponents/CardTwo";
import axios from "axios";
import pH from "../../images/pH.png";
import N from "../../images/nitrate.png";
import tdsicon from "../../images/tds1.png";
import temp from "../../images/temp.png";
import LineChart from "../../components/apex-chart/LineChart";
import MobileTowerIcon from "../../components/apex-chart/HeatMapChart";
import LongMenu from "../../utils/longMenu/LongMenu";
import {
  formatTimestamp,
  generateCSV1,
  reportDataWithGraph,
} from "../../common-function/CommonFunction";

const baseUrl = process.env.REACT_APP_BASE_URL;
const DashboardWaterQuality2 = ({ selectedDevice, devices }: any) => {
  const [value, setValue] = useState(0);
  const accessToken = localStorage.getItem("token");
  const [apiData, setApiData] = useState<any>([]);
  const chartRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    fetchData();
    const intervalId = setInterval(fetchData, 120000);
    return () => clearInterval(intervalId);
  }, [selectedDevice]);

  const fetchData = async () => {
    let response: any = null;
    try {
      response = await axios.get(baseUrl + `/device-data-log?last24=last24`, {
        params: {
          deviceId: selectedDevice,
        },
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      const _waterQualityDO_Data = response.data
        .map((d: any, i: any) => {
          return {
            timestamp: formatTimestamp(d.timeStamp),
            rssi: d["rssi"],
            nitrate: d["NI"],
            phvalue: d["PH"],
            tdsvalue: d["TDS"],
            tdstemp: d["TEMP"],
          };
        })
        .filter((d: any) => {
          return !Object.values(d).some(
            (val: any) =>
              typeof val === "string" && val.toLowerCase() === "error"
          );
        });
      setApiData(_waterQualityDO_Data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const currentDate = new Date();
  const last24HoursData = apiData.filter((entry: any) => {
    const entryDate = new Date(entry.timestamp);
    const timeDifference = currentDate.getTime() - entryDate.getTime();
    return timeDifference <= 24 * 60 * 60 * 1000; // Include entries up to the current timestamp
  });
  const ph =
    last24HoursData.length > 0
      ? last24HoursData.map(({ timestamp, phvalue }: any) => ({
          timestamp,
          value: phvalue,
        }))
      : [];
  const phGraph: any = [
    {
      name: "PH",
      color: "#3cbd2b",
      data: ph.reverse(),
    },
  ];
  const nitGraph =
    last24HoursData.length > 0
      ? last24HoursData.map(({ timestamp, nitrate }: any) => ({
          timestamp,
          value: nitrate,
        }))
      : [];

  const nitrateGraph: any = [
    {
      name: "Nitrate",
      color: "#3cbd2b",
      data: nitGraph.reverse(),
      postfix: " (mg/L)",
    },
  ];

  const tdsGraph =
    last24HoursData.length > 0
      ? last24HoursData.map(({ timestamp, tdsvalue }: any) => ({
          timestamp,
          value: tdsvalue,
        }))
      : [];

  const tds: any = [
    {
      name: "TDS",
      color: "#3cbd2b",
      data: tdsGraph.reverse(),
      postfix: " (ppm)",
    },
  ];
  const tempGraph =
    last24HoursData.length > 0
      ? last24HoursData.map(({ timestamp, tdstemp }: any) => ({
          timestamp,
          value: tdstemp,
        }))
      : [];

  const tmpGraph: any = [
    {
      name: "TEMPERATURE",
      color: "#3cbd2b",
      data: tempGraph.reverse(),
      postfix: " (°C)",
    },
  ];

  const handleExportBarChart = async (exportOption: string) => {
    let lineChartData =
      value === 0
        ? nitGraph?.map(({ timestamp, value }: any) => {
            return {
              TIMESTAMP: timestamp,
              NITRATE: value,
              UNIT: " mg/L",
            };
          })
        : value === 1
        ? ph?.map(({ timestamp, value }: any) => {
            return {
              TIMESTAMP: timestamp,
              PH: value,
            };
          })
        : value === 2
        ? tdsGraph?.map(({ timestamp, value }: any) => {
            return {
              TIMESTAMP: timestamp,
              TDS: value,
              UNIT: " ppm",
            };
          })
        : tempGraph?.map(({ timestamp, value }: any) => {
            return {
              TIMESTAMP: timestamp,
              TEMPERATURE: value,
              UNIT: " °C",
            };
          });

    switch (exportOption) {
      case "Download as CSV":
        generateCSV1(lineChartData);
        break;
      case "Download as XLS":
        break;
      case "Download as PDF":
        if (lineChartData && chartRef) {
          reportDataWithGraph(
            chartRef,
            lineChartData,
            devices?.deviceName,
            devices?.Customer?.name,
            "Water Quality Summary",
            last24HoursData.length > 0
              ? last24HoursData[last24HoursData.length - 1].timestamp +
                  " - " +
                  last24HoursData[0].timestamp
              : ""
          );
        } else {
        }
        return;
      default:
        return; // Unknown export option
    }
  };

  const handleSetValue = useCallback((newValue: number) => {
    setValue(newValue);
  }, []);

  return (
    <div>
      <p className=" flex p-[10px] mb-[20px]">
        <MobileTowerIcon
          levels={apiData[0]?.rssi || 0}
          time={apiData[0]?.timestamp}
        />
        <p className="ml-2">
          {"    Last Data Received at  " + apiData[0]?.timestamp ||
            "  YYYY-MM-DD HH:MM:SS"}
        </p>
      </p>
      <div className="border border-gray-400 rounded-md p-[10px]  grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-6 xl:grid-cols-4 2xl:gap-7.5">
        <CardTwo
          title="Nitrates"
          amount={`${apiData[0]?.nitrate || 0} mg/L`}
          icon={N}
        />
        <CardTwo title="PH" amount={`${apiData[0]?.phvalue || 0} `} icon={pH} />
        <CardTwo
          title="TDS"
          amount={`${apiData[0]?.tdsvalue || 0} ppm`}
          icon={tdsicon}
        />
        <CardTwo
          title="Temperature"
          amount={`${apiData[0]?.tdstemp || 0} \u2103`}
          icon={temp}
        />
      </div>
      <div className="border border-gray-400 rounded-md mt-[5px]  p-[20px]">
        <div className="relative">
          <p className="mb-[20px]">
            {value === 0
              ? "Nitrates"
              : value === 1
              ? "PH"
              : value === 2
              ? "TDS"
              : "Temperature"}{" "}
            data variation with time (between{" "}
            {last24HoursData.length > 0
              ? last24HoursData[last24HoursData.length - 1].timestamp +
                " to " +
                last24HoursData[0].timestamp
              : " "}
            )
          </p>
          <div className="absolute top-0 right-0">
            <LongMenu
              options={[
                "Download as CSV",
                "Download as PDF",
              ]}
              onOptionClick={handleExportBarChart}
              id={1}
            />
          </div>
        </div>

        <ul className="text-sm font-medium text-center text-gray-500 rounded-lg sm:flex">
          <li className="focus-within:z-10">
            <button
              className={`inline-block border border-gray-300 px-4 py-2 text-[1rem] text-gray-900 rounded-s-lg transition-colors duration-300`}
              aria-current="page"
              style={value === 0 ? { backgroundColor: "#dadada" } : {}}
              onClick={() => {
                handleSetValue(0);
              }}
            >
              Nitrates
            </button>
          </li>

          <li className="focus-within:z-10">
            <button
              className={`inline-block border border-gray-300 px-5 py-2 text-[1rem] text-gray-900 transition-colors duration-300`}
              style={value === 1 ? { backgroundColor: "#dadada" } : {}}
              onClick={() => {
                handleSetValue(1);
              }}
            >
              PH
            </button>
          </li>

          <li className="focus-within:z-10">
            <button
              className={`inline-block border border-gray-300 px-5 py-2 text-[1rem] text-gray-900 transition-colors duration-300`}
              style={value === 2 ? { backgroundColor: "#dadada" } : {}}
              onClick={() => {
                handleSetValue(2);
              }}
            >
              TDS
            </button>
          </li>

          <li className="focus-within:z-10">
            <button
              className={`inline-block border border-gray-300 px-4 py-2 text-[1rem] text-gray-900 rounded-e-lg transition-colors duration-300`}
              style={value === 3 ? { backgroundColor: "#dadada" } : {}}
              onClick={() => {
                handleSetValue(3);
              }}
            >
              Temperature
            </button>
          </li>
        </ul>

        <div ref={chartRef}>
          {value === 0 && (
            <LineChart
              stroke={true}
              yAxisName="Nitrates (mg/L)"
              xAxisName="Time"
              data={nitrateGraph}
            />
          )}
          {value === 1 && (
            <LineChart
              stroke={true}
              yAxisName="PH"
              xAxisName="Time"
              data={phGraph}
            />
          )}
          {value === 2 && (
            <LineChart
              stroke={true}
              yAxisName="TDS (ppm)"
              xAxisName="Time"
              data={tds}
            />
          )}
          {value === 3 && (
            <LineChart
              stroke={true}
              yAxisName="Temperature (°C)"
              xAxisName="Time"
              data={tmpGraph}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default DashboardWaterQuality2;
