import { useEffect, useRef, useState } from "react";
import MobileTowerIcon from "../../components/apex-chart/HeatMapChart";
import { http } from "../../helper/http";
import {
  formatTimestamp,
  generateCSV1,
  reportDataWithGraph,
} from "../../common-function/CommonFunction";
import CardTwo from "../../components/dashBoardComponents/CardTwo";
import LineChart from "../../components/apex-chart/LineChart";

import LongMenu from "../../utils/longMenu/LongMenu";
const baseUrl = process.env.REACT_APP_BASE_URL;
const DashboardVibration1 = ({ devices, selectedDevice }: any) => {
  const [apiData, setApiData] = useState<any[]>([]);
  const [dashboardSts, setDashboardSts] = useState<any>([]);
  const chartRef = useRef<HTMLDivElement>(null);
  const fetchData = async () => {
    let response: any = null;
    try {
      response = await http(
        baseUrl + `/device-data-log?deviceId=${selectedDevice}?last24=last24`
      );

      const _data = response.map((d: any, i: any) => {
        return {
          timestamp: formatTimestamp(d.timeStamp),
          rssi: d["rssi"],
          x: d["x"],
          y: d["y"],
          z: d["z"],
        };
      }) .filter((d: any) => {
        return !Object.values(d).some(
          (val: any) =>
            typeof val === "string" && val.toLowerCase() === "error"
        );
      });
      setApiData(_data);
      setDashboardSts(_data.length ? _data[0] : "");
      
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
    const intervalId = setInterval(fetchData, 120000);
    return () => clearInterval(intervalId);
  }, [selectedDevice]);

  const currentDate = new Date();
  const last24HoursData = apiData.filter((entry) => {
    const entryDate = new Date(entry.timestamp);
    const timeDifference = currentDate.getTime() - entryDate.getTime();
    return timeDifference <= 24 * 60 * 60 * 1000; // Include entries up to the current timestamp
  });

  const x =
    last24HoursData.length > 0
      ? last24HoursData.map(({ timestamp, x }: any) => ({
          timestamp,
          value: x,
        }))
      : [];

  const y =
    last24HoursData.length > 0
      ? last24HoursData.map(({ timestamp, y }: any) => ({
          timestamp,
          value: y,
        }))
      : [];

  const z =
    last24HoursData.length > 0
      ? last24HoursData.map(({ timestamp, z }: any) => ({
          timestamp,
          value: z,
        }))
      : [];

  const xyz: any = [
    {
      name: "X",
      color: "#23ad41",
      showDots: false,
      showHoverTooltip: false,
      data: x.reverse(),
    },
    {
      name: "Y",
      color: "#d1172a",
      showDots: false,
      showHoverTooltip: false,
      data: y.reverse(),
    },

    {
      name: "Z",
      color: "#1730d1",
      showDots: false,
      showHoverTooltip: false,
      data: z.reverse(),
    },
  ];

  const xyzpdf =
    last24HoursData.length > 0
      ? last24HoursData.map(({ timestamp, z, x, y }: any) => ({
          timestamp,
          x: x,
          y: y,
          z: z,
        }))
      : [];

  const handleExportBarChart = async (exportOption: string) => {
    switch (exportOption) {
      case "Download as CSV":
        generateCSV1(last24HoursData);
        break;
      case "Download as PDF":
        if (xyzpdf && chartRef) {
          reportDataWithGraph(
            chartRef,
            xyzpdf,
            devices.deviceName,
            devices.Customer.name,
            "Vibration Summary",
            last24HoursData.length > 0
              ? last24HoursData[last24HoursData.length - 1].timestamp +
                  " - " +
                  last24HoursData[0].timestamp
              : ""
          );
        } else {
        }
        return;
      default:
        return; // Unknown export option
    }
  };

  return (
    <div>
      <p className=" flex  p-[10px] mb-[20px]">
        <MobileTowerIcon levels={dashboardSts.rssi || 0} time={dashboardSts?.timestamp}/>
        <p className="ml-2">
          {"    Last Data Received at  " + dashboardSts?.timestamp ||
            "  YYYY-MM-DD HH:MM:SS"}
        </p>
      </p>

      <div className="border border-gray-400 rounded-md grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-6 xl:grid-cols-4 2xl:gap-7.5  p-2">
        <CardTwo
          title=" X Axis"
          amount={dashboardSts && dashboardSts.x ? `${dashboardSts.x} ` : "0"}
        />
        <CardTwo
          title="Y Axis"
          amount={dashboardSts && dashboardSts.y ? `${dashboardSts.y} ` : "0"}
        />
        <CardTwo
          title="Z Axis"
          amount={dashboardSts && dashboardSts.z ? `${dashboardSts.z} ` : "0"}
        />
      </div>
      <div className="mt-2 mb-8 border border-gray-400 rounded-md">
        <div className="relative p-3">
          <p className="mb-[20px]">
            Vibration data variation with time (between{" "}
            {last24HoursData.length > 0
              ? last24HoursData[last24HoursData.length - 1].timestamp +
                " to " +
                last24HoursData[0].timestamp
              : " "}
            )
          </p>
          <div className="absolute top-0 right-0">
            <LongMenu
              options={[
                "Download as CSV",
                "Download as PDF",
              ]}
              onOptionClick={handleExportBarChart}
              id={1}
            />
          </div>
        </div>
        <div ref={chartRef || ""} className="p-3">
          <LineChart
            stroke={true}
            yAxisName="X-Axis  Y-Axis  Z-Axis"
            xAxisName="Time"
            data={xyz}
          />
        </div>
      </div>
    </div>
  );
};

export default DashboardVibration1;
