import { createContext, Dispatch, ReactNode, useContext, useEffect, useReducer } from "react";
import scheduleReducer, { ScheduleState } from "./SchedulingReducer";
import { ScheduleAction, setSchedule } from "./SchedulingAction";



interface ScheduleContextProps{
    state: ScheduleState;
    dispatch: Dispatch<ScheduleAction>;
}

const initialState: ScheduleState = {
    schedulings: {
        schedulings: [],
        currentPage: 0,
        totalPages: 0,
        previousPage: 0,
        totalItems: 0
    }
}

export const ScheduleContext = createContext<ScheduleContextProps>({
    state: initialState,
    dispatch: () => {},
})

interface ScheduleProviderProps {
    children: ReactNode;
}

export const ScheduleProvider: React.FC<ScheduleProviderProps> = ({ children }) => {
    const [state, dispatch] = useReducer(scheduleReducer, initialState);

    return (
        <ScheduleContext.Provider value={{ state, dispatch }}>
            {children}
        </ScheduleContext.Provider>
    )
}

export const useScheduleState = () => {
    const { state, dispatch } = useContext(ScheduleContext);
    return { ...state, dispatch }
}