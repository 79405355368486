import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Tag, TagApiResponse } from "../../../types/TagTypes";
import { http } from "../../../helper/http";
import { toast } from "react-toastify";
import { addNewTags, updateTag } from "../../../app-context/tags-context/TagAction";
import { useTagState } from "../../../app-context/tags-context/TagState";
import { Customer } from "../../../types/CustomerTypes";
import { useCustomerState } from "../../../app-context/customer-context/CustomerState";
import ProtectedComponent from "../../../utils/protected-route/ProtectedComponent";
import { fetchAllCustomer } from "../../../common-function/CommonFunction";

interface AddTagFormModalProps {
  toggleForm: boolean;
  toggleFormFun: () => void;
  data: Tag[];
  uniqueId: string;
}

let id: number | 0;
const baseUrl = process.env.REACT_APP_BASE_URL;

const AddTagForm: React.FC<AddTagFormModalProps> = ({
  toggleForm,
  toggleFormFun,
  data,
  uniqueId,
}) => {

  const [tagname, setTagname] = useState("")
  const [unit, setUnit] = useState("")
  const [factorialvalue, setFactorialValue] = useState("")
  const [customer, setCustomer] = useState<Customer[]>([]);
  const [selectedCustomer, setSelectedCustomer] = useState("");
  const { tags, dispatch } = useTagState();
  const { customers } = useCustomerState();

  useEffect(() => {
      const fetchData = async () => {
        try {
          const customerResp = (await fetchAllCustomer()) as any;
          setCustomer(customerResp.customers || []);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
      fetchData();
    if (uniqueId === "EDIT" && data) {
      const tagToUse = Array.isArray(data) ? data[0] : data;
      id = tagToUse.id;
      setTagname(tagToUse.tagname || "");
      const cust = customers.customers.find((c) => c.id === tagToUse.customerId)
      setSelectedCustomer(cust?.id.toString() || "")
      setUnit(tagToUse.unit.toString() || "");
      setFactorialValue(tagToUse.factorialvalue.toString())
    }
  }, [uniqueId, data]);

  const handleSubmit = async () => {
    const data = {
      tagname,
      unit,
      factorialvalue,
      customerId: selectedCustomer
    };
    try {
      if (uniqueId === "ADD") {
        const resp = (await http(
          baseUrl + `/tags`,
          "POST",
          data
        )) as TagApiResponse;
        // console.log(resp);

        addNewTags(dispatch, { tags: resp.tags, totalItems: tags?.totalItems || 0 });
        toast.success(resp.message);
      } else {
        const resp = (await http(
          baseUrl + `/tags?id=${id}`,
          "PUT",
          data
        )) as TagApiResponse;
        updateTag(dispatch, { id, tags: resp.tags });
        toast.success(resp.message);
      }
    } catch (err: any) {
      toast.error(err);
    }
    toggleFormFun();

  }


  return (
    <>
      {/* Popup onclick of Add button */}
      <Dialog open={toggleForm} onClose={toggleFormFun}>
        <DialogTitle>
          {" "}
          {uniqueId === "ADD" ? "Add Tag" : "Update Tag"}
        </DialogTitle>
        <DialogContent>
          {/* Input fields for adding a new device */}
          <TextField
            label="Tag Name"
            placeholder="Enter"
            onChange={e => setTagname(e.target.value)}
            value={tagname}
            fullWidth
            margin="normal"
          />

          {/* <InputLabel id="selectunit">Select the Unit</InputLabel>
          <Select
            labelId="selectunit"
            id="select-unit"
            value={unit}
            label="Select Unit"
              onChange={(e)=>setUnit(e.target.value)}
            sx={{ width: "100%" }}
          >
            <MenuItem value="master1">Master1</MenuItem>
            <MenuItem value="master2">Master2</MenuItem>
            <MenuItem value="master3">Master3</MenuItem>
          </Select> */}
          <ProtectedComponent>
          <InputLabel id="selectunit">Select the Customer</InputLabel>
          <Select
            labelId="selectcustmer"
            id="select-customer"
            value={selectedCustomer}
            label="Select Customer"
            onChange={(e) => setSelectedCustomer(e.target.value)}
            sx={{ width: "100%" }}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 300, // Adjust the maxHeight as needed
                },
              },
            }}
          >
            {customer.map((customer) => (
              <MenuItem key={customer.id} value={customer.id} style={{paddingLeft: "20px", display: "block", marginBottom: "5px"}}>
                {customer.name}
              </MenuItem>
            ))}
          </Select>
          </ProtectedComponent>

          <TextField
            label="Unit"
            placeholder="Enter Unit"
            fullWidth
            margin="normal"
            onChange={e => setUnit(e.target.value)}
            value={unit}
          />

          <TextField
            label="Factoring Value"
            placeholder="Enter"
            fullWidth
            margin="normal"
            onChange={e => setFactorialValue(e.target.value)}
            value={factorialvalue}
          />

          {/* <TextField
              label="Min/Max Threshold"
              placeholder="Enter"
              fullWidth
              margin="normal"
            />
            <TextField
              label="Current Value"
              placeholder="Enter"
              fullWidth
              margin="normal"
            /> */}
        </DialogContent>
        <DialogActions>
          <Button onClick={toggleFormFun}>Cancel</Button>
          <Button onClick={handleSubmit} color="primary">
            {uniqueId === "ADD" ? "Save" : "Update"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddTagForm;
