import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  ListItemText,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import React, { useEffect, useState, useRef, ChangeEvent } from "react";

import { toast } from "react-toastify";
import {
  UserOnboard,
  UserOnboardApiResponse,
} from "../../../types/UserOnboardTypes";
import { useCustomerState } from "../../../app-context/customer-context/CustomerState";
import { Customer } from "../../../types/CustomerTypes";
import { http } from "../../../helper/http";
import {
  addNewUserOnBoard,
  updateUserOnBoard,
} from "../../../app-context/user-context/UserAction";
import { useUserOnBoardState } from "../../../app-context/user-context/UserState";
import ProtectedComponent from "../../../utils/protected-route/ProtectedComponent";

interface AddUserModalProps {
  toggleForm: boolean;
  toggleFormFun: () => void;
  data: UserOnboard[];
  uniqueId: string;
}

let id: number | 0;
const baseUrl = process.env.REACT_APP_BASE_URL;

const AddUserForm: React.FC<AddUserModalProps> = ({
  toggleForm,
  toggleFormFun,
  data,
  uniqueId,
}) => {
  const [customer, setCustomer] = useState<Customer[]>([]);
  const [selectedCustomer, setSelectedCustomer] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [showCustomers, setShowCustomers] = useState(false);
  const [loginRedirectPage, setLoginRedirectPage] =
    useState<string>("overview");
  const [menu, setMenu] = useState<any>({
    "alert": false,
    "report": false,
    "scheduling": false,
  });

  const { customers } = useCustomerState();
  const { dispatch } = useUserOnBoardState();

  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setCustomer(customers.customers);
  }, [name, email, customers.customers]);

  useEffect(() => {
    if (uniqueId === "EDIT" && data) {
      const user = Array.isArray(data) ? data[0] : data;
      id = user?.id;
      setName(user.name);
      setEmail(user.email);
      const customerData = customers.customers.find(
        (c) => c.id === (user?.customerId || null)
      );
      setSelectedCustomer(customerData?.id.toString() || "");
      setLoginRedirectPage(user.loginRedirectPage);
      setMenu(user.menu);
    }
  }, [uniqueId, data]);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setShowCustomers(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleSubmit = async () => {
    const data = {
      name,
      email,
      password,
      customerId: selectedCustomer,
      loginRedirectPage,
      menu,
    };

    try {
      if (uniqueId === "ADD") {
        const resp = (await http(
          baseUrl + `/user?newUser=newUser`,
          "POST",
          data
        )) as UserOnboardApiResponse;
        addNewUserOnBoard(dispatch, {
          userOnboards: resp.users,
          totalItems: customers.totalItems,
        });
        toast.success(resp.message);
      } else {
        const resp = (await http(
          baseUrl + `/user?id=${id}`,
          "PUT",
          data
        )) as UserOnboardApiResponse;
        updateUserOnBoard(dispatch, { id: id, userOnboards: resp.users });
        toast.success(resp.message);
      }
    } catch (error: any) {
      // console.log(error);
      toast.error(error);
    }
    toggleFormFun();
  };

  const handleLoginRedirect = (event: SelectChangeEvent<string>) => {
    setLoginRedirectPage(event.target.value);
  };

  const options = Object.keys(menu).map((key) => ({
    label: key,
    value: key,
    checked: menu[key],
  }));

  const handleChangeOptions = (event: any) => {
    const selectedValues: any = event.target.value;

    setMenu((prevOptions: any) => {
      const newOptions = { ...prevOptions };
      Object.keys(newOptions).forEach((key) => {
        newOptions[key] = selectedValues.includes(key);
      });
      return newOptions;
    });
  };

  const filteredCustomers = customer.filter((c) =>
    c.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <>
      <Dialog open={toggleForm} onClose={toggleFormFun}>
        <DialogTitle>
          {uniqueId === "ADD" ? "Add User" : "Update User"}
        </DialogTitle>
        <DialogContent>
          <TextField
            label="Name"
            placeholder="Enter The Name"
            fullWidth
            margin="normal"
            value={name}
            onChange={(event) => {
              setName(event.target.value);
            }}
          />
          <TextField
            label="Email"
            placeholder="Enter The Email"
            fullWidth
            margin="normal"
            value={email}
            onChange={(event) => {
              setEmail(event.target.value);
            }}
          />
          {uniqueId !== "EDIT" && (
            <TextField
              label="Password"
              placeholder="Enter The Password"
              fullWidth
              margin="normal"
              value={password}
              onChange={(event) => {
                setPassword(event.target.value);
              }}
            />
          )}
          <ProtectedComponent>
            <InputLabel id="selectunit">Select the Customer</InputLabel>
            <div ref={dropdownRef}>
              <TextField
                fullWidth
                value={
                  selectedCustomer
                    ? customer.find((c) => c.id.toString() === selectedCustomer)
                      ?.name
                    : ""
                }
                onClick={() => setShowCustomers(!showCustomers)}
                placeholder="Select Customer"
                InputProps={{
                  readOnly: true,
                }}
              />
              {showCustomers && (
                <div
                  style={{
                    maxHeight: "200px",
                    overflowY: "auto",
                    border: "1px solid #ccc",
                    borderRadius: "4px",
                  }}
                >
                  <TextField
                    fullWidth
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    placeholder="Search customers..."
                  />
                  {filteredCustomers.map((c) => (
                    <MenuItem
                      key={c.id}
                      value={c.id}
                      onClick={() => {
                        setSelectedCustomer(c.id.toString());
                        setShowCustomers(false);
                      }}
                      style={{
                        paddingLeft: "20px",
                        display: "block",
                        marginBottom: "5px",
                      }}
                    >
                      {c.name}
                    </MenuItem>
                  ))}
                </div>
              )}
            </div>
          </ProtectedComponent>
            <InputLabel>Login Redirect</InputLabel>
          <FormControl fullWidth>
            <Select
              value={loginRedirectPage}
              onChange={handleLoginRedirect}
            >
              <MenuItem value="overview">Overview</MenuItem>
              <MenuItem value="ambientDashboard">Ambient Monitoring Dashboard</MenuItem>
              <MenuItem value="acDashboard">AC Monitoring Dashboard</MenuItem>
            </Select>
          </FormControl>
          <InputLabel>Select Sidebar Options</InputLabel>
          <FormControl fullWidth>
            <Select
              multiple
              value={options
                .filter((option) => option.checked)
                .map((option) => option.value)}
              onChange={handleChangeOptions}
              renderValue={(selected) => (
                <div>
                  {options
                    .filter((option) => option.checked)
                    .map((option) => option.label)
                    .join(", ")}
                </div>
              )}
            >
              {options.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  <Checkbox checked={option.checked} />
                  <ListItemText primary={option.label} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={toggleFormFun}>Cancel</Button>
          <Button onClick={handleSubmit} color="primary">
            {uniqueId === "ADD" ? "Save" : "Update"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddUserForm;
