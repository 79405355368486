import React, {
  createContext,
  useContext,
  useEffect,
  useReducer,
  ReactNode,
  Dispatch,
} from "react";
import CustomerReducer, { CustomerState } from "./CustomerReducer";
import { CustomerAction, setCustomer } from "./CustomerAction";



interface CustomerContextProps {
  state: CustomerState;
  dispatch: Dispatch<CustomerAction>;
}

const initialState: CustomerState = {
  customers: {
    customers: [],
    currentPage: 0,
    totalPages: 0,
    previousPage: 0,
    totalItems: 0
  },
};

export const CustomerContext = createContext<CustomerContextProps>({
  state: initialState,
  dispatch: () => {},
});

interface CustomerProviderProps {
  children: ReactNode;
}

export const CustomerProvider: React.FC<CustomerProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(CustomerReducer, initialState);

  // useEffect(() => {
  //   setCustomer(dispatch, 1);
  // }, []);

  return (
    <CustomerContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      {children}
    </CustomerContext.Provider>
  );
};

export const useCustomerState = () => {
  const { state, dispatch } = useContext(CustomerContext);
  return { ...state, dispatch };
};
