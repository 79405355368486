import { useEffect, useRef, useState } from "react";
import DynamicCard from "../../components/waterDashboardComponents/DynamicCard";
import SignalStrength from "../../components/waterDashboardComponents/SignalStrength";
import TabularComponents from "../../components/deviceComponents/TabularComponents";
import { http } from "../../helper/http";
import { IconButton, Dialog, DialogTitle, DialogContent } from "@mui/material";
import { AspectRatio, ImportExport, Search } from "@mui/icons-material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import LineChart3 from "../../components/dashBoardComponents/stepAfterchart2";
import LongMenu from "../../utils/longMenu/LongMenu";
import {
  formatTimestamp,
  generateCSV1,
  reportDataWithGraph,
} from "../../common-function/CommonFunction";

const DashboardPiezo = ({ selectedDevice, devices }: any) => {
  const [data, setData]: any = useState([]);
  const [currentData, setCurrentData]: any = useState({ level: "", rssi: 0 });
  const [isExpanded, setIsExpanded] = useState(false);
  const chartRef = useRef<HTMLDivElement>(null);
  const [levelGraphData, setLevelGraphData]: any = useState([]);

  async function fetchData() {
    if (!selectedDevice) {
      return;
    }
    try {
      const resp: any = await http(
        "/device-data-log?deviceId=" + selectedDevice
      );

      const _data = resp.map((d: any) => {
        return {
          level: d["Piezo Level"],
          timestamp: formatTimestamp(d.timeStamp),
          rssi: Math.round((+d.rssi / 35) * 100),
        };
      });
      setData(_data);

      if (_data.length) {
        setCurrentData(_data[0]);
      }
    } catch (err) {
      console.error(err);
    }
  }

  useEffect(() => {
    fetchData();
  }, [selectedDevice]);

  const handleExportToExcel = () => {
    const csvContent = "data:text/csv;charset=utf-8,";
    const header = "Timestamp,Level\n";
    const dataRows = data
      .map((row: any) => `${row.timestamp},${row.level}`)
      .join("\n");
    const encodedUri = encodeURI(csvContent + header + dataRows);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "exported_data.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
    // console.log(isExpanded);
  };


  function prepareFlowGraphData() {
    const currentDate = new Date();
    currentDate.setHours(currentDate.getHours() - 12);
    const _data: any = data.filter(
      (d: any) => new Date(d.timestamp) >= currentDate
    );

    const levelData: any = [];
    if (_data.length) {
      let date: any = new Date();
      date.setHours(date.getHours() - 12);
      for (let i = 0; i < 12; i++) {
        const d: any = {
          x: i,
          y: getLevelDataByTime(_data, date),
        };
        date.setHours(date.getHours() + 1);
        levelData.push(d);
      }
    }
    setLevelGraphData(levelData);
  }

  function getLevelDataByTime(data: any, date: any) {
    const currentDate = new Date(date);
    const nextDate = new Date(date);
    nextDate.setHours(currentDate.getHours() + 1);
    const _data: any = data.filter(
      (d: any) =>
        new Date(d.timestamp) >= currentDate && new Date(d.timestamp) < nextDate
    );
    if (_data.length) {
      return _data[0].level;
    } else {
      return 0;
    }
  }

  useEffect(() => {
    prepareFlowGraphData();
  }, [data]);

  const filteredRows = data.filter(
    (row: { level: string }) =>
      row.level.toLowerCase() !== "error".toLowerCase()
  );
  //FILTER THE LAST 24 HOUR DATA AND RETURN
  const currentDate = new Date();
  const last24HoursData = filteredRows.filter(
    (entry: { timestamp: string | number | Date }) => {
      const entryDate = new Date(entry.timestamp);
      const timeDifference = currentDate.getTime() - entryDate.getTime();
      return timeDifference <= 24 * 60 * 60 * 1000; // 24 hours in milliseconds
    }
  );

  const handleExportBarChart = async (exportOption: string) => {
    switch (exportOption) {
      case "Download as CSV":
        generateCSV1(levelGraphData);
        break;
      case "Download as XLS":
        break;
      case "Download as PDF":
        if (levelGraphData && chartRef) {
          reportDataWithGraph(
            chartRef,
            levelGraphData,
            devices?.deviceName,
            devices?.Customer?.name,
            "Water Quality Summary",
            "Realtime - Last 24 Hour | IST"
          );
        } else {
        }
        return;
      default:
        return; // Unknown export option
    }
  };

  return (
    <div className="dashboard-layout">
      <div className="flex flex-row gap-6">
        <div className="card-layout basis-1/4">
          <div className="flex flex-col gap-5">
            <DynamicCard
              customComponent={<SignalStrength percentage={currentData.rssi} />}
              width="300px"
              height="300px"
            />
          </div>
        </div>
        <div
          className="chart-table-layout basis-3/4"
          style={{ boxShadow: "rgba(0, 0, 0, 0.14) 1px 1px 11px 0px" }}
        >
          <div className="flex flex-col gap-5">
            <div>
              <div className="relative">
                <h1 className="relative p-[1rem] h-fit w-[15rem]">
                  <p>Water Level</p>
                  <p className="text-sm pt-[0.2rem] text-gray-600">
                    <AccessTimeIcon className="pr-[0.2rem]" />
                    Realtime - Last 24 Hour | IST
                  </p>
                </h1>
                <div className="absolute top-0 right-0">
                  <IconButton>
                    <LongMenu
                      options={[
                        "Download as CSV",
                        "Download as PDF",
                      ]}
                      onOptionClick={handleExportBarChart}
                      id={1}
                    />
                  </IconButton>
                </div>
                <div ref={chartRef}>
                  <LineChart3 height="400px" 
                  flow={levelGraphData as any}
                   />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="mb-10 mt-5"
        style={{ boxShadow: "rgba(0, 0, 0, 0.14) 1px 1px 11px 0px" }}
      >
        <div className="flex justify-between p-4">
          <p className="font-semibold text-lg text-slate-600">Data Table</p>
          <div>
            <IconButton>
              <Search />
            </IconButton>
            <IconButton onClick={handleExportToExcel}>
              <ImportExport />
            </IconButton>
            <IconButton onClick={toggleExpand}>
              <AspectRatio />
            </IconButton>
            {isExpanded ? (
              <>
                <Dialog maxWidth="xl" open={true} onClose={toggleExpand}>
                  <DialogTitle className="font-semibold text-lg text-slate-600">
                    Data Table
                  </DialogTitle>
                  <DialogContent>
                    <div className="flex items-center">
                      <AccessTimeIcon color="action" fontSize="large" />
                      <span className="text-slate-500 ml-2 text-lg">
                        Realtime - Last 24 Hour | IST
                      </span>
                    </div>
                    <TabularComponents
                      headers={["Timestamp", "Level"]}
                      rows={data}
                      pagination={true}
                      sorting={false}
                    />
                  </DialogContent>
                </Dialog>
              </>
            ) : (
              <></>
            )}
            {/* {isExpanded} */}
          </div>
        </div>

        <TabularComponents
          headers={["Timestamp", "Level"]}
          rows={last24HoursData}
        />
      </div>
    </div>
  );
};

export default DashboardPiezo;
