import { TableCell, TableHead, TableRow } from '@mui/material';
import React from 'react'

function EnhancedTableHeader() {
    const tableCellsData = [
        { label: "Device Name", id: "deviceName" },
        { label: "Device Alias", id: "deviceAlias" },
        { label: "AC Status", id: "ac_status" },
        { label: "Temp (C)", id: "temp" },
        { label: "Humidity (%)", id: "hum" },
        { label: "Set Temp (C)", id: "set_temp" },
        { label: "SCH. Time", id: "scheduleTime" },
        { label: "SCH. Status", id: "scheduleStatus" },
        { label: "Device Status", id: "status" },
      ];
    return (
        <TableHead className="bg-gray-800 sticky top-0 z-10">
          <TableRow className="border-b">
            {tableCellsData.map((cellData) => (
              <TableCell
                key={cellData.id}
                padding="normal"
                className="p-2"
                sx={{ color: "white", fontWeight: 400, textAlign: "start" }}
              >
                {cellData.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
      );
}

export default EnhancedTableHeader