import { Device } from "./../types/DeviceTypes";
import jsPDF from "jspdf";
import frinsologo from "../images/logoblue.jpeg";
import "jspdf-autotable";
import {
  formatTimestamp,
  processConsumptionDataTotalizer,
  processHourlyDataTotalizer,
} from "./CommonFunction";
import { http } from "../helper/http";

function getPreviousDayData(data: any[]): any[] {
  // Get the current date
  const today = new Date();
  // Calculate the date for the previous day
  const previousDay = new Date(today);
  previousDay.setDate(today.getDate() - 1);
  // Format previous day's date as "yyyy-mm-dd" for comparison
  const previousDayFormatted = `${previousDay.getFullYear()}-${String(
    previousDay.getMonth() + 1
  ).padStart(2, "0")}-${String(previousDay.getDate()).padStart(2, "0")}`;
  // Filter the array to get the data for the previous day
  const previousDayData = data.filter((item) =>
    item.timeStamp.startsWith(previousDayFormatted)
  );

  return previousDayData;
}

export async function getReportAll(
  deviceData: any[],
  name: String,
  fileName = "report.pdf"
) {
  try {
    const pdf = new jsPDF();
    const fontSize = 8; // Reduce font size
    let fontStyle = "helvetica";

    const pageWidth = pdf.internal.pageSize.getWidth();
    const pageHeight = pdf.internal.pageSize.getHeight();

    const responsiveFontSize = fontSize * (pageWidth / 210);

    pdf.setFont(fontStyle);
    pdf.setFontSize(responsiveFontSize);

    const logoWidth = 50;
    const logoHeight = 20;
    pdf.addImage(frinsologo, "JPEG", 15, 10, logoWidth, logoHeight);

    pdf.setFontSize(14);
    pdf.text("FRINSO TECHNOLOGIES PVT. LTD.", 120, 20);

    let isFirstDevice = true; // Flag to indicate the first device
    let startY: number;
    let additionalInfoY = 50; // Initialize additionalInfoY with a default value

    deviceData.forEach(async (d, index) => {
      const deviceName = d["deviceName"];
      const data: any = d["data"];
      const template = d["template"];
      if (data.data.length === 0) {
        return; // If no data, move to the next iteration
      }
      const [columns, processedData] = await findColumnsAndData(
        template,
        data.data,
        ""
      );

      if (processedData.length === 0) return;
      if (!isFirstDevice) {
        pdf.addPage();
      } else {
        isFirstDevice = false; // Update the flag after processing the first device
      }

      startY = isFirstDevice ? 20 : 25; // Set startY based on whether it's the first device

      pdf.setFontSize(16);
      pdf.text(`Device Name : ${deviceName}`, 15, startY + 12);

      const deviceNameTextHeight = 2 * (pageWidth / 120); // Assuming fontSize is 16
      startY += deviceNameTextHeight + 10; // Adjust the value according to your preference

      const tableRows = processedData.map((entry: any) =>
        columns?.map((column: any) => {
          return entry[column];
        })
      );

      pdf.setFontSize(10);
      pdf.text(" ", 200, startY + 15);
      const uppercase = columns?.map((key: any) => key.toUpperCase()) || "";

      // Split the table if the number of columns exceeds 8
      let tableStartY = startY;

      (pdf as any).autoTable({
        head: [uppercase],
        body: tableRows,
        startY: tableStartY,
      });
      tableStartY += (pdf as any).previousAutoTable.finalY + 10;
      isFirstDevice = false; // Update the flag after processing the first device
      additionalInfoY = tableStartY;
    });

    pdf.setFontSize(10);
    const generatedOnText = `Report Generated On: ${new Date().toLocaleString()}`;
    const generatedOnTextY = pageHeight - 10; // Adjust 10 as needed
    pdf.text(generatedOnText, 20, generatedOnTextY);
    pdf.text("Report Generated By : " + name, 20, generatedOnTextY + 3);

    pdf.save(fileName);
  } catch (error) {
    console.error(error);
  }
}

interface DataItem1 {
  timeStamp: string;
  Totalizer: string;
}

interface HourlyData1 {
  firstTimeStamp: string;
  lastTimeStamp: string;
  firstTotalizer: string;
  lastTotalizer: string;
  hour: string;
}
export function filterDataFromEachHourTotalizer(
  data: DataItem1[]
): HourlyData1[] {
  data.sort(
    (a, b) => new Date(a.timeStamp).getTime() - new Date(b.timeStamp).getTime()
  );

  const result: HourlyData1[] = [];

  // Process the data
  data.forEach((item) => {
    const hourTimestamp = item.timeStamp.slice(0, 13); // Adjust the timestamp format here

    const index = result.findIndex(
      (hourlyData) => hourlyData.hour === hourTimestamp
    );

    if (index === -1) {
      // If the hour doesn't exist in the result array, initialize it
      result.push({
        firstTimeStamp: item.timeStamp,
        lastTimeStamp: item.timeStamp,
        firstTotalizer: item.Totalizer,
        lastTotalizer: item.Totalizer,
        hour: hourTimestamp,
      });
    } else {
      // Update the lastTimeStamp and lastPositivecumulative for the current hour
      result[index].lastTimeStamp = item.timeStamp;
      result[index].lastTotalizer = item.Totalizer;
    }
  });

  return result;
}

export async function generateCSV1(
  data: any[],
  template: number,
  device: any,
  deviceTemplate = null,
  customTag = null
) {
  if (data.length === 0) {
    console.error("Data array is empty");
    return;
  }

  // Extract column names from the keys of the first item in the data array
  // const columns = Object.keys(data[0]).filter((key) => key !== "logId");

  const [columns, processedData] = await findColumnsAndData(
    template,
    data,
    "KEY",
    device.id,
    deviceTemplate,
    customTag,
    device
  );

  // Prepare CSV content
  let csvContent = "";

  // Add header row with column names
  csvContent += columns.join(",") + "\n";

  // Add data rows
  processedData.forEach((item: any) => {
    const row = columns.map((column: string) => {
      return item[column] !== undefined ? item[column] : "";
    });
    csvContent += row.join(",") + "\n";
  });

  // Initiate download
  initiateDownload(csvContent);
}
// export function generateCSV(columns: string[], data: any[]) {
//   // Prepare CSV content
//   console.log('columns', columns);
//   console.log('data', data);
//   let csvContent = "";

//   // Add header row with column names
//   csvContent += columns.join(",") + "\n";

//   // Add data rows
//   data.forEach((item: any) => {
//     const row = columns.map((column: string) => {
//       // Normalize the column name for matching with object keys
//       const normalizedColumn = column.toLowerCase().replace(/ /g, "").replace("#", "");
//       const matchingKey = Object.keys(item).find(
//         (key) => key.toLowerCase().replace(/ /g, "") === normalizedColumn
//       );
//       return matchingKey !== undefined ? item[matchingKey] : "";
//     });
//     csvContent += row.join(",") + "\n";
//   });

//   // Create a Blob containing the CSV data
//   const blob = new Blob([csvContent], { type: "text/csv" });

//   // Create a temporary anchor element to trigger the download
//   const link = document.createElement("a");
//   link.href = URL.createObjectURL(blob);
//   link.download = "data.csv";
//   link.click();
// }

// export function generateCSV(
//   deviceData: any[],
//   name: String,
//   fileName = "report.csv",
//   reportDuration: String
// ) {
//   console.log('fileName',fileName)
//   console.log('reportDuration', reportDuration);
//   try {

//     let csvContent = "";
//     let isFirstDevice = true; // Flag to indicate the first device
//     let filterData: any[] = [];

//     deviceData.forEach((d, index) => {
//       const deviceName = d["deviceName"];
//       const data: any = d["data"];
//       const template = d["template"];

//       if (data.data.length === 0 && deviceData.length - 1!== index) {
//         return; // If no data, move to the next iteration
//       }

//       const [columns, processedData] = findColumnsAndData1(
//         template,
//         data.data,
//         "",
//         deviceName
//       );

//       if (template === 9 && index!== deviceData.length - 1) {
//         filterData = filterData.concat(processedData);
//         return;
//       } else {
//         filterData = filterData.concat(processedData);
//       }
//       if (processedData.length === 0 && deviceData.length - 1!== index) {
//         return;
//       }

//       if (!isFirstDevice) {
//         csvContent += `\n`; // Add a newline for each device
//       } else {
//         isFirstDevice = false; // Update the flag after processing the first device
//       }

//       let pData = filterData && filterData.length > 0? filterData : processedData;

//       const tableRows = pData.map((entry: any) =>
//         columns?.map((column: any) => {
//           return entry[column];
//         })
//       );

//       csvContent += `${columns.join(",")}\n`; // Add the column headers
//       tableRows.forEach((row: any[]) => {
//         csvContent += `${row.join(",")}\n`;
//       });

//       filterData = [];
//     });

//     const blob = new Blob([csvContent], { type: 'text/csv' });
//     const link = document.createElement('a');
//     link.href = URL.createObjectURL(blob);
//     link.download = fileName;
//     link.click();
//   } catch (error) {
//     console.log(error);
//   }
// }

export function generateCSV(
  data: any[],
  filename: string,
  reportTitle: string
) {
  // Prepare CSV content
  const columns = Object.keys(data[0]);
  let csvContent = "";

  // Add header row with column names
  csvContent += columns.join(",") + "\n";

  // Add data rows
  data.forEach((item: any) => {
    const row = columns.map((column: string) => item[column]);
    csvContent += row.join(",") + "\n";
  });

  // Create a Blob containing the CSV data
  const blob = new Blob([csvContent], { type: "text/csv" });

  // Create a temporary anchor element to trigger the download
  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.download = filename;
  link.click();
}
function initiateDownload(csvContent: string) {
  // Add Byte Order Mark (BOM) to indicate UTF-8 encoding
  const csvData = "\uFEFF" + csvContent;
  const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.download = "data.csv";
  link.click();
}

function isEmpty(obj: any) {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
}

function getCustomTagValue(
  tagName: any,
  d: any,
  headerName: any,
  customTag: any
) {
  if (customTag && !isEmpty(customTag) && customTag[tagName]) {
    const metric = customTag[tagName];
    // console.log("return", {
    //   [`${metric.sensorAlias} (${metric.sensorUnit})`]:
    //     d[`${metric.sensorAlias} (${metric.sensorUnit})`],
    // });
    return {
      [`${metric.sensorAlias} (${metric.sensorUnit})`]:
        d[`${metric.sensorAlias} (${metric.sensorUnit})`],
    };
  } else {
    return { [headerName]: d[headerName] };
  }
}

export const reportData = async (
  template: number,
  consumptionData: any[],
  deviceName: string,
  customerName: string,
  templateName: string,
  reportDuration: string,
  device: any,
  deviceTemplate = null,
  customTag = null,
  fileName: string = "report.pdf", // Default file name
  options: { fontSize?: number; fontStyle?: string } = {}
) => {

  try {
    const { fontSize = 12, fontStyle = "helvetica" } = options;
    const pdf = new jsPDF();
    const pageWidth = pdf.internal.pageSize.getWidth();
    const pageHeight = pdf.internal.pageSize.getHeight();
    const responsiveFontSize = fontSize * (pageWidth / 210);
    pdf.setFont(fontStyle);
    pdf.setFontSize(responsiveFontSize);
    const logoWidth = 50;
    const logoHeight = 20;
    pdf.addImage(frinsologo, "JPEG", 15, 10, logoWidth, logoHeight);
    pdf.setFontSize(14);
    pdf.text("FRINSO TECHNOLOGIES PVT. LTD.", 120, 20);

    pdf
      .setFontSize(responsiveFontSize * 1.5)
      .setFontSize(16)
      .setFont("", "bold");
    pdf.text(templateName, pageWidth / 2, 40, { align: "center" });
    pdf
      .setFontSize(responsiveFontSize * 1.5)
      .setFontSize(8)
      .setFont("", "normal");

    pdf.setFontSize(responsiveFontSize);
    pdf.text(`Device Name: ${deviceName}`, 15, 50);
    pdf.text(`Report Duration: ${reportDuration}`, 15, 55);

    const startY = 60; // Adjust the startY position for table

    const [columns, processedData] = await findColumnsAndData(
      template,
      consumptionData,
      "KEY",
      device.id,
      deviceTemplate,
      customTag,
      device
    );

    const mappedData = processedData.map((row: any) => {
      const mappedRow: { [key: string]: any } = {};
      columns.forEach((column: any) => {
        mappedRow[column] = row[column];
      });
      return mappedRow;
    });

    (pdf as any).autoTable({
      startY,
      head: [columns],
      body: mappedData.map((row: any) => columns.map((col: any) => row[col])),
      // theme: "grid",
      margin: { top: 10 },
      // styles: { fontSize: responsiveFontSize },
      // columnStyles: {
      //   0: { fontStyle: "bold" }, // Make the first column bold
      // },
    });
    const additionalInfoY = (pdf as any).autoTable.previous.finalY + 10; // Get the Y position after table
    pdf.text("", pageWidth - 20, additionalInfoY, { align: "right" });

    // Add report generated on and by
    const generatedOnY = additionalInfoY + 10;
    pdf.setFontSize(10);
    pdf.text(
      `Report Generated On: ${new Date().toLocaleString()}`,
      20,
      generatedOnY
    );
    pdf.text(`Report Generated by: ${customerName}`, 20, generatedOnY + 5);

    pdf.save(fileName);
  } catch (error) {
    console.error("Error generating PDF:", error);
  }
};
async function findColumnsAndData(
  template: number,
  data: any,
  key: string,
  deviceId = null,
  deviceTemplate = null,
  customTag: any = null,
  device: any = null
) {
  let columns: string[] = [];
  let processedData: any = [];
  // console.log(data, template);

  switch (+template) {
    case 9:
      columns = ["Hour", "First TimeStamp", "Last TimeStamp", "Consumption"];
      let t = getPreviousDayData(data);
      if (t.length === 0) return [columns, processedData];
      const eachHour = filterDataFromEachHourTotalizer(t);
      const dataT = processHourlyDataTotalizer(eachHour);
      const consumptioData = processConsumptionDataTotalizer(dataT);
      processedData = consumptioData.map((d: any) => {
        return {
          Hour: d.hour + ":00",
          "First TimeStamp": d.firstTimeStamp,
          "Last TimeStamp": d.lastTimeStamp,
          Consumption: d.consumption,
        };
      });

      break;
    case 5:
      columns = ["Timestamp", "Flow", "Flow Unit", "Total", "Total Unit"];

      processedData = data.map((d: any, i: any) => {
        
        const isAirtel = +d["firmwareVersionId"] !== 1;
        return {
          Timestamp:
            key === "KEY" ? formatTimestamp(d["TimeStamp"]) : d["TimeStamp"],
          Flow: isAirtel ? d["flow"] : calculateFlow(data, i), // Assuming `calculateFlow` and `resp` are defined in your context
          "Flow Unit": "m³/hr",
          Total:
            d["total"] !== "ERROR"
              ? isAirtel
                ? d["total"]
                : getSanitizedValue(+d["total"] * 0.001)
              : "ERROR",
          // "Negative Cumulative":
          //   d["Reverse Total Flow"] !== "ERROR"
          //     ? isAirtel
          //       ? d["Reverse Total Flow"]
          //       : getSanitizedValue(+d["Reverse Total Flow"] * 0.001)
          //     : "ERROR",
          // Total:
          //   d["Differential Total Flow"] !== "ERROR"
          //     ? isAirtel
          //       ? d["Differential Total Flow"]
          //       : getSanitizedValue(+d["Differential Total Flow"] * 0.001)
          //     : "ERROR",
          "Total Unit": "m³",
        };
      });

      break;
    case 22:
    case 23:
    case 8:
      columns = ["timestamp", "x", "y", "z"];
      processedData = data
        .filter(
          (d: any) =>
            !["x", "y", "z"].some((key) => d[key].toLowerCase() === "error")
        )
        .map((d: any) => ({
          timestamp:
            key === "KEY" ? formatTimestamp(d["TimeStamp"]) : d["TimeStamp"],
          x: parseFloat(d["x"]),
          y: parseFloat(d["y"]),
          z: parseFloat(d["z"]),
        }));
      break;
    case 6:
      columns = ["Timestamp", "Level"];
      processedData = data.map((d: any) => {
        return {
          Timestamp:
            key === "KEY" ? formatTimestamp(d["TimeStamp"]) : d["TimeStamp"],
          Level: d["level"],
        };
      });
      break;
    case 7:
      columns = ["Timestamp", "DO", "PH", "TDS", "TEMP"];
      processedData = data.map((d: any) => {
        return {
          Timestamp:
            key === "KEY" ? formatTimestamp(d["TimeStamp"]) : d["TimeStamp"],
          DO: d["do"],
          PH: d["ph"],
          TDS: d["tds"],
          TEMP: d["temp"],
        };
      });
      break;
    case 10:
      columns = ["Timestamp", "Temperature", "Humidity"];
      processedData = data.map((d: any) => {
        return {
          Timestamp:
            key === "KEY" ? formatTimestamp(d["TimeStamp"]) : d["TimeStamp"],
          Temperature: d["temperature"],
          Humidity: d["humidity"],
        };
      });

      break;
    case 12:
      columns = ["Timestamp", "NI", "PH", "TDS", "TEMP"];
      processedData = data.map((d: any) => {
        return {
          Timestamp:
            key === "KEY" ? formatTimestamp(d["TimeStamp"]) : d["TimeStamp"],
          NI: d["ni"],
          PH: d["ph"],
          TDS: d["tds"],
          TEMP: d["temp"],
        };
      });
      break;
    case 13:
      columns = ["Timestamp", "CL", "PH", "TDS", "TEMP", "TURB"];
      processedData = data.map((d: any) => {
        return {
          Timestamp:
            key === "KEY" ? formatTimestamp(d["TimeStamp"]) : d["TimeStamp"],
          CL: d["cl"],
          PH: d["ph"],
          TDS: d["tsd"],
          TEMP: d["temp"],
          TURB: d["turb"],
        };
      });
      break;

case 15:
      columns = ["Timestamp"];
      if (deviceTemplate === null || customTag?.Flow?.sensorStatus !== false) {
        columns.push("Flow", "Flow Unit");
      }
      if (
        deviceTemplate === null ||
        customTag?.Totalizer?.sensorStatus !== false
      ) {
        columns.push("Totalizer", "Totalizer Unit");
      }
      processedData = data.map((d: any) => {
        return {
          Timestamp:
            key === "KEY" ? formatTimestamp(d["TimeStamp"]) : d["TimeStamp"],
          Flow: d["flow"],
          "Flow Unit": deviceTemplate ? customTag.Flow?.sensorUnit : "m³/hr",
          Totalizer: d["totalizer"],
          "Totalizer Unit": deviceTemplate
            ? customTag.Totalizer?.sensorUnit
            : "m³",
        };
      });
      break;

    case 16:
      columns = ["timestamp", "flow", "totalizer", "pumpstatus"];
      processedData = data.map((d: any) => {
        return {
          timestamp:
            key === "KEY" ? formatTimestamp(d["TimeStamp"]) : d["TimeStamp"],
          flow: d["flow"],
          totalizer: d["totalizer"],
          pumpstatus: +d["pumpstatus"] === 1 ? "On" : "Off",
        };
      });

      break;
    case 17:
      columns = ["timestamp", "ph", "temp"];
      processedData = data.map((d: any) => {
        return {
          timestamp:
            key === "KEY" ? formatTimestamp(d["TimeStamp"]) : d["TimeStamp"],
          ph: d["ph"],
          temp: d["temp"],
        };
      });
      break;
    case 18:
      columns = ["timestamp", "tds", "temp"];
      processedData = data.map((d: any) => {
        return {
          timestamp:
            key === "KEY" ? formatTimestamp(d["TimeStamp"]) : d["TimeStamp"],
          tds: d["tds"],
          temp: d["temp"],
        };
      });

      break;
    case 19:
      columns = ["timestamp", "cl", "turb", "temp"];
      processedData = data.map((d: any) => {
        return {
          timestamp:
            key === "KEY" ? formatTimestamp(d["TimeStamp"]) : d["TimeStamp"],
          cl: d["cl"],
          turb: d["turb"],
          temp: d["temp"],
        };
      });
      break;
    case 21:
      columns = ["timestamp", "devicestatus"];
      processedData = data.map((d: any) => {
        return {
          timestamp:
            key === "KEY" ? formatTimestamp(d["TimeStamp"]) : d["TimeStamp"],
          devicestatus: +d["devicestatus"] === 1 ? "On" : "Off",
        };
      });

      break;

    case 24:
      columns = [
        "Timestamp",
        "Flow",
        "Flow Unit",
        "Totalizer",
        "Totalizer Unit",
      ];
      processedData = data.map((d: any) => {
        return {
          Timestamp:
            key === "KEY" ? formatTimestamp(d["TimeStamp"]) : d["TimeStamp"],
          Flow: d["flow"],
          "Flow Unit": "m/hr³",
          Totalizer: d["totalizer"],
          "Totalizer Unit": "m³",
        };
      });
      break;
    case 20:
      columns = ["timestamp", "temperature", "humidity"];
      processedData = data.map((d: any) => {
        return {
          timestamp:
            key === "KEY" ? formatTimestamp(d["TimeStamp"]) : d["TimeStamp"],
          temperature: d["temperature"],
          humidity: d["humidity"],
        };
      });
      break;
    case 27:
      columns = [
        "Timestamp",
        "Flow",
        "Flowrate",
        "Totalizer",
        "Forward Totalizer",
        "Reverse Totalizer",
        "Battery Level",
        "Unit",
      ];
      processedData = data.map((d: any) => {
        return {
          Timestamp:
            key === "KEY" ? formatTimestamp(d["TimeStamp"]) : d["TimeStamp"],
          Flow: d["Flow"],
          Flowrate: d["Flowrate"],
          Totalizer: d["Totalizer"],
          "Forward Totalizer": d["Forward Totalizer"],
          "Reverse Totalizer": d["Reverse Totalizer"],
          "Battery Level": d["Battery Level"],
          Unit: ["m³"],
        };
      });
      break;

    case 26:
      columns = ["timestamp", "Mppt_Power", "Mppt_Voltage", "Mppt_Current"];
      processedData = data.map((d: any) => {
        return {
          timestamp:
            key === "KEY" ? formatTimestamp(d["TimeStamp"]) : d["TimeStamp"],
          Mppt_Power: d["mppt_power"],
          Mppt_Voltage: d["mppt_voltage"],
          Mppt_Current: d["mppt_current"],
        };
      });
      break;
    case 25:
      columns = [
        "timestamp",
        "Energy_Generation",
        "Energy_Consumption",
        "Fuel_Consumed",
        "Total_Run_Hours",
        "Tank_Level",
      ];
      processedData = data.map((d: any) => {
        return {
          timestamp:
            key === "KEY" ? formatTimestamp(d["TimeStamp"]) : d["TimeStamp"],
          Energy_Generation: d["energy_generation"],
          Energy_Consumption: d["energy_consumption"],
          Fuel_Consumed: d["fuel_consumed"],
          Total_Run_Hours: d["total_run_hours"],
          Tank_Level: d["tank_level"],
        };
      });
      break;

    case 28:
      columns = ["Timestamp", "Temperature"];
      processedData = data.map((d: any) => {
        return {
          Timestamp:
            key === "KEY" ? formatTimestamp(d["TimeStamp"]) : d["TimeStamp"],
          Temperature: d["temperature"],
        };
      });
      break;

    case 29:
      columns = ["Timestamp", "Level", "Unit"];
      processedData = data.map((d: any) => {
        
        return {
          Timestamp: formatTimestamp(d["TimeStamp"]),
          Level: d["Level"],
          Unit: "cm",
        };
      });
      break;
    case 31:
      columns = ["Timestamp", "Totalizer", "Unit"];
      processedData = data.map((d: any) => {
        return {
          Timestamp:
            key === "KEY" ? formatTimestamp(d["TimeStamp"]) : d["TimeStamp"],
          Totalizer: d["Totalizer"],
          Unit: "m³",
        };
      });
      break;
    case 32:
      columns = ["timestamp", "flow", "flowunit", "total", "totalunit"];
      processedData = data.map((d: any) => {
        return {
          timestamp: formatTimestamp(d.TimeStamp),
          flow: d["flow"],
          flowunit: "m³/hr",
          total: d["total"],
          totalunit: "m³",
        };
      });
      break;
    case 42:
      columns = [
        "#",
        "TimeStamp",
        "Temperature (°C)",
        "Humidity (%)",
        "Set Temp (°C)",
        "Status",
      ];
      processedData = data.map((d: any) => {
        return {
          TimeStamp: d["TimeStamp"],
          "Temperature (°C)": d["Temperature (°C)"],
          "Humidity (%)": d["Humidity (%)"],
          Status: d["Status"],
          "Set Temp (°C)": d["Set Temp (°C)"],
        };
      });
      break;
    case 37:
      const dummyColums = [
        "#",
        "TimeStamp",
        customTag && !isEmpty(customTag) && customTag.Flow
          ? customTag.Flow.sensorStatus
            ? `${customTag.Flow.sensorAlias} (${customTag.Flow.sensorUnit})`
            : ""
          : "Flow (m³/hr)",
        customTag && !isEmpty(customTag) && customTag.Totalizer
          ? customTag.Totalizer.sensorStatus
            ? `${customTag.Totalizer.sensorAlias} (${customTag.Totalizer.sensorUnit})`
            : ""
          : "Totalizer (m³)",
        device.batteryConfigStatus ? "Battery (%)" : "",

      ];
      columns = dummyColums.filter((item) => item !== "");
      processedData = data.map((d: any) => {
        
        return {
          TimeStamp: d["TimeStamp"],
          rssi: d["rssi"],
          ...getCustomTagValue("Flow", d, "Flow (m³/hr)", customTag),
          ...getCustomTagValue("Totalizer", d, "Totalizer (m³)", customTag),
          "Battery (%)": d["batteryValue"],
        };
      });
      break;
    case 33:
      columns = [
        "TimeStamp",
        "Flow",
        "FlowUnit",
        "Totalizer",
        "TotalizerUnit",
        "Battery",
        "BatteryUnit",
      ];
      processedData = data.map((d: any) => {
        return {
          TimeStamp: formatTimestamp(d.TimeStamp),
          Flow: parseFloat(d["flow"]),
          FlowUnit: "m³/hr",
          Totalizer: d["totalizer"],
          TotalizerUnit: "m³",
          Battery: d["battery"],
          BatteryUnit: "%",
        };
      });
      break;
    case 34:
      columns = ["TimeStamp", "Flow", "FlowUnit", "Totalizer", "TotalizerUnit"];
      processedData = data.map((d: any) => {
        return {
          TimeStamp: formatTimestamp(d.TimeStamp),
          Flow: parseFloat(d["flow"]),
          FlowUnit: "m³/hr",
          Totalizer: d["totalizer"],
          TotalizerUnit: "m³",
        };
      });
      break;

    case 35:
      columns = ["Timestamp", "Level", "Unit"];
      processedData = data.map((d: any) => {
        return {
          Timestamp:
            key === "KEY" ? formatTimestamp(d["TimeStamp"]) : d["TimeStamp"],
          Level: parseFloat(d["Level"]).toFixed(3),
          Unit: "m",
        };
      });
      break;

    default:
      columns = [];
      processedData = [];
  }

  processedData = processedData.filter((d: any) => {
    // Function to check if any value contains "error" (case insensitive)
    const containsError = (value: any) =>
      typeof value === "string" && value.toLowerCase().includes("error");

    // Check if any value in the object contains "error" (case insensitive)
    return !Object.values(d).some(containsError);
  });


  return [columns, processedData];
}

function calculateFlow(_data: any, i: any) {
  if (i === _data.length - 1) {
    return getSanitizedValue(0);
  }
  const currentData = _data[i];
  const previousData = _data[i + 1];

  if (
    currentData["Differential Total Flow"] === "ERROR" ||
    previousData["Differential Total Flow"] === "ERROR"
  ) {
    return getSanitizedValue(0);
  }

  const currentFlow =
    currentData["Differential Total Flow"] !== "ERROR"
      ? +currentData["Differential Total Flow"]
      : 0;
  const previousFlow =
    previousData["Differential Total Flow"] !== "ERROR"
      ? +previousData["Differential Total Flow"]
      : 0;
  const timeDiff =
    +new Date(currentData.timeStamp) - +new Date(previousData.timeStamp);
  let flow = ((currentFlow - previousFlow) / timeDiff) * 1000 * 60 * 60;
  if (flow < 0) {
    flow = 0;
  }
  const v = getSanitizedValue(flow * 0.001);
  if (isNaN(v)) {
    return 0;
  }

  return v;
}

export function getReportAll1(
  deviceData: any[],
  name: String,
  fileName = "report.pdf",
  reportDuration: String
) {
  try {
    const pdf = new jsPDF();
    const fontSize = 8; // Reduce font size
    let fontStyle = "helvetica";

    const pageWidth = pdf.internal.pageSize.getWidth();
    const pageHeight = pdf.internal.pageSize.getHeight();

    const responsiveFontSize = fontSize * (pageWidth / 210);

    pdf.setFont(fontStyle);
    pdf.setFontSize(responsiveFontSize);

    const logoWidth = 50;
    const logoHeight = 20;
    pdf.addImage(frinsologo, "JPEG", 15, 10, logoWidth, logoHeight);

    pdf.setFontSize(14);
    pdf.text("FRINSO TECHNOLOGIES PVT. LTD.", 120, 20);

    pdf.text(`Report Duration: ${reportDuration}`, 13, 38);

    let isFirstDevice = true; // Flag to indicate the first device
    let startY: number;
    let additionalInfoY = 50; // Initialize additionalInfoY with a default value

    deviceData.forEach((d, index) => {
      const deviceName = d["deviceName"];
      const data: any = d["data"];
      const template = d["template"];
      if (data.data.length === 0) {
        return; // If no data, move to the next iteration
      }
      const [columns, processedData] = findColumnsAndData1(
        template,
        data.data,
        "",
        ""
      );

      if (processedData.length === 0) return;
      startY = isFirstDevice ? 35 : 20; // Set startY based on whether it's the first device
      if (!isFirstDevice) {
        pdf.addPage();
      } else {
        isFirstDevice = false; // Update the flag after processing the first device
      }

      pdf.setFontSize(16);
      pdf.text(`Device Name : ${deviceName}`, 13, startY + 12);

      const deviceNameTextHeight = 2 * (pageWidth / 120); // Assuming fontSize is 16
      startY += deviceNameTextHeight + 10; // Adjust the value according to your preference

      const tableRows = processedData.map((entry: any) =>
        columns?.map((column: any) => {
          return entry[column];
        })
      );

      pdf.setFontSize(10);
      pdf.text(" ", 200, startY + 15);
      const uppercase = columns?.map((key: any) => key.toUpperCase()) || "";

      // Split the table if the number of columns exceeds 8
      let tableStartY = startY;

      (pdf as any).autoTable({
        head: [uppercase],
        body: tableRows,
        startY: tableStartY,
      });
      tableStartY += (pdf as any).previousAutoTable.finalY + 10;
      isFirstDevice = false; // Update the flag after processing the first device
      additionalInfoY = tableStartY;
    });

    pdf.setFontSize(10);
    const generatedOnText = `Report Generated On: ${new Date().toLocaleString()}`;
    const generatedOnTextY = pageHeight - 10; // Adjust 10 as needed
    pdf.text(generatedOnText, 20, generatedOnTextY);
    pdf.text("Report Generated By : " + name, 20, generatedOnTextY + 3);

    pdf.save(fileName);
  } catch (error) {
    console.error(error);
  }
}

// export function getReportAll1(
//   deviceData: any[],
//   name: String,
//   fileName = "report.pdf",
//   reportDuration: String
// ) {
//   try {
//     const pdf = new jsPDF();
//     const fontSize = 8; // Reduce font size
//     let fontStyle = "helvetica";

//     const pageWidth = pdf.internal.pageSize.getWidth();
//     const pageHeight = pdf.internal.pageSize.getHeight();

//     const responsiveFontSize = fontSize * (pageWidth / 210);

//     pdf.setFont(fontStyle);
//     pdf.setFontSize(responsiveFontSize);

//     const logoWidth = 50;
//     const logoHeight = 20;
//     pdf.addImage(frinsologo, "JPEG", 15, 10, logoWidth, logoHeight);

//     pdf.setFontSize(14);
//     pdf.text("FRINSO TECHNOLOGIES PVT. LTD.", 120, 20);

//     pdf.text(`Report Duration: ${reportDuration}`, 13, 38);

//     let startY: number = 35; // Initialize startY
//     let additionalInfoY = 50; // Initialize additionalInfoY with a default value

//     deviceData.forEach((d, index) => {
//       const deviceName = d["deviceName"];
//       const data: any = d["data"];
//       const template = d["template"];
//       if (data.data.length === 0) {
//         return; // If no data, move to the next iteration
//       }
//       const [columns, processedData] = findColumnsAndData1(
//         template,
//         data.data,
//         ""
//       );

//       if (processedData.length === 0) return;

//       if (index !== 0) {
//         // Add some vertical space between devices
//         startY += 50;
//       }

//       pdf.setFontSize(16);
//       pdf.text(`Device Name : ${deviceName}`, 13, startY + 12);

//       const deviceNameTextHeight = 2 * (pageWidth / 120); // Assuming fontSize is 16
//       startY += deviceNameTextHeight + 10; // Adjust the value according to your preference

//       const tableRows = processedData.map((entry: any) =>
//         columns?.map((column: any) => {
//           return entry[column];
//         })
//       );

//       pdf.setFontSize(10);
//       pdf.text(" ", 200, startY + 15);
//       const uppercase = columns?.map((key: any) => key.toUpperCase()) || "";

//       // Split the table if the number of columns exceeds 8
//       let tableStartY = startY;

//       (pdf as any).autoTable({
//         head: [uppercase],
//         body: tableRows,
//         startY: tableStartY,
//       });
//       tableStartY += (pdf as any).previousAutoTable.finalY + 10;
//       additionalInfoY = tableStartY;
//     });

//     pdf.setFontSize(10);
//     const generatedOnText = `Report Generated On: ${new Date().toLocaleString()}`;
//     const generatedOnTextY = pageHeight - 10; // Adjust 10 as needed
//     pdf.text(generatedOnText, 20, generatedOnTextY);
//     pdf.text("Report Generated By : " + name, 20, generatedOnTextY + 3);

//     pdf.save(fileName);
//   } catch (error) {
//     console.log(error);
//   }
// }

function getSanitizedValue(value: any) {
  if (typeof value === "number") {
    return value.toFixed(3);
  } else {
    return value;
  }
}

function findColumnsAndData1(
  template: number,
  data: any,
  key: string,
  deviceName: string
) {
  let columns: string[] = [];
  let processedData: any = [];

  switch (+template) {
    case 9:
      columns = [
        "Device Name",
        "Date",
        "First TimeStamp",
        "Last TimeStamp",
        "Consumption",
      ];
      if (data.length <= 0) {
        return [columns, []];
      }

      let date = data[0].timeStamp;
      let lastTimeStamp = data[0].timeStamp;
      let firstTimeStamp = data[data.length - 1].timeStamp;
      let consumption = data[data.length - 1].Totalizer - data[0].Totalizer;
      if (consumption < 0) {
        consumption = consumption * -1;
      }

      let resultObject = {
        "Device Name": deviceName,
        Date: date?.slice(0, 10),
        "First TimeStamp": firstTimeStamp,
        "Last TimeStamp": lastTimeStamp,
        Consumption: consumption,
      };
      processedData = [resultObject];

      break;
    case 5:
      columns = ["timestamp", "flow", "flowunit", "total", "totalunit"];

      processedData = data.map((d: any) => ({
        timestamp:
          key === "KEY" ? formatTimestamp(d["TimeStamp"]) : d["TimeStamp"],
        flow: d["Flow"],
        flowunit: "m³/hr",

        negativecumulative: d["Reverse Total Flow"],
        total: d["Forward Total Flow"],
        totalunit: "m³",
        any: "Your value here",
      }));
      break;
    case 22:
    case 23:
    case 8:
      columns = ["timestamp", "x", "y", "z"];
      processedData = data.map((d: any) => ({
        timestamp:
          key === "KEY" ? formatTimestamp(d["TimeStamp"]) : d["TimeStamp"],
        x: parseFloat(d["x"]),
        y: parseFloat(d["y"]),
        z: parseFloat(d["z"]),
      }));
      break;
    case 6:
      columns = ["timestamp", "level"];
      processedData = data.map((d: any) => {
        return {
          timestamp:
            key === "KEY" ? formatTimestamp(d["TimeStamp"]) : d["TimeStamp"],
          level: d["Piezo Level"],
        };
      });
      break;
    case 7:
      columns = ["timestamp", "do", "ph", "tds", "temp"];
      processedData = data.map((d: any) => {
        return {
          timestamp:
            key === "KEY" ? formatTimestamp(d["TimeStamp"]) : d["TimeStamp"],
          do: d["DO"],
          ph: d["PH"],
          tds: d["TDS"],
          temp: d["TEMP"],
        };
      });
      break;
    case 10:
      columns = ["timestamp", "temperature", "humidity"];
      processedData = data.map((d: any) => {
        return {
          timestamp:
            key === "KEY" ? formatTimestamp(d["TimeStamp"]) : d["TimeStamp"],
          temperature: d["Temperature"],
          humidity: d["Humidity"],
        };
      });

      break;
    case 12:
      columns = ["timestamp", "ni", "ph", "tds", "temp"];
      processedData = data.map((d: any) => {
        return {
          timestamp:
            key === "KEY" ? formatTimestamp(d["TimeStamp"]) : d["TimeStamp"],
          ni: d["NI"],
          ph: d["PH"],
          tds: d["TDS"],
          temp: d["TEMP"],
        };
      });
      break;
    case 13:
      columns = ["timestamp", "cl", "ph", "tds", "temp", "turb"];
      processedData = data.map((d: any) => {
        return {
          timestamp:
            key === "KEY" ? formatTimestamp(d["TimeStamp"]) : d["TimeStamp"],
          cl: d["CL"],
          ph: d["PH"],
          tds: d["TDS"],
          temp: d["TEMP"],
          turb: d["TURB"],
        };
      });

      break;

    case 14:
      columns = [
        "timestamp",
        "averagevoltage",
        "averagecurrent",
        "totalactivepower",
        "totalreactivepower",
        "totalapparentpower",
        "frequency",
      ];
      processedData = data.map((d: any) => {
        return {
          timestamp:
            key === "KEY" ? formatTimestamp(d["TimeStamp"]) : d["TimeStamp"],
          averagevoltage: d["avg_vtg"],
          averagecurrent: d["avg_crt"],
          totalactivepower: d["total_act_po"],
          totalreactivepower: d["total_react_po"],
          totalapparentpower: d["total_app_po"],
          frequency: d["frequency"],
        };
      });

      break;
    case 15:
      columns = ["timestamp", "flow", "totalizer"];
      processedData = data.map((d: any) => {
        return {
          timestamp:
            key === "KEY" ? formatTimestamp(d["TimeStamp"]) : d["TimeStamp"],
          flow: d["Flow"],
          totalizer: d["Totalizer"],
        };
      });
      break;

    case 16:
      columns = ["timestamp", "flow", "totalizer", "pumpstatus"];
      processedData = data.map((d: any) => {
        return {
          timestamp:
            key === "KEY" ? formatTimestamp(d["TimeStamp"]) : d["TimeStamp"],
          flow: d["Flow"],
          totalizer: d["Totalizer"],
          pumpstatus: +d["Pump Status"] === 1 ? "On" : "Off",
        };
      });

      break;
    case 17:
      columns = ["timestamp", "ph", "temp"];
      processedData = data.map((d: any) => {
        return {
          timestamp:
            key === "KEY" ? formatTimestamp(d["TimeStamp"]) : d["TimeStamp"],
          ph: d["PH"],
          temp: d["TEMP"],
        };
      });
      break;
    case 18:
      columns = ["timestamp", "tds", "temp"];
      processedData = data.map((d: any) => {
        return {
          timestamp:
            key === "KEY" ? formatTimestamp(d["TimeStamp"]) : d["TimeStamp"],
          tds: d["TDS"],
          temp: d["TEMP"],
        };
      });

      break;
    case 19:
      columns = ["timestamp", "cl", "turb", "temp"];
      processedData = data.map((d: any) => {
        return {
          timestamp:
            key === "KEY" ? formatTimestamp(d["TimeStamp"]) : d["TimeStamp"],
          cl: d["CL"],
          turb: d["TURB"],
          temp: d["TEMP"],
        };
      });

      break;
    case 21:
      columns = ["timestamp", "devicestatus"];
      processedData = data.map((d: any) => {
        return {
          timestamp:
            key === "KEY" ? formatTimestamp(d["TimeStamp"]) : d["TimeStamp"],
          devicestatus: +d["device_status"] === 1 ? "On" : "Off",
        };
      });

      break;
    case 24:
      columns = [
        "Timestamp",
        "Flow",
        "Flow Unit",
        "Totalizer",
        "Totalizer Unit",
      ];
      processedData = data.map((d: any) => {
        return {
          Timestamp:
            key === "KEY" ? formatTimestamp(d["TimeStamp"]) : d["TimeStamp"],
          Flow: d["Inst_flow_rate"],
          "Flow Unit": "m³/hr",
          Totalizer: +d["Pos_Cumu_flow_Int"] + +d["Pos_Cumu_flow_Dec"],
          "Totalizer Unit": "m³",
        };
      });

      break;
    case 20:
      columns = ["timestamp", "temperature", "humidity"];
      processedData = data.map((d: any) => {
        return {
          timestamp:
            key === "KEY" ? formatTimestamp(d["TimeStamp"]) : d["TimeStamp"],
          temperature: d["Temperature"],
          humidity: d["Humidity"],
        };
      });

      break;
    case 27:
      columns = [
        "timestamp",
        "flow",
        "flowrate",
        "totalizer",
        "forward_totalizer",
        "reverse_totalizer",
        "battery_level",
        "unit",
      ];
      processedData = data.map((d: any) => {
        return {
          timestamp:
            key === "KEY" ? formatTimestamp(d["TimeStamp"]) : d["TimeStamp"],
          flow: d["Inst_flow"],
          flowrate: d["Inst_flow_rate"],
          totalizer: d["Cumu_total"],
          forward_totalizer: +d["Pos_Cumu_Int"] + +d["Pos_Cumu_Frac"],
          reverse_totalizer: d["Rev_Cumu_Dec"],
          battery_level: d["Batt_lvl"],
          unit: ["m³"],
        };
      });

      break;

    case 26:
      columns = ["timestamp", "Mppt_Power", "Mppt_Voltage", "Mppt_Current"];
      processedData = data.map((d: any) => {
        return {
          timestamp:
            key === "KEY" ? formatTimestamp(d["TimeStamp"]) : d["TimeStamp"],
          Mppt_Power: d["mppt_po"],
          Mppt_Voltage: d["mppt_vtg"],
          Mppt_Current: d["mppt_crt"],
        };
      });

      break;
    case 25:
      columns = [
        "timestamp",
        "Energy_Generation",
        "Energy_Consumption",
        "Fuel_Consumed",
        "Total_Run_Hours",
        "Tank_Level",
      ];
      processedData = data.map((d: any) => {
        return {
          timestamp:
            key === "KEY" ? formatTimestamp(d["TimeStamp"]) : d["TimeStamp"],
          Energy_Generation: d["ene_gener"],
          Energy_Consumption: d["ene_cons"],
          Fuel_Consumed: d["fuel_cons"],
          Total_Run_Hours: d["total_run_hr"],
          Tank_Level: d["tank_level"],
        };
      });

      break;
    case 28:
      columns = ["Timestamp", "Temperature"];
      processedData = data.map((d: any) => {
        return {
          Timestamp:
            key === "KEY" ? formatTimestamp(d["TimeStamp"]) : d["TimeStamp"],
          Temperature: d["Temperature"],
        };
      });

      break;
    case 31:
      columns = ["Timestamp", "Totalizer", "Unit"];
      processedData = data.map((d: any) => {
        return {
          Timestamp:
            key === "KEY" ? formatTimestamp(d["TimeStamp"]) : d["TimeStamp"],
          Totalizer: +d["Pos_Cumu_flow_Int"] + +d["Pos_Cumu_flow_Dec"],
          Unit: "m³",
        };
      });
      break;
    default:
      columns = [];
      processedData = [];
  }
  processedData = processedData.filter((d: any) => {
    return !Object.values(d).some(
      (val: any) => typeof val === "string" && val.toLowerCase() === "error"
    );
  });
  return [columns, processedData];
}

export function getReportAll2(
  deviceData: any[],
  name: String,
  fileName = "report.pdf",
  reportDuration: String
) {
  try {
    const [start, end] = reportDuration
      .split(" - ")
      .map((date) => new Date(date));

    const formatDate = (date: Date) => {
      const yyyy = date.getFullYear();
      const mm = String(date.getMonth() + 1).padStart(2, "0");
      const dd = String(date.getDate()).padStart(2, "0");
      const hh = String(date.getHours()).padStart(2, "0");
      const min = String(date.getMinutes()).padStart(2, "0");
      const ss = String(date.getSeconds()).padStart(2, "0");
      return `${yyyy}-${mm}-${dd} ${hh}:${min}:${ss}`;
    };

    const formattedStart = formatDate(start);
    const formattedEnd = formatDate(end);
    const formattedDuration = `${formattedStart} - ${formattedEnd}`;

    const pdf = new jsPDF();
    const fontSize = 8; // Reduce font size
    let fontStyle = "helvetica";
    const pageWidth = pdf.internal.pageSize.getWidth();
    const pageHeight = pdf.internal.pageSize.getHeight();
    const responsiveFontSize = fontSize * (pageWidth / 210);
    pdf.setFont(fontStyle);
    pdf.setFontSize(responsiveFontSize);
    const logoWidth = 50;
    const logoHeight = 20;
    pdf.addImage(frinsologo, "JPEG", 15, 10, logoWidth, logoHeight);
    pdf.setFontSize(14);
    pdf.text("FRINSO TECHNOLOGIES PVT. LTD.", 120, 20);
    let isFirstDevice = true; // Flag to indicate the first device
    let startY: number;
    let additionalInfoY = 50; // Initialize additionalInfoY with a default value
    let filterData: any[] = [];
    pdf
      .setFontSize(responsiveFontSize * 1.5)
      .setFontSize(18)
      .setFont("", "bold");

    pdf.text("Summary", pageWidth / 2, 40, {
      align: "center",
    });

    pdf
      .setFontSize(responsiveFontSize * 1.5)
      .setFontSize(14)
      .setFont("", "normal");
    pdf.text(`Report Duration: ${formattedDuration}`, 13, 48);

    deviceData.forEach((d, index) => {
      const deviceName = d["deviceName"];
      const data: any = d["data"];
      const template = d["template"];

      if (data.data.length === 0 && deviceData.length - 1 !== index) {
        return; // If no data, move to the next iteration
      }

      const [columns, processedData] = findColumnsAndData1(
        template,
        data.data,
        "",
        deviceName
      );

      if (template === 9 && index !== deviceData.length - 1) {
        filterData = filterData.concat(processedData);
        return;
      } else {
        filterData = filterData.concat(processedData);
      }
      if (processedData.length === 0 && deviceData.length - 1 !== index) {
        return;
      }

      startY = isFirstDevice ? 47 : 15; // Set startY based on whether it's the first device
      if (!isFirstDevice) {
        pdf.addPage();
      } else {
        isFirstDevice = false; // Update the flag after processing the first device
      }

      if (data.length! > 0) {
        pdf.text(
          `Device Name : ${deviceName}`,
          13,
          isFirstDevice ? startY + 10 : startY
        );
      }

      // Add a line break between device names
      if (index !== deviceData.length - 1) {
        pdf.text("", 13, startY + 5);
      }

      const deviceNameTextHeight = 2 * (pageWidth / 120); // Assuming fontSize is 16
      startY += deviceNameTextHeight + 10; // Adjust the value according to your preference

      pdf.text(`Device Name : ${deviceName}`, 13, startY); // Display device name before the table

      let pData =
        filterData && filterData.length > 0 ? filterData : processedData;

      const tableRows = pData.map((entry: any) =>
        columns?.map((column: any) => {
          return entry[column];
        })
      );

      pdf.setFontSize(10);
      pdf.text(" ", 200, startY + 15);
      const uppercase = columns?.map((key: any) => key.toUpperCase()) || "";

      let tableStartY = isFirstDevice ? startY + 15 : startY + 5; // Adjust the startY value

      (pdf as any).autoTable({
        head: [uppercase],
        body: tableRows,
        startY: tableStartY,
      });
      filterData = [];
      tableStartY += (pdf as any).previousAutoTable.finalY + 10;
      isFirstDevice = false; // Update the flag after processing the first device
      additionalInfoY = tableStartY;
    });

    pdf.setFontSize(10);
    const generatedOnText = `Report Generated On: ${new Date().toLocaleString()}`;
    const generatedOnTextY = pageHeight - 10; // Adjust 10 as needed
    pdf.text(generatedOnText, 20, generatedOnTextY);
    pdf.text("Report Generated By : " + name, 20, generatedOnTextY + 3);

    pdf.save(fileName);
  } catch (error) {
    console.error(error);
  }
}
