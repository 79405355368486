import { jwtDecode, JwtPayload } from "jwt-decode";
import React, {useEffect, useState} from "react";

interface ProtectedComponentProp {
    roleids?: any;
    children?: React.ReactNode
  }

const ProtectedComponent: React.FC<ProtectedComponentProp> = ({ roleids = [1], children }) => {

  const [token] = useState(window.localStorage.getItem("token"));
    const [allow, setAllow] = useState(false);
  useEffect(() => {
    if(token){
      try {
        const decodedToken: any = jwtDecode<JwtPayload>(token);
        // console.log('roleidssssssssssssssssssssss',roleids)
        if(roleids.includes(decodedToken.role)){
            setAllow(true)
        }
      } catch (error) {
        console.error("Error decoding token: ", error)
      }
  }
}, [roleids]);

    return <>{allow? children : ""}</>;
};

export default ProtectedComponent;
