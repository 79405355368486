import { useEffect, useRef, useState } from "react";
import { Switch } from "@mui/material";
import { http } from "../../helper/http";
import {
  formatTimestamp,
  generateCSV1,
  getIndianCurrentTime,
  reportDataWithGraph,
} from "../../common-function/CommonFunction";
import { toast } from "react-toastify";
import axios from "axios";
import LongMenu from "../../utils/longMenu/LongMenu";
import LineChart1 from "../../components/apex-chart/LineChart1";
import SteplineChart from "../../components/apex-chart/SteplineChart";

const baseUrl = process.env.REACT_APP_BASE_URL;
const DashboardAutomation2 = ({ selectedDevice, deviceId, devices }: any) => {
  const [apiData, setApiData] = useState<any[]>([]);
  const [dashboardSts, setDashboardSts] = useState<any>([]);
  const [pumpStatus, setPumpStatus] = useState(false);
  const chartRef = useRef<HTMLDivElement>(null);

  const fetchData = async () => {
    const accessToken = localStorage.getItem("token");

    let response: any = null;
    try {
      response = await axios.get(baseUrl + `/device-data-log?last24=last24`, {
        params: {
          deviceId: selectedDevice,
        },
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      const _data = response.data.map((d: any, i: any) => {
        return {
          timestamp: formatTimestamp(d.timeStamp),
          devicestatus: d["device_status"],
        };
      });

      setPumpStatus(_data.length ? +_data[0].devicestatus === 1 : false);
      setApiData(_data);
      setDashboardSts(_data.length ? _data[0] : "");
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
    const intervalId = setInterval(fetchData, 120000);
    return () => clearInterval(intervalId);
  }, [selectedDevice]);

  const currentDate = new Date();
  const last24HoursData = apiData.filter((entry) => {
    const entryDate = new Date(entry.timestamp);
    const timeDifference = currentDate.getTime() - entryDate.getTime();
    return timeDifference <= 24 * 60 * 60 * 1000; // Include entries up to the current timestamp
  });

  const pump1line =
    last24HoursData.length > 0
      ? last24HoursData.map(({ timestamp, devicestatus }: any) => ({
          timestamp,
          status: +devicestatus === 1 ? "on" : "Off",
        }))
      : [];
  // console.log("Pumpline", pump1line);
  

  const handleChange = async () => {
    setPumpStatus((prev) => !prev);
    try {
      let time = getIndianCurrentTime();
      const newData = {
        timestamp: time,
        devicestatus: pumpStatus ? "0" : "1",
      };
      if (pumpStatus) {
        setApiData((prevData) => [newData, ...prevData]);
        setDashboardSts(newData);
      } else {
        setApiData((prevData) => [newData, ...prevData]);
        setDashboardSts(newData);
      }

      // const message = { "Pump Status": checked ? 0 : 1, Totalizer: 0, Flow: 0 };
      const message = { device_status: pumpStatus ? 0 : 1 };
      const resp: any = await http("/mqtt-publish?start=1", "POST", {
        deviceId: deviceId,
        message: JSON.stringify(message),
      });
      toast.success(resp.message);
    } catch (err: any) {
      toast.error(err);
    }
  };

  const pump1data: any = [
    {
      name: "Pump Staus",
      color: "#1f79a7",
      data: pump1line.reverse(),
    },
  ];

  const handleExportBarChart = async (exportOption: string) => {
    const filter = pump1line.map(({ timestamp, status }) => {
      return {
        timestamp,
        Pump_Staus: status,
      };
    });

    switch (exportOption) {
      case "Download as CSV":
        generateCSV1(filter);
        break;
      case "Download as XLS":
        break;
      case "Download as PDF":
        if (filter && chartRef) {
          reportDataWithGraph(
            chartRef,
            filter,
            devices?.deviceName,
            devices?.Customer?.name,
            "Automation Summary",
            "Realtime - Last 24 Hour | IST"
          );
        } else {
        }
        return;
      default:
        return; // Unknown export option
    }
  };

  return (
    <div>
      <div>
        <div>
          <div className=" p-2 mb-4">
            <section>
              <p className=" flex ">
                {"    Last Data Sent at  " + dashboardSts?.timestamp ||
                  "  YYYY-MM-DD HH:MM:SS"}
              </p>
            </section>
          </div>

          <div className="border border-gray-400 rounded-md grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-6 xl:grid-cols-4 2xl:gap-7.5 mb-2 p-2">
            <div
              className="rounded-md border border-stroke p-6 bg-white shadow-lg"
              style={{ boxShadow: "lightgrey 3px 3px 30px 5px" }}
            >
              <h2 className="font-medium" style={{ whiteSpace: "nowrap" }}>Device Status</h2>

              <div className="mt-2 flex items-end justify-between">
                <div>
                  <h4
                    className="text-title-md text-3xl"
                    style={{ color: pumpStatus ? "green" : "red" }}
                  >
                    {pumpStatus ? "ON" : "OFF"}{" "}
                    {/* Display text indicating switch state */}
                  </h4>
                </div>
                <Switch
                  className="ml-20"
                  checked={pumpStatus}
                  onChange={handleChange}
                  color="primary"
                  sx={{
                    "& .MuiSwitch-switchBase.Mui-checked": {
                      color: "green",
                    },
                    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
                      backgroundColor: "green",
                    },
                    "& .MuiSwitch-track": {
                      backgroundColor: "red",
                    },
                  }}
                />
              </div>
            </div>
          </div>
          <div className="pt-[3px]"></div>
          <div
            className="border border-gray-400 rounded-md p-2"
            // style={{ border: "2px solid #EAECF0", padding: "10px" }}
          >
            <div className="mt-7 mb-11">
              <div className="relative">
                <h1 className="relative  h-fit  p-2">
                  Data variation with time (between{" "}
                  {last24HoursData.length > 0
                    ? last24HoursData[last24HoursData.length - 1].timestamp +
                      " to " +
                      last24HoursData[0].timestamp
                    : " "}
                  )
                </h1>
                <div className="absolute top-0 right-0">
                  <LongMenu
                    options={[
                      "Download as CSV",
                      "Download as PDF",
                    ]}
                    onOptionClick={handleExportBarChart}
                    id={1}
                  />
                </div>
              </div>
              <div ref={chartRef || ""}>
                {/* <LineChart1
                  data={pump1data}
                  yAxisName="Pump Status"
                  xAxisName="Time"
                /> */}
                <SteplineChart data={pump1line} yAxisLabel="Device Status" xAxisLabel="Time" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardAutomation2;
