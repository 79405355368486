import { Button, FormControl, InputLabel, MenuItem, Select, Toolbar, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { http } from "../../../helper/http";
import AddIcon from "@mui/icons-material/Add";
import AddTagGroupForm from "./AddTagGroupForm";
import {  Template } from "../../../types/TemplateType";
import { useTemplateState } from "../../../app-context/template-context/TemplateState";
import { PaginatedTagGroupWithTagTemplate, TagGroupApiResponse } from "../../../types/TagGroup";
import { getTagGroupPageClick, searchTemplate } from "../../../app-context/taggroup-context/TagGroupAction";
import { useTagGroupState } from "../../../app-context/taggroup-context/TagGroupState";
import { setAllTags } from "../../../app-context/tags-context/TagAction";
import { useTagState } from "../../../app-context/tags-context/TagState";
import { setAllTemplate } from "../../../app-context/template-context/TemplateAction";
import { fetchAllTags, fetchAllTemplate } from "../../../common-function/CommonFunction";

interface ApiResponse {
  message: string;
  tagsGroup: PaginatedTagGroupWithTagTemplate;
}


interface EnhancedTableToolbarProps {
  onFilter: (value: string, filterOption: string) => void;
}
const baseUrl = process.env.REACT_APP_BASE_URL;

function EnhancedTableToolbar({ onFilter }: EnhancedTableToolbarProps) {
  const [filterValue, setFilterValue] = React.useState("");
  const [filterOption, setFilterOption] = React.useState<string>("name");
  const [formModalIsOpen, setFormModalIsOpen] = useState(false);


  const [template, setTemplate] = useState<Template[]>([]);
  const [selectedTemplate, setSelectedTemplate] = useState("");
  const { templates, dispatch: templateDispatch } = useTemplateState();
  const { dispatch } = useTagGroupState();



  useEffect(() => {
    setTemplate(templates.templates);

    const fetchData = async () => {
      try {
        if (selectedTemplate) {
          // Call your API here and update the state accordingly
          const response = (await http(`/tag-groups?templateid=${+selectedTemplate}`) as ApiResponse);
          searchTemplate(dispatch, response.tagsGroup)

        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [selectedTemplate, templates]);



  const { dispatch: tagDispatch } = useTagState()

  const toggleForm = async () => {
    setFormModalIsOpen((prevVal) => !prevVal);
    try {      
      const respTags= (await fetchAllTags()) as any
      setAllTags(tagDispatch, respTags.tags)
      const respTemplate = (await fetchAllTemplate()) as any;
      setAllTemplate(templateDispatch, respTemplate.template);
    } catch (error) {
      console.error(error);
      
    }
  };

  const handleFilterChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value;
    try {
      let resp;

      if (value === "") {
        resp = (await http(baseUrl + "/tag-groups?page=1")) as TagGroupApiResponse;
      } else {
        resp = (await http(
          baseUrl + "/tag-groups?searchTag=" + value
        )) as TagGroupApiResponse;
      }
      getTagGroupPageClick(dispatch, resp.tagsGroup)
    } catch (error) {
      console.error("no device found");
    }
  };

  const handleFilterSubmit = () => {
    onFilter(filterValue, filterOption);
  };



  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
      }}
    >
      <Typography
        sx={{
          flex: "1 1 100%",
          fontWeight: 700,
          fontSize: [
            "text-sm",
            "text-md",
            "text-lg",
            "text-xl",
            "text-2xl",
            "text-3xl",
          ],
        }}
        variant="h6"
        id="tableTitle"
        component="div"
        className="drop-shadow-[2px_2px_var(--tw-shadow-color)] "
      >
        TagGroup
      </Typography>

      <div className="flex flex-col sm:flex-row items-center space-y-2 sm:space-y-0 sm:space-x-2">
        <Button variant="contained" sx={{
          backgroundColor: "teal",
          color: "white",
          height: 32,
          fontWeight: "bold",
          fontSize: "1rem",
          transition: "background-color 200ms",
          "&:hover": {
            backgroundColor: "teal",
          },
        }} onClick={toggleForm}>
          <AddIcon />
          ADD
        </Button>

        <FormControl sx={{ width: "200px" }} >
          <InputLabel id="demo-simple-select-label">Template</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={selectedTemplate}
            label="Template"
            onChange={(e) => {
              setSelectedTemplate(e.target.value);
            }
            }
          >
            {template.map((template) => (
              <MenuItem
                key={template.id}
                value={template.id}
                style={{paddingLeft: "20px", display: "block", marginBottom: "5px"}}
              >
                {template.templatename}
              </MenuItem>
            ))}
          </Select>
        </FormControl>


        <input
          type="text"
          placeholder="Search"
          onChange={handleFilterChange}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleFilterSubmit();
            }
          }}
          className="border border-black rounded p-1 w-full sm:w-auto"
        />

      </div>
      {formModalIsOpen &&
        <AddTagGroupForm toggleForm={formModalIsOpen} toggleFormFun={toggleForm} data={[]} uniqueId="ADD" />
      }
    </Toolbar>
  );
}

export default EnhancedTableToolbar;