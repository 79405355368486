// activeenergy: d["active_energy"],
// reactiveenergy: d["reactive_energy"],
// apparentenergy
interface DataItem1 {
  timestamp: string;
  totalizer: string;
}

interface HourlyData1 {
  firstTimeStamp: string;
  lastTimeStamp: string;

  firstActiveEnergy: string;
  lastActiveEnergy: string;

  firstReactiveEnergy: string;
  lastReactiveEnergy: string;

  firstApparentEnergy: string;
  lastApparentEnergy: string;

  firstImportActiveEnergy?: string;
  lastImportActiveEnergy?: string;

  firstExportActiveEnergy?: string;
  lastExportActiveEnergy?: string;

  firstImportReactiveEnergy?: string;
  lastImportReactiveEnergy?: string;

  firstExportReactiveEnergy?: string;
  lastExportReactiveEnergy?: string;

  firstRunHoursEnergy?: string;
  lastRunHoursEnergy?: string;

  hour: string;
}
export function filterDataFromEachHourEnergy(data: any[]): HourlyData1[] {
  data.sort(
    (a, b) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime()
  );

  // Calculate the timestamp for 24 hours ago from the current time
  const twentyFourHoursAgo = new Date(Date.now() - 24 * 60 * 60 * 1000);
  // Filter the array to include only items from the last 24 hours from the current hour
  const last24HoursData = data.filter(
    (item) => new Date(item.timestamp) >= twentyFourHoursAgo
  );
  // Initialize an array to store the results
  const result: HourlyData1[] = [];

  last24HoursData.forEach((item: any) => {
    const hourTimestamp = item.timestamp.slice(0, 13); // Adjust the timestamp format here

    const index = result.findIndex(
      (hourlyData) => hourlyData.hour === hourTimestamp
    );

    if (index === -1) {
      // If the hour doesn't exist in the result array, initialize it
      result.push({
        firstTimeStamp: item.timestamp,
        lastTimeStamp: item.timestamp,
        firstActiveEnergy: item.activeenergy,
        lastActiveEnergy: item.activeenergy,
        firstReactiveEnergy: item.reactiveenergy,
        lastReactiveEnergy: item.reactiveenergy,
        firstApparentEnergy: item.apparentenergy,
        lastApparentEnergy: item.apparentenergy,

        firstImportActiveEnergy: item.importactenrgy,
        lastImportActiveEnergy: item.importactenrgy,

        firstExportActiveEnergy: item.exportactenrgy,
        lastExportActiveEnergy: item.exportactenrgy,

        firstImportReactiveEnergy: item.importreactenrgy,
        lastImportReactiveEnergy: item.importreactenrgy,

        firstExportReactiveEnergy: item.exportreactenrgy,
        lastExportReactiveEnergy: item.exportreactenrgy,

        firstRunHoursEnergy: item.runhours,
        lastRunHoursEnergy: item.runhours,
        hour: hourTimestamp,
      });
    } else {
      // Update the lastTimeStamp and lastPositivecumulative for the current hour
      result[index].lastTimeStamp = item.timestamp;
      result[index].lastActiveEnergy = item.activeenergy;
      result[index].lastReactiveEnergy = item.reactiveenergy;
      result[index].lastApparentEnergy = item.apparentenergy;
      result[index].lastImportActiveEnergy = item.importactenrgy;
      result[index].lastExportActiveEnergy = item.exportactenrgy;
      result[index].lastImportReactiveEnergy = item.importreactenrgy;
      result[index].lastExportReactiveEnergy = item.exportreactenrgy;
      result[index].lastRunHoursEnergy = item.runhours;
    }
  });

  return result;
}

export function processHourlyDataEnergy(
  inputData: HourlyData1[]
): HourlyData1[] {
  const processedData: HourlyData1[] = [];

  // Process the first object separately

  processedData.push({
    firstTimeStamp: inputData[0].firstTimeStamp,
    lastTimeStamp: inputData[0].lastTimeStamp,
    firstActiveEnergy: inputData[0].firstActiveEnergy,
    lastActiveEnergy: inputData[0].lastActiveEnergy,
    firstReactiveEnergy: inputData[0].firstReactiveEnergy,
    lastReactiveEnergy: inputData[0].lastReactiveEnergy,
    firstApparentEnergy: inputData[0].firstApparentEnergy,
    lastApparentEnergy: inputData[0].lastApparentEnergy,
    firstImportActiveEnergy: inputData[0].firstImportActiveEnergy,
    lastImportActiveEnergy: inputData[0].lastImportActiveEnergy,
    firstExportActiveEnergy: inputData[0].firstExportActiveEnergy,
    lastExportActiveEnergy: inputData[0].lastExportActiveEnergy,
    firstImportReactiveEnergy: inputData[0].firstImportReactiveEnergy,
    lastImportReactiveEnergy: inputData[0].lastImportReactiveEnergy,
    firstExportReactiveEnergy: inputData[0].firstExportReactiveEnergy,
    lastExportReactiveEnergy: inputData[0].lastExportReactiveEnergy,
    firstRunHoursEnergy: inputData[0].firstRunHoursEnergy,
    lastRunHoursEnergy: inputData[0].lastRunHoursEnergy,
    hour: inputData[0].hour,
  });
  // console.log(processedData, "proces");

  // Process the rest of the objects
  for (let i = 1; i < inputData.length; i++) {
    const currentHour = inputData[i];
    const previousHour = inputData[i - 1];

    processedData.push({
      firstTimeStamp: previousHour.lastTimeStamp,
      lastTimeStamp: currentHour.lastTimeStamp,

      firstActiveEnergy: previousHour.lastActiveEnergy,
      lastActiveEnergy: currentHour.lastActiveEnergy,

      firstReactiveEnergy: previousHour.lastReactiveEnergy,
      lastReactiveEnergy: currentHour.lastReactiveEnergy,

      firstApparentEnergy: previousHour.lastApparentEnergy,
      lastApparentEnergy: currentHour.lastApparentEnergy,

      firstImportActiveEnergy: previousHour.lastImportActiveEnergy,
      lastImportActiveEnergy: currentHour.lastImportActiveEnergy,

      firstExportActiveEnergy: previousHour.lastExportActiveEnergy,
      lastExportActiveEnergy: currentHour.lastExportActiveEnergy,

      firstImportReactiveEnergy: previousHour.lastImportReactiveEnergy,
      lastImportReactiveEnergy: currentHour.lastImportReactiveEnergy,

      firstExportReactiveEnergy: previousHour.lastExportReactiveEnergy,
      lastExportReactiveEnergy: currentHour.lastExportReactiveEnergy,

      firstRunHoursEnergy: previousHour.lastRunHoursEnergy,
      lastRunHoursEnergy: currentHour.lastRunHoursEnergy,

      hour: currentHour.hour,
    });
  }

  return processedData;
}

interface ConsumptionData1 {
  hour: string;
  activeconsumption: number;
  reactiveconsumption: number;
  apparentconsumption: number;
  importactiveconsumption?: number;
  exportactiveconsumption?: number;
  importreactiveconsumption?: number;
  exportreactiveconsumption?: number;
  runhoursconsumption?: number;
  firstTimeStamp: string;
  lastTimeStamp: string;
}
// this will return ths consumtion data
export function processConsumptionDataEnergy(inputData: any[]): any[] {
  const consumptionData: ConsumptionData1[] = [];

  for (let i = 0; i < inputData.length; i++) {
    const currentHour = inputData[i];

    let ae = +currentHour.lastActiveEnergy - +currentHour.firstActiveEnergy;
    let re = +currentHour?.lastReactiveEnergy - +currentHour.firstReactiveEnergy;
    let ape = +currentHour?.lastApparentEnergy - +currentHour.firstApparentEnergy;
    let ia = +currentHour?.lastImportActiveEnergy - +currentHour.firstImportActiveEnergy;
    let ea = +currentHour?.lastExportActiveEnergy - +currentHour.firstExportActiveEnergy;
    let ir = +currentHour?.lastImportReactiveEnergy - +currentHour.firstImportReactiveEnergy;
    let er = +currentHour?.lastExportReactiveEnergy - +currentHour.firstExportReactiveEnergy;
    let rh = +currentHour?.lastRunHoursEnergy - +currentHour.firstRunHoursEnergy;
    

    const consumption: any = {
      hour: currentHour.hour,
      activeconsumption: ae.toFixed(3),
      reactiveconsumption: re.toFixed(3),
      apparentconsumption: ape.toFixed(3),
      importactiveconsumption: ia.toFixed(3),
      exportactiveconsumption: ea.toFixed(3),
      importreactiveconsumption: ir.toFixed(3),
      exportreactiveconsumption: er.toFixed(3),
      runhoursconsumption: rh.toFixed(3),
      firstTimeStamp: currentHour.firstTimeStamp,
      lastTimeStamp: currentHour.lastTimeStamp,
    };

    consumptionData.push(consumption);
  }

  return consumptionData;
}

export function fillMissingHours(data: any) {
  const result: {
    hour: string;
    activeconsumption: number;
    reactiveconsumption: number;
    apparentconsumption: number;
    importactiveconsumption?: number;
    exportactiveconsumption?: number;
    importreactiveconsumption?: number;
    exportreactiveconsumption?: number;
    runhoursconsumption?: number;
    firstTimeStamp: null;
    lastTimeStamp: null;
  }[] = [];
  const currentDate = new Date(); // Get current date and time

  // Adjust current date and time to IST
  const currentISTDate = new Date(currentDate.getTime() + 5.5 * 60 * 60 * 1000);

  // Generate the last 24 hours in IST format 'YYYY-MM-DD HH'
  const last24Hours = [];
  for (let i = 0; i < 24; i++) {
    const hourAgo = new Date(currentISTDate.getTime() - i * 60 * 60 * 1000);
    const formattedHour = hourAgo.toISOString().slice(0, 13).replace("T", " ");
    last24Hours.push(formattedHour);
  }

  // Iterate over the generated hours and check if each hour is present in the data
  last24Hours.reverse().forEach((hour) => {
    const foundData = data.find((item: { hour: string }) => item.hour === hour);

    // If data for the hour is found, add it to the result, otherwise add default values
    if (foundData) {
      result.push(foundData);
    } else {
      result.push({
        hour: hour,
        activeconsumption: 0.0,
        reactiveconsumption: 0.0,
        apparentconsumption: 0.0,
        importactiveconsumption: 0.0,
        exportactiveconsumption: 0.0,
        importreactiveconsumption: 0.0,
        exportreactiveconsumption: 0.0,
        runhoursconsumption: 0.0,
        firstTimeStamp: null,
        lastTimeStamp: null,
      });
    }
  });

  return result;
}

interface HourlyData2 {
  firstTimeStamp: string;
  lastTimeStamp: string;

  firstActiveEnergy: string;
  lastActiveEnergy: string;

  firstReactiveEnergy: string;
  lastReactiveEnergy: string;

  firstApparentEnergy: string;
  lastApparentEnergy: string;

  firstImportActiveEnergy?: string;
  lastImportActiveEnergy?: string;

  firstExportActiveEnergy?: string;
  lastExportActiveEnergy?: string;

  firstImportReactiveEnergy?: string;
  lastImportReactiveEnergy?: string;

  firstExportReactiveEnergy?: string;
  lastExportReactiveEnergy?: string;

  firstRunHoursEnergy?: string;
  lastRunHoursEnergy?: string;

  day: string;
}

export function filterDataFromEachDayEnergy(data: any[]): HourlyData2[] {
  // Sort the data by timestamp
  data.sort(
    (a, b) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime()
  );

  // Initialize an array to store the results
  const result: HourlyData2[] = [];

  data.forEach((item: any) => {
    const dayTimestamp = item.timestamp.slice(0, 10); // Adjust the timestamp format to get the date part

    const index = result.findIndex(
      (dayData: any) => dayData.day === dayTimestamp
    );

    if (index === -1) {
      // If the day doesn't exist in the result array, initialize it
      result.push({
        firstTimeStamp: item.timestamp,
        lastTimeStamp: item.timestamp,
        firstActiveEnergy: item.activeenergy,
        lastActiveEnergy: item.activeenergy,
        firstReactiveEnergy: item.reactiveenergy,
        lastReactiveEnergy: item.reactiveenergy,
        firstApparentEnergy: item.apparentenergy,
        lastApparentEnergy: item.apparentenergy,

        firstImportActiveEnergy: item.importactenrgy,
        lastImportActiveEnergy: item.importactenrgy,

        firstExportActiveEnergy: item.exportactenrgy,
        lastExportActiveEnergy: item.exportactenrgy,

        firstImportReactiveEnergy: item.importreactenrgy,
        lastImportReactiveEnergy: item.importreactenrgy,

        firstExportReactiveEnergy: item.exportreactenrgy,
        lastExportReactiveEnergy: item.exportreactenrgy,

        firstRunHoursEnergy: item.runhours,
        lastRunHoursEnergy: item.runhours,

        day: dayTimestamp,
      });
    } else {
      // Update the lastTimeStamp and last energy values for the current day
      result[index].lastTimeStamp = item.timestamp;
      result[index].lastActiveEnergy = item.activeenergy;
      result[index].lastReactiveEnergy = item.reactiveenergy;
      result[index].lastApparentEnergy = item.apparentenergy;
      result[index].lastImportActiveEnergy = item.importactenrgy;
      result[index].lastExportActiveEnergy = item.exportactenrgy;
      result[index].lastImportReactiveEnergy = item.importreactenrgy;
      result[index].lastExportReactiveEnergy = item.exportreactenrgy;
      result[index].lastRunHoursEnergy = item.runhours;
    }
  });

  return result;
}

interface ConsumptionData2 {
  day: string;
  activeconsumption: number;
  reactiveconsumption: number;
  apparentconsumption: number;
  runhoursconsumption: number;
  firstTimeStamp: string;
  lastTimeStamp: string;
}

export function processMonthlyConsumptionEnergy(inputData: any[]) {
  const consumptionData: ConsumptionData2[] = [];

  for (let i = 0; i < inputData.length; i++) {
    const currentHour = inputData[i];

    let ae = +currentHour.lastActiveEnergy - +currentHour.firstActiveEnergy;
    let re = +currentHour?.lastReactiveEnergy - +currentHour.firstReactiveEnergy;
    let ape = +currentHour?.lastApparentEnergy - +currentHour.firstApparentEnergy;
    let ia = +currentHour?.lastImportActiveEnergy - +currentHour.firstImportActiveEnergy;
    let ea = +currentHour?.lastExportActiveEnergy - +currentHour.firstExportActiveEnergy;
    let ir = +currentHour?.lastImportReactiveEnergy - +currentHour.firstImportReactiveEnergy;
    let er = +currentHour?.lastExportReactiveEnergy - +currentHour.firstExportReactiveEnergy;
    let rh = +currentHour?.lastRunHoursEnergy - +currentHour.firstRunHoursEnergy;

    const consumption: any = {
      day: currentHour.day,
      activeconsumption: ae.toFixed(3),
      reactiveconsumption: re.toFixed(3),
      apparentconsumption: ape.toFixed(3),
      importactiveconsumption: ia.toFixed(3),
      exportactiveconsumption: ea.toFixed(3),
      importreactiveconsumption: ir.toFixed(3),
      exportreactiveconsumption: er.toFixed(3),
      runhoursconsumption: rh.toFixed(3),
      firstTimeStamp: currentHour.firstTimeStamp,
      lastTimeStamp: currentHour.lastTimeStamp,
    };

    consumptionData.push(consumption);
  }

  return consumptionData;
}


export function fillMissingDays(data: any) {
  const result: {
    day: string;
    activeconsumption: number;
    reactiveconsumption: number;
    apparentconsumption: number;
    importactiveconsumption?: number;
    exportactiveconsumption?: number;
    importreactiveconsumption?: number;
    exportreactiveconsumption?: number;
    runhoursconsumption?: number;
    firstTimeStamp: null | string;
    lastTimeStamp: null | string;
  }[] = [];

  const currentDate = new Date(); // Get current date and time

  // Adjust current date and time to IST
  const currentISTDate = new Date(currentDate.getTime() + 5.5 * 60 * 60 * 1000);

  // Get the start of the current month in IST
  const startOfMonth = new Date(
    currentISTDate.getFullYear(),
    currentISTDate.getMonth(),
    1,
    0,
    0,
    0,
    0
  );

  // Generate the days for the current month up to the current day
  const daysInMonth = [];
  const endOfCurrentDate = new Date(currentISTDate);
  endOfCurrentDate.setUTCHours(23, 59, 59, 999); // Ensure it includes the entire current day

  for (let d = new Date(startOfMonth); d <= endOfCurrentDate; d.setDate(d.getDate() + 1)) {
    const formattedDay = d.toISOString().slice(0, 10); // Format as 'YYYY-MM-DD'
    daysInMonth.push(formattedDay);
  }

  // Iterate over the generated days and check if each day is present in the data
  daysInMonth.forEach((day, index) => {
    if (index === 0) return; // Skip the first day of the month

    const foundData = data.find((item: { day: string }) => item.day === day);

    // If data for the day is found, add it to the result, otherwise add default values
    if (foundData) {
      result.push(foundData);
    } else {
      result.push({
        day: day,
        activeconsumption: 0.0,
        reactiveconsumption: 0.0,
        apparentconsumption: 0.0,
        importactiveconsumption: 0.0,
        exportactiveconsumption: 0.0,
        importreactiveconsumption: 0.0,
        exportreactiveconsumption: 0.0,
        runhoursconsumption: 0.0,
        firstTimeStamp: null,
        lastTimeStamp: null,
      });
    }
  });


  return result;
}



// Define the interface for the result data
interface HourlyData3 {
  firstTimeStamp: string;
  lastTimeStamp: string;
  firstActiveEnergy: number;
  lastActiveEnergy: number;
  firstReactiveEnergy: number;
  lastReactiveEnergy: number;
  firstApparentEnergy: number;
  lastApparentEnergy: number;
  month: string;
}

export function filterDataFromEachMonthEnergy(data: any[]): HourlyData3[] {
  // Sort the data by timestamp
  data.sort(
    (a, b) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime()
  );

  // Initialize an array to store the results
  const result: HourlyData3[] = [];

  data.forEach((item: any) => {
    const monthTimestamp = item.timestamp.slice(0, 7); // Adjust the timestamp format to get the 'YYYY-MM' part

    const index = result.findIndex(
      (monthData: any) => monthData.month === monthTimestamp
    );

    if (index === -1) {
      // If the month doesn't exist in the result array, initialize it
      result.push({
        firstTimeStamp: item.timestamp,
        lastTimeStamp: item.timestamp,
        firstActiveEnergy: item.activeenergy,
        lastActiveEnergy: item.activeenergy,
        firstReactiveEnergy: item.reactiveenergy,
        lastReactiveEnergy: item.reactiveenergy,
        firstApparentEnergy: item.apparentenergy,
        lastApparentEnergy: item.apparentenergy,
        month: monthTimestamp,
      });
    } else {
      // Update the lastTimeStamp and last energy values for the current month
      result[index].lastTimeStamp = item.timestamp;
      result[index].lastActiveEnergy = item.activeenergy;
      result[index].lastReactiveEnergy = item.reactiveenergy;
      result[index].lastApparentEnergy = item.apparentenergy;
    }
  });

  return result;
}

interface ConsumptionData3 {
  month: string;
  activeconsumption: number;
  reactiveconsumption: number;
  apparentconsumption: number;
  firstTimeStamp: string;
  lastTimeStamp: string;
}

export function processYearlyConsumptionEnergy(inputData: any[]) {
  const consumptionData: ConsumptionData3[] = [];

  for (let i = 0; i < inputData.length; i++) {
    const currentHour = inputData[i];

    let ae = +currentHour.firstActiveEnergy - +currentHour.lastActiveEnergy;
    let re =
      +currentHour?.firstReactiveEnergy - +currentHour.lastReactiveEnergy;
    let ape =
      +currentHour?.firstApparentEnergy - +currentHour.lastApparentEnergy;

    if (ae < 0) {
      ae = ae * -1;
    }
    if (re < 0) {
      re = re * -1;
    }
    if (ape < 0) {
      ape = ape * -1;
    }

    const consumption: any = {
      month: currentHour.month,
      activeconsumption: ae.toFixed(2),
      reactiveconsumption: re.toFixed(2),
      apparentconsumption: ape.toFixed(2),
      firstTimeStamp: currentHour.firstTimeStamp,
      lastTimeStamp: currentHour.lastTimeStamp,
    };

    consumptionData.push(consumption);
  }

  return consumptionData;
}
export function fillMissingMonths(data: any) {
  const result: {
    month: string;
    activeconsumption: number;
    reactiveconsumption: number;
    apparentconsumption: number;
    firstTimeStamp: null | string;
    lastTimeStamp: null | string;
  }[] = [];

  const currentDate = new Date(); // Get current date and time

  // Adjust current date and time to IST
  const currentISTDate = new Date(currentDate.getTime() + 5.5 * 60 * 60 * 1000);

  // Get the current year and month
  const currentYear = currentISTDate.getFullYear();
  const currentMonth = currentISTDate.getMonth() + 1; // Adding 1 to get the current month

  // Generate the months for the current year up to and including the current month
  const monthsInYear = [];
  for (let month = 1; month <= currentMonth; month++) {
    const formattedMonth = `${currentYear}-${month.toString().padStart(2, '0')}`; // Format as 'YYYY-MM'
    monthsInYear.push(formattedMonth);
  }

  // Iterate over the generated months and check if each month is present in the data
  monthsInYear.forEach((month) => {
    const foundData = data.find(
      (item: { month: string }) => item.month === month
    );

    // If data for the month is found, add it to the result, otherwise add default values
    if (foundData) {
      result.push(foundData);
    } else {
      result.push({
        month: month,
        activeconsumption: 0.0,
        reactiveconsumption: 0.0,
        apparentconsumption: 0.0,
        firstTimeStamp: null,
        lastTimeStamp: null,
      });
    }
  });

  // console.log(result, ";;");

  return result;
}







// FOR MAX DEMAND DASHBOARD

// Define the interface for the result data
interface HourlyData5 {
  firstTimeStamp: string;
  lastTimeStamp: string;
  firstActiveEnergy: number;
  lastActiveEnergy: number;
  halfHour: string;
}
export function filterDataFromEachHalfHourEnergy(data: any[]): HourlyData5[] {
  // Sort the data by timestamp
  data.sort(
    (a, b) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime()
  );

  // Calculate the timestamp for 24 hours ago from the current time
  // const twentyFourHoursAgo = new Date(Date.now() - 24 * 60 * 60 * 1000);

  // Filter the array to include only items from the last 24 hours from the current hour
  // const last24HoursData = data.filter(
  //   (item) => new Date(item.timestamp) >= 
  // );

  // Initialize an array to store the results
  const result: HourlyData5[] = [];

  data.forEach((item: any) => {
    const date = new Date(item.timestamp);
    const hour = date.getHours().toString().padStart(2, "0");
    const halfHour = date.getMinutes() < 30 ? "00" : "30";
    const halfHourTimestamp = `${item.timestamp.slice(
      0,
      10
    )} ${hour}:${halfHour}`;

    const index = result.findIndex(
      (halfHourlyData) => halfHourlyData.halfHour === halfHourTimestamp
    );

    if (index === -1) {
      // If the half-hour interval doesn't exist in the result array, initialize it
      result.push({
        firstTimeStamp: item.timestamp,
        lastTimeStamp: item.timestamp,
        firstActiveEnergy: item.activeenergy,
        lastActiveEnergy: item.activeenergy,
        halfHour: halfHourTimestamp,
      });
    } else {
      // Update the lastTimeStamp and last energy values for the current half-hour interval
      result[index].lastTimeStamp = item.timestamp;
      result[index].lastActiveEnergy = item.activeenergy;
    }
  });

  return result;
}

export function processHalfHourlyDataEnergy(
  inputData: HourlyData5[]
): HourlyData5[] {
  const processedData: HourlyData5[] = [];

  // Process the first object separately

  processedData.push({
    firstTimeStamp: inputData[0].firstTimeStamp,
    lastTimeStamp: inputData[0].lastTimeStamp,
    firstActiveEnergy: inputData[0].firstActiveEnergy,
    lastActiveEnergy: inputData[0].lastActiveEnergy,
    halfHour: inputData[0].halfHour,
  });
  // console.log(processedData, "proces");

  // Process the rest of the objects
  for (let i = 1; i < inputData.length; i++) {
    const currentHour = inputData[i];
    const previousHour = inputData[i - 1];

    processedData.push({
      firstTimeStamp: previousHour.lastTimeStamp,
      lastTimeStamp: currentHour.lastTimeStamp,

      firstActiveEnergy: previousHour.lastActiveEnergy,
      lastActiveEnergy: currentHour.lastActiveEnergy,

      halfHour: currentHour.halfHour,
    });
  }

  return processedData;
}

interface ConsumptionData5 {
  halfHour: string;
  activeconsumption: number;
  firstTimeStamp: string;
  lastTimeStamp: string;
}

export function processConsumptionHalfHourDataEnergy(inputData: any[]): any[] {
  const consumptionData: ConsumptionData5[] = [];

  for (let i = 0; i < inputData.length; i++) {
    const currentHour = inputData[i];

    let ae = +currentHour.firstActiveEnergy - +currentHour.lastActiveEnergy;

    if (ae < 0) {
      ae = ae * -1;
    }

    const consumption: any = {
      halfHour: currentHour.halfHour,
      activeconsumption: ae,
      firstTimeStamp: currentHour.firstTimeStamp,
      lastTimeStamp: currentHour.lastTimeStamp,
    };

    consumptionData.push(consumption);
  }

  return consumptionData;
}


export const formatNumber = (value: number): string => {
  if (value >= 1e12) return (value / 1e12).toFixed(2) + "T";
  if (value >= 1e9) return (value / 1e9).toFixed(2) + "B";
  if (value >= 1e6) return (value / 1e6).toFixed(2) + "M";
  if (value >= 1e3) return (value / 1e3).toFixed(2) + "K";
  return value.toFixed(3);
};


interface HourlyDataDaily {
  firstTimeStamp: string;
  lastTimeStamp: string;

  firstActiveEnergy: string;
  lastActiveEnergy: string;

  firstReactiveEnergy: string;
  lastReactiveEnergy: string;

  firstApparentEnergy: string;
  lastApparentEnergy: string;

  firstImportActiveEnergy?: string;
  lastImportActiveEnergy?: string;

  firstExportActiveEnergy?: string;
  lastExportActiveEnergy?: string;

  firstImportReactiveEnergy?: string;
  lastImportReactiveEnergy?: string;

  firstExportReactiveEnergy?: string;
  lastExportReactiveEnergy?: string;

  firstRunHourEnergy?: string;
  lastRunHourEnergy?: string;
  day: string;
}



export function processHourlyDataEnergyDaily(
  inputData: HourlyDataDaily[]
): HourlyDataDaily[] {
  const processedData: HourlyDataDaily[] = [];

  // Process the first object separately

  processedData.push({
    firstTimeStamp: inputData[0].firstTimeStamp,
    lastTimeStamp: inputData[0].lastTimeStamp,
    firstActiveEnergy: inputData[0].firstActiveEnergy,
    lastActiveEnergy: inputData[0].lastActiveEnergy,
    firstReactiveEnergy: inputData[0].firstReactiveEnergy,
    lastReactiveEnergy: inputData[0].lastReactiveEnergy,
    firstApparentEnergy: inputData[0].firstApparentEnergy,
    lastApparentEnergy: inputData[0].lastApparentEnergy,
    firstImportActiveEnergy: inputData[0].firstImportActiveEnergy,
    lastImportActiveEnergy: inputData[0].lastImportActiveEnergy,
    firstExportActiveEnergy: inputData[0].firstExportActiveEnergy,
    lastExportActiveEnergy: inputData[0].lastExportActiveEnergy,
    firstImportReactiveEnergy: inputData[0].firstImportReactiveEnergy,
    lastImportReactiveEnergy: inputData[0].lastImportReactiveEnergy,
    firstExportReactiveEnergy: inputData[0].firstExportReactiveEnergy,
    lastExportReactiveEnergy: inputData[0].lastExportReactiveEnergy,
    lastRunHourEnergy: inputData[0].lastRunHourEnergy,
    day: inputData[0].day,
  });
  // console.log(processedData, "proces");

  // Process the rest of the objects
  for (let i = 1; i < inputData.length; i++) {
    const currentHour = inputData[i];
    const previousHour = inputData[i - 1];

    processedData.push({
      firstTimeStamp: previousHour.lastTimeStamp,
      lastTimeStamp: currentHour.lastTimeStamp,

      firstActiveEnergy: previousHour.lastActiveEnergy,
      lastActiveEnergy: currentHour.lastActiveEnergy,

      firstReactiveEnergy: previousHour.lastReactiveEnergy,
      lastReactiveEnergy: currentHour.lastReactiveEnergy,

      firstApparentEnergy: previousHour.lastApparentEnergy,
      lastApparentEnergy: currentHour.lastApparentEnergy,

      firstImportActiveEnergy: previousHour.lastImportActiveEnergy,
      lastImportActiveEnergy: currentHour.lastImportActiveEnergy,

      firstExportActiveEnergy: previousHour.lastExportActiveEnergy,
      lastExportActiveEnergy: currentHour.lastExportActiveEnergy,

      firstImportReactiveEnergy: previousHour.lastImportReactiveEnergy,
      lastImportReactiveEnergy: currentHour.lastImportReactiveEnergy,

      firstExportReactiveEnergy: previousHour.lastExportReactiveEnergy,
      lastExportReactiveEnergy: currentHour.lastExportReactiveEnergy,

      firstRunHourEnergy: previousHour.lastRunHourEnergy,
      lastRunHourEnergy: currentHour.lastRunHourEnergy,

      day: currentHour.day,
    });
  }

  return processedData;
}


interface HourlyDataMonthly {
  firstTimeStamp: string;
  lastTimeStamp: string;
  firstActiveEnergy: number;
  lastActiveEnergy: number;
  firstReactiveEnergy: number;
  lastReactiveEnergy: number;
  firstApparentEnergy: number;
  lastApparentEnergy: number;
  month: string;
}



export function processHourlyDataEnergyMonthly(
  inputData: HourlyDataMonthly[]
): HourlyDataMonthly[] {
  const processedData: HourlyDataMonthly[] = [];

  // Process the first object separately

  processedData.push({
    firstTimeStamp: inputData[0].firstTimeStamp,
    lastTimeStamp: inputData[0].lastTimeStamp,
    firstActiveEnergy: inputData[0].firstActiveEnergy,
    lastActiveEnergy: inputData[0].lastActiveEnergy,
    firstReactiveEnergy: inputData[0].firstReactiveEnergy,
    lastReactiveEnergy: inputData[0].lastReactiveEnergy,
    firstApparentEnergy: inputData[0].firstApparentEnergy,
    lastApparentEnergy: inputData[0].lastApparentEnergy,
    month: inputData[0].month,
  });
  // console.log(processedData, "proces");

  // Process the rest of the objects
  for (let i = 1; i < inputData.length; i++) {
    const currentHour = inputData[i];
    const previousHour = inputData[i - 1];

    processedData.push({
      firstTimeStamp: previousHour.lastTimeStamp,
      lastTimeStamp: currentHour.lastTimeStamp,

      firstActiveEnergy: previousHour.lastActiveEnergy,
      lastActiveEnergy: currentHour.lastActiveEnergy,

      firstReactiveEnergy: previousHour.lastReactiveEnergy,
      lastReactiveEnergy: currentHour.lastReactiveEnergy,

      firstApparentEnergy: previousHour.lastApparentEnergy,
      lastApparentEnergy: currentHour.lastApparentEnergy,

      month: currentHour.month,
    });
  }

  return processedData;
}