import {
  Box,
  Button,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Template } from "../../../types/TemplateType";
import { useTemplateState } from "../../../app-context/template-context/TemplateState";
import DeleteIcon from "@mui/icons-material/Delete";
import { useParams } from "react-router-dom";
import { http } from "../../../helper/http";
import { fetchAllTemplate } from "../../../common-function/CommonFunction";
import { ConfirmModal } from "../../../utils/alertui/ConfirmModal";
import { toast } from "react-toastify";

const F6_Config_Template = () => {
  const [template, setTemplate] = useState<any[]>([]);
  const [selectedTemplate, setSelectedTemplate] = useState("");
  const [selectedSlaveId, setSelectedSlaveID] = useState<string | null>(null);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [deleteId, setDeleteId] = useState<number | null>(null);
  const [apiData, setApiData] = useState<any[]>([]);
  const { id } = useParams();
  const baseUrl = process.env.REACT_APP_BASE_URL;

  useEffect(() => {
    fetchData();
  }, [id]);

  const handleSave = async () => {
    const data = {
      deviceId: id,
      templateId: selectedTemplate,
      slaveId: selectedSlaveId,
    };
    try {
      await http(`${baseUrl}/f6-template`, "POST", data);
      toast.success("Template saved successfully");
      fetchData();
    } catch (error) {
      console.error(error);
    }
  };

  const handleDelete = async (rowId: any) => {
    setShowConfirmModal(true);
    setDeleteId(rowId);
  };

  const fetchData = async () => {
    try {
      const allTemplate = await fetchAllTemplate();
      if (allTemplate) {
        const filteredTemplates = allTemplate.template.filter(
          (template) => template.id !== 39
        );
        setTemplate(filteredTemplates);
      }

      const resp: any = await http(`${baseUrl}/f6-template?deviceId=${id}`);
      if (resp) {
        const processedData = resp.map((i: any) => ({
          id: i.id,
          tempName: i.Template.templatename,
          slaveId: i.slaveId,
        }));
        setApiData(processedData);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleConfirmDelete = async () => {
    if (deleteId !== null) {
      try {
        await http(`${baseUrl}/f6-template?id=${deleteId}`, "DELETE");
        fetchData();
        toast.success("Successfully deleted");
        setShowConfirmModal(false);
      } catch (error) {
        console.error(error);
      }
    }
  };

  return (
    <>
      <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <Paper sx={{ width: "99%", marginTop: "20px" }}>
          <div className="mb-10">
            <p className="text-2xl font-semibold text-slate-500">
              F6 Template Config
            </p>
          </div>
          <div
            style={{
              width: "70%",
              display: "flex",
              flexDirection: "row",
              gap: "40px",
              marginLeft: "5px",
            }}
          >
            <div
              style={{ width: "45%", display: "flex", flexDirection: "column" }}
            >
              <InputLabel id="selectunit">Select the Template</InputLabel>
              <Select
                labelId="selecttemplate"
                id="select-template"
                value={selectedTemplate}
                label="Select Unit"
                onChange={(e) => setSelectedTemplate(e.target.value)}
                fullWidth
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 300,
                    },
                  },
                }}
              >
                {template.map((template) => (
                  <MenuItem
                    key={template.id}
                    value={template.id}
                    style={{
                      paddingLeft: "20px",
                      display: "block",
                      marginBottom: "5px",
                    }}
                  >
                    {template.templatename}
                  </MenuItem>
                ))}
              </Select>
            </div>
            <div
              style={{ width: "30%", display: "flex", flexDirection: "column" }}
            >
              <InputLabel id="selectSlaveID">Slave ID</InputLabel>
              <Select
                labelId="selectSlaveID"
                id="select-slave-id"
                value={selectedSlaveId}
                label="Slave ID"
                onChange={(e) => setSelectedSlaveID(e.target.value)}
                fullWidth
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 200, // Adjust the maxHeight value as needed
                    },
                  },
                }}
              >
                {Array.from({ length: 32 }, (_, i) => (
                  <MenuItem key={i + 1} value={i + 1}>
                    {i + 1}
                  </MenuItem>
                ))}
              </Select>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "20px",
              }}
            >
              <Button variant="contained" color="primary" onClick={handleSave}>
                Save
              </Button>
            </div>
          </div>
          <div style={{ marginTop: "60px" }}>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell
                      sx={{
                        backgroundColor: "lightgray",
                        fontSize: "lg",
                        fontWeight: "bold",
                      }}
                    >
                      Serial No
                    </TableCell>
                    <TableCell
                      sx={{
                        backgroundColor: "lightgray",
                        fontSize: "lg",
                        fontWeight: "bold",
                      }}
                    >
                      Template Name
                    </TableCell>
                    <TableCell
                      sx={{
                        backgroundColor: "lightgray",
                        fontSize: "lg",
                        fontWeight: "bold",
                      }}
                    >
                      SlaveId
                    </TableCell>
                    <TableCell
                      sx={{
                        backgroundColor: "lightgray",
                        fontSize: "lg",
                        fontWeight: "bold",
                      }}
                    >
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {apiData
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => (
                      <TableRow key={row.id}>
                        <TableCell>{page * rowsPerPage + index + 1}</TableCell>
                        <TableCell>{row.tempName}</TableCell>
                        <TableCell>{row.slaveId}</TableCell>
                        <TableCell>
                          <DeleteIcon
                            onClick={() => handleDelete(row.id)}
                            sx={{ cursor: "pointer", color: "gray" }}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={apiData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableContainer>
          </div>
          {showConfirmModal && (
            <ConfirmModal
              title="Confirmation"
              message="Are you sure you want to delete?"
              onConfirm={handleConfirmDelete}
              onCancel={() => {
                setShowConfirmModal(false);
              }}
              isVisible={showConfirmModal}
            />
          )}
        </Paper>
      </Box>
    </>
  );
};

export default F6_Config_Template;
