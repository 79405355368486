import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { http } from '../../../helper/http';
import { Button, TextField, FormControlLabel, Checkbox } from '@mui/material';


const HwraDetails = ({ deviceId, onClose } : any) => {
  const [data, setData]: any = useState({
    nocNumber: '',
    userKey: '',
    companyName: '',
    abstractionStructureNumber: '',
    vendorFirmsName: '',
    status: false,
  });
  

  const [submitting, setSubmitting]: any = useState(false);

  async function fetchData() {
    try {
      const resp: any = await http('/hwra?deviceId=' + deviceId);
      setData(resp); // Assuming the response structure matches the 'data' state
    } catch (err: any) {
      toast.error(err);
    }
  }

  useEffect(() => {
    if (deviceId) {
      fetchData();
    }
  }, [deviceId]);

  function handleInputChange(event: any) {
    const { name, value } = event.target;
    setData((prevData: any) => ({ ...prevData, [name]: value }));
  }

  function handleCheckboxChange(event: any) {
    const { name, checked } = event.target;
    setData((prevData: any) => ({ ...prevData, [name]: checked }));
  }

  async function handleSubmit() {
    if (submitting) {
      return;
    }
    setSubmitting(true);
    if(deviceId === null){
      setSubmitting(false);
      onClose();
    } else{
      try {
        const resp: any = await http('/hwra?deviceId=' + deviceId, 'PUT', data);
        toast.success(resp.message);
        setSubmitting(false);
        onClose();
      } catch (err: any) {
        toast.error(err);
        setSubmitting(false);
      }
    }
  }

  return (
    <>
      <div className="mb-4"></div>
      <div className="mb-4">
        <TextField
          label="NOC Number"
          name="nocNumber"
          value={data.nocNumber}
          onChange={handleInputChange}
          className="w-full"
        />
      </div>
      <div className="mb-4">
        <TextField
          label="User Key"
          name="userKey"
          value={data.userKey}
          onChange={handleInputChange}
          className="w-full"
        />
      </div>
      <div className="mb-4">
        <TextField
          label="Company Name"
          name="companyName"
          value={data.companyName}
          onChange={handleInputChange}
          className="w-full"
        />
      </div>
      <div className="mb-4">
        <TextField
          label="Abstraction Structure Number"
          name="abstractionStructureNumber"
          value={data.abstractionStructureNumber}
          onChange={handleInputChange}
          className="w-full"
        />
      </div>
      <div className="mb-4">
        <TextField
          label="Vendor Firms Name"
          name="vendorFirmsName"
          value={data.vendorFirmsName}
          onChange={handleInputChange}
          className="w-full"
        />
      </div>
      <div className="mb-4">
        <FormControlLabel
          control={<Checkbox name="status" checked={data.status} onChange={handleCheckboxChange} />}
          label="Status"
        />
      </div>
      <Button variant="contained" color="primary" size="large" className="w-full" onClick={handleSubmit}>
        SAVE
      </Button>
    </>
  );
};

export default HwraDetails;