import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Toolbar, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import AddDeviceFormModal from "./AddDeviceFormModal";
import {
  Device,
  DeviceApiResponse,
  createDefaultDevice,
} from "../../../types/DeviceTypes";
import { getDevicePageClick, setDevice } from "../../../app-context/device-context/DeviceAction";
import { useDeviceState } from "../../../app-context/device-context/DeviceState";
import { http } from "../../../helper/http";
import AddIcon from "@mui/icons-material/Add";
import {
  setAllCustomer,
} from "../../../app-context/customer-context/CustomerAction";
import { useCustomerState } from "../../../app-context/customer-context/CustomerState";
import ProtectedComponent from "../../../utils/protected-route/ProtectedComponent";
import { fetchAllCustomer, fetchAllTemplate } from "../../../common-function/CommonFunction";
import { useTemplateState } from "../../../app-context/template-context/TemplateState";
import { setAllTemplate } from "../../../app-context/template-context/TemplateAction";
import { toast } from "react-toastify";

const baseUrl = process.env.REACT_APP_BASE_URL;

interface EnhancedTableToolbarProps {
  search: string;
  setSearch: (search: string) => void;
}

export function EnhancedTableToolbar({ search, setSearch }: EnhancedTableToolbarProps) {
  const [toggleForm, setToggleForm] = React.useState(false);
  const { dispatch } = useDeviceState();
  const { dispatch: customerDispatch } = useCustomerState();
  const { dispatch: templateDispatch } = useTemplateState();
  const [openBulkDeviceDialog, setOpenBulkDeviceDialog] = useState(false);
  const [file, setFile] = useState(null);

  const toggleDeviceForm = async () => {
    setToggleForm((prevValue) => !prevValue);
    try {
      const resp = (await fetchAllCustomer()) as any;
      setAllCustomer(customerDispatch, resp.customers);
      const respTemplate = (await fetchAllTemplate()) as any;
      setAllTemplate(templateDispatch, respTemplate.template);
    } catch (error) {
      console.error(error);
    }
  };

  const handleBulkDeviceClick = () => {
    setOpenBulkDeviceDialog(true);
  };

  const handleFileChange = (event: any) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
    }
  };

  const handleUpload = async () => {
    if (file) {
      try {
        const formData = new FormData();
        formData.append("file", file);
        const response = (await http(
          baseUrl + `/device/bulk-upload`,
          "POST",
          formData
        ) as any) 
      
        if (response.status === 200) {
          toast.success(response.data.message);
        }
      } catch (error: any) {
          toast.error(error || 'File upload failed');
      }
    } else {
      toast.error('No file selected');
    }
  };

  const handleCloseDialog = () => {
    setOpenBulkDeviceDialog(false);
    setFile(null);
  };



  // const handleUpdateDeviceActiveOrInactive = async () => {
  //   try {
  //     const resp = (await http(baseUrl + "/device", 'PATCH')) as any;
  //     setDevice(dispatch, 1);
  //     // console.log("ACTIVEINACTIVE", resp);
  //     toast.success(resp.message);
  //   } catch (error) {
  //     console.error("no device found");
  //   }
  // };

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
      }}
    >
      <Typography
        sx={{
          flex: "1 1 100%",
          fontWeight: 700,
          fontSize: [
            "text-sm",
            "text-md",
            "text-lg",
            "text-xl",
            "text-2xl",
            "text-3xl",
          ],
        }}
        variant="h6"
        id="tableTitle"
        component="div"
        className="shadow-gray-500 text-black font-bold"
      >
        Devices
      </Typography>

      <ProtectedComponent>
        <div className="mr-2">
          <div className="mr-2" style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "teal",
                color: "white",
                height: 32,
                fontWeight: "bold",
                fontSize: "1rem",
                transition: "background-color 200ms",
                "&:hover": {
                  backgroundColor: "teal",
                },
              }}
              onClick={toggleDeviceForm}
            >
              <AddIcon />
              ADD
            </Button>

            {/* Add the Bulk Device button */}
            <Button
              variant="contained"
              sx={{
                backgroundColor: "teal",
                color: "white",
                width: "150px",
                height: 32,
                fontWeight: "bold",
                fontSize: "1rem",
                transition: "background-color 200ms",
                "&:hover": {
                  backgroundColor: "teal",
                },
              }}
              onClick={handleBulkDeviceClick}
            >
              <AddIcon />
              BULK DEVICE
            </Button>
          </div>

          <Dialog open={openBulkDeviceDialog} onClose={handleCloseDialog}>
            <DialogTitle>Upload Bulk Devices</DialogTitle>
            <DialogContent>
              <Typography variant="body1" gutterBottom>
                Select a CSV or Excel file to upload.
              </Typography>
              <input
                type="file"
                accept=".csv, .xlsx, .xls"
                onChange={handleFileChange}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseDialog} color="primary">
                Cancel
              </Button>
              <Button onClick={handleUpload} color="primary">
                Upload
              </Button>
            </DialogActions>
          </Dialog>

          {toggleForm && (
            <AddDeviceFormModal
              uniqueId="ADD"
              data={[createDefaultDevice()]}
              toggleForm={toggleForm}
              toggleFormFun={toggleDeviceForm}
            />
          )}
        </div>
        <div className="flex flex-col sm:flex-row items-center space-y-2 sm:space-y-0 sm:space-x-2">
          <input
            type="search"
            placeholder="Search..."
            name="search"
            value={search}
            onChange={(event) => {
              setSearch(event.target.value);
            }}
            className="border border-black rounded p-1 w-full sm:w-auto"
          />
          {/* <Button
            variant="contained"
            sx={{
              marginLeft: "2px",
              backgroundColor: "teal",
              color: "white",
              height: 32,
              fontWeight: "bold",
              fontSize: "1rem",
              transition: "background-color 200ms",
              "&:hover": {
                backgroundColor: "teal",
              },
            }}
            onClick={handleUpdateDeviceActiveOrInactive}
          >
            Check 
          </Button> */}
        </div>
      </ProtectedComponent>
    </Toolbar>
  );
}
