import React, {useState, useEffect } from "react";
import Table from "@mui/material/Table";
import Box from "@mui/material/Box";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import EnhancedTableHead from "./EnhancedTableHead";
import LongMenu from "../../../utils/longMenu/LongMenu";
import { http } from "../../../helper/http";
import { ConfirmModal } from "../../../utils/alertui/ConfirmModal";
import { toast } from "react-toastify";
import AddTemplateForm from "./AddTemplateForm";
import EnhancedTableToolbar from "./EnhancedTableToolbar";
import { useTemplateState } from "../../../app-context/template-context/TemplateState";
import { ApiResponseTemplate, Template, TemplateApiResponse } from "../../../types/TemplateType";
import { getTemplatePageClick, setTemplate } from "../../../app-context/template-context/TemplateAction";
import { useCustomerState } from "../../../app-context/customer-context/CustomerState";
import { fetchAllCustomer } from "../../../common-function/CommonFunction";
import { setAllCustomer } from "../../../app-context/customer-context/CustomerAction";


const baseUrl = process.env.REACT_APP_BASE_URL;
export default function Templates() {
  const [page] = useState(0);
  const [rowsPerPage] = useState(10);
  const [updatedRows, setUpdatedRows] = useState<Template[]>([]);
  const [templateData, seTemplateData] = useState<Template[]>([]);
  const [showConfirmModal, setShowConfirmModal] = React.useState(false);
  const [editModal, setEditModal] = useState(false);
  const [deleteId, setDeleteId] = useState<number | null>(null);

  const {templates, dispatch: templateDispatch}= useTemplateState()
  const { dispatch: customerDispatch } = useCustomerState();

  const toggleTagForm = () => {
    setEditModal((prevVal) => !prevVal);
  };

  // const getCustomerNameById = (customerId: number | undefined) => {
  //   const customer = customers.customers.find((customer: { id: number | undefined; }) => customer.id === customerId);
  //   return customer ? customer.name : '';
  // }


     useEffect(() => {
      if(templates.templates.length === 0){
        setTemplate(templateDispatch, 1);

      }
    }, []);
  useEffect(() => {
     
    // setUpdatedRows(templates.templates);
    // console.log('modified', updatedRowsWithNames)
    // console.log(updatedRows.);
    
    setUpdatedRows(templates.templates);
  }, [templates, templates.previousPage]);


  const handleMenuOptionClick = async (option: string, id: number) => {
    try {
      switch (option) {
        case "Delete":
          setShowConfirmModal(true);
          setDeleteId(id);
          break;
        case "Edit":
          const resp = (await fetchAllCustomer()) as any;
          setAllCustomer(customerDispatch, resp.customers);
          const editResp = (await http(
            baseUrl + `/templates?id=${id}`
          )) as TemplateApiResponse;
          seTemplateData([editResp.templates]);
          toggleTagForm();
          break;
        case "View":
          // const viewResp = (await http(
          //   baseUrl + `/device?id=${id}`
          // )) as DeviceApiResponse;
          // setDeviceData([viewResp.devices]);
          // toggleViewForm();
          break;
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleConfirmDelete = async () => {
    if (deleteId !== null) {
      try {
        const deleteResp = (await http(
          baseUrl + `/templates?id=${deleteId}`,
          "DELETE"
        )) as TemplateApiResponse;
        toast.success(deleteResp.message);
        if (templates.templates.length === 1) {
          setTemplate(templateDispatch, 1);
        } else {
          setTemplate(templateDispatch, templates.currentPage);
        }
      } catch (err) {
        console.error(err);
      }
      setShowConfirmModal(false);
    }
  };

  const handlePageChange = async (newPage: number) => {
    const direction = newPage > templates.currentPage - 1 ? "next" : "previous";
    const page = +templates.currentPage;
    try {
      const resp = (await http(
        baseUrl + `/templates?page=${direction === "next" ? page + 1 : page - 1}`
      )) as ApiResponseTemplate;
      getTemplatePageClick(templateDispatch, resp.templates);
    } catch (err) {
      console.error(err);
    }
  };


  const emptyRows = 10 - updatedRows.length;
  const visibleRows = React.useMemo(
    () =>
      updatedRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    [page, rowsPerPage, updatedRows]
  );

  
  // const handleSwitchToggle = async (id: number, status?: boolean) => {
  //   try {
  //     const resp = (await http(
  //       baseUrl + `/tags?id=${id}&status=${!status}`,
  //       "PATCH"
  //     )) as TagApiResponse;
  //     updateTagStatus(dispatch, { id, status: !status });
  //     toast.success(resp.message);
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };
  return (
    <>
      <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <Paper sx={{ width: "99%", mb: 2, mt: 2 }}>
          <EnhancedTableToolbar onFilter={()=>{}} />
          <div className="h-[72vh] overflow-y-auto">
            <TableContainer>
            {showConfirmModal && (
                <ConfirmModal
                  title="Confirmation"
                  message="Are you sure you want to delete?"
                  onConfirm={handleConfirmDelete}
                  onCancel={() => {
                    setShowConfirmModal(false);
                  }}
                  isVisible={showConfirmModal}
                />
              )}
              <Table
                sx={{ minWidth: 750 }}
                aria-labelledby="tableTitle"
                size="medium"
              >
                {editModal && (
                  <AddTemplateForm
                    uniqueId="EDIT"
                    data={templateData}
                    toggleForm={editModal}
                    toggleFormFun={toggleTagForm}
                  />
                )}
                <EnhancedTableHead />
                <TableBody>
                  {visibleRows.map((row: Template) => {
                    return (
                      <TableRow
                        hover
                        className="cursor-pointer transition-all hover:bg-gray-100"
                        key={row.id}
                      >
                        <TableCell align="center" style={{ textAlign: "center" }}>
                          {row.id}
                        </TableCell>
                        <TableCell align="center" style={{ textAlign: "center" }}>
                          {row.templatename}
                        </TableCell>
                        <TableCell align="center" style={{ textAlign: "center" }}>
                          {row?.Customer?.name || "NA"}
                        </TableCell>
                        {/* <TableCell
                          className="text-center"
                          style={{ textAlign: "center" }}
                        >
                          <Switch
                            checked={Boolean(row.status !== undefined ? row.status : false)}
                            color="primary"
                            onChange={() =>
                              handleSwitchToggle(row.id || 0, Boolean(row.status))
                            }
                          />
                        </TableCell> */}
                        <TableCell
                          className="text-center"
                          style={{ textAlign: "center" }}
                        >
                          <LongMenu
                            options={["Edit", "Delete"]}
                            onOptionClick={handleMenuOptionClick}
                            id={row.id}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow
                      style={{
                        height: 53 * emptyRows,
                      }}
                    >
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          <TablePagination
            rowsPerPageOptions={[]}
            component="div"
            count={templates?.totalItems || 0}
            rowsPerPage={10}
            page={Math.max(
              0,
              Math.min(templates?.currentPage - 1, templates?.totalPages - 1)
            )}
            onPageChange={(event, newPage) => handlePageChange(newPage)}
            className="bg-gray-100"
          />
        </Paper>
      </Box>
    </>
  );
}
