import React, { useCallback, useEffect, useRef, useState } from "react";

// import "../../App.css";
import {
  formatDate,
  formatTimestamp,
  generateCSV1,
  reportDataWithGraph,
} from "../../../common-function/CommonFunction";

import LineChart from "../../../components/apex-chart/LineChart";
import CardThree from "../../../components/dashBoardComponents/CardThree";
import LongMenu from "../../../utils/longMenu/LongMenu";
import MobileTowerIcon from "../../../components/apex-chart/HeatMapChart";
import { http } from "../../../helper/http";
import {
  fillMissingDays,
  fillMissingHours,
  filterDataFromEachDayEnergy,
  filterDataFromEachHourEnergy,
  processConsumptionDataEnergy,
  processHourlyDataEnergy,
  processHourlyDataEnergyDaily,
  processMonthlyConsumptionEnergy,
} from "./common-functions";
import CircularProgress from "@mui/material/CircularProgress";
import DynamicBarChart from "../../../components/apex-chart/CommonBarChart2";
import DynamicLineChart from "../../../components/apex-chart/DynamicLineChart";

const baseUrl = process.env.REACT_APP_BASE_URL;
const DashboardEnergySinglePhase = ({ devices, selectedDevice }: any) => {
  //material tabs
  const [value, setValue] = React.useState(0);
  const [valueAnalysis, setvalueAnalysis] = React.useState(0);

  const [apiData, setApiData] = useState<any>([]);
  const barchartref1 = useRef<HTMLDivElement>(null);
  const barchartref2 = useRef<HTMLDivElement>(null);
  const chartRef = useRef<HTMLDivElement>(null);
  const chartRef4 = useRef<HTMLDivElement>(null);
  const [dashboardSts, setDashboardSts] = useState<any>([]);
  const [loader, setLoader] = React.useState(false);
  const [customTagData, setCustomTagData] = useState<any>([]);

  function formatNumericProperty(value: any) {
    //console.log('value',value)
    if (!isNaN(value) && value !== null && value !== undefined) {
      return parseFloat(value).toFixed(3);
    }
    return value;
  }

  const fetchData = async () => {
    let dof = new Date();

    let response: any = null;
    try {
      const formattedDate = formatDate(dof);
      let queryParam = "";
      if (value === 0) {
        queryParam =
          valueAnalysis === 0
            ? // ? `day=${formattedDate.slice(0, 10)}`
              `last24=last24`
            : valueAnalysis === 1
            ? `month=${formattedDate.slice(5, 7)}`
            : "";
      } else if (value === 1) {
        queryParam = `last24=last24`;
      }
      response = await http(
        `${baseUrl}/device-data-log?deviceId=${selectedDevice}&${queryParam}`
      );
      // console.log("response", response);
      
      const _data = response
        .map((d: any, i: any) => {
          return {
            timestamp: formatTimestamp(d.timeStamp),
            activeenergy: formatNumericProperty(d["t_acte"]),
            reactiveenergy: formatNumericProperty(d["t_re"]),
            apparentenergy: formatNumericProperty(d["t_apte"]),
            runhours: formatNumericProperty(d["r_hr"]),

            voltage: formatNumericProperty(d["v"]),
            current: formatNumericProperty(d["a"]),
            actpower: formatNumericProperty(d["kW"]),
            reactpower: formatNumericProperty(d["kvar"]),
            apparpower: formatNumericProperty(d["kva"]),
            frequency: formatNumericProperty(d["hz"]),
            powfactor: formatNumericProperty(d["pf"]),
            extra1: formatNumericProperty(d["ex1"]),
            extra2: formatNumericProperty(d["ex2"]),
            extra3: formatNumericProperty(d["ex3"]),
            extra4: formatNumericProperty(d["ex4"]),
            extra5: formatNumericProperty(d["ex5"]),

            rssi: formatNumericProperty(d["rssi"]),
          };
        })
        .filter((d: any) => {
          return !Object.values(d).some(
            (val: any) =>
              typeof val === "string" && val.toLowerCase() === "error"
          );
        });

      setDashboardSts(_data.length ? _data[0] : "");
      const sortedData = _data.sort((a: any, b: any) => {
        return (
          new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime()
        );
      });
      setApiData(sortedData);
      setLoader(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
    
    if (devices?.DeviceTemplate?.tagJson) {
      const transformedTagJson = devices.DeviceTemplate.tagJson.reduce(
        (acc: any, tag: any) => {
          acc[tag.tagName] = tag;
          return acc;
        },
        {}
      );
      setCustomTagData(transformedTagJson);
    }

    const intervalId = setInterval(fetchData, 120000);
    return () => clearInterval(intervalId);
  }, [selectedDevice, valueAnalysis, value]);

  let energy_data_pdfData: any = [];
  let active_energy_last24hour: any = [];
  let reactive_energy_last24hour: any = [];
  let apparent_energy_last24hour: any = [];
  let runhours_energy_last24hour: any = [];

  let voltageData: any = [];
  let currentData: any = [];
  let activePowData: any = [];
  let reactivePowData: any = [];
  let apparPowData: any = [];
  let frequencyData: any = [];
  let powFactorData: any = [];
  let extra1Data: any = [];
  let extra2Data: any = [];
  let extra3Data: any = [];
  let extra4Data: any = [];
  let extra5Data: any = [];
  let instLineChartData: any = [];

  if (value === 0) {
    if (apiData.length > 0) {
      apiData.forEach(
        ({
          timestamp,
          activeenergy,
          reactiveenergy,
          apparentenergy,
          runhours,
        }: any) => {
          energy_data_pdfData.push({
            timestamp,
            // Active Energy
            ...(customTagData?.["t_acte"]?.sensorStatus
              ? {
                  [`${customTagData["t_acte"]?.sensorAlias} (${
                    customTagData["t_acte"]?.sensorUnit || "kWh"
                  })`]: activeenergy,
                }
              : !customTagData?.["t_acte"]
              ? { "Active Energy (kWh)": activeenergy }
              : {}),

            // Reactive Energy
            ...(customTagData?.["t_re"]?.sensorStatus
              ? {
                  [`${customTagData["t_re"]?.sensorAlias} (${
                    customTagData["t_re"]?.sensorUnit || "kVarh"
                  })`]: reactiveenergy,
                }
              : !customTagData?.["t_re"]
              ? { "Reactive Energy (kVarh)": reactiveenergy }
              : {}),

            // Apparent Energy
            ...(customTagData?.["t_apte"]?.sensorStatus
              ? {
                  [`${customTagData["t_apte"]?.sensorAlias} (${
                    customTagData["t_apte"]?.sensorUnit || "kVah"
                  })`]: apparentenergy,
                }
              : !customTagData?.["t_apte"]
              ? { "Apparent Energy (kVah)": apparentenergy }
              : {}),

            // Run Hours
            ...(customTagData?.["r_hr"]?.sensorStatus
              ? {
                  [`${customTagData["r_hr"]?.sensorAlias} (${
                    customTagData["r_hr"]?.sensorUnit || "hr"
                  })`]: runhours,
                }
              : !customTagData?.["r_hr"]
              ? { "Run Hours (hr)": runhours }
              : {}),
          });

          active_energy_last24hour.push({
            timestamp,
            value:
              activeenergy === undefined || activeenergy === ""
                ? 0
                : activeenergy,
          });

          reactive_energy_last24hour.push({
            timestamp,
            value:
              reactiveenergy === undefined || reactiveenergy === ""
                ? 0
                : reactiveenergy,
          });

          apparent_energy_last24hour.push({
            timestamp,
            value:
              apparentenergy === undefined || apparentenergy === ""
                ? 0
                : apparentenergy,
          });

          runhours_energy_last24hour.push({
            timestamp,
            value: runhours === undefined || runhours === "" ? 0 : runhours,
          });
        }
      );
    }
  } else if (value === 1) {
    if (apiData.length > 0) {
      apiData.forEach(
        ({
          timestamp,
          voltage,
          current,
          actpower,
          reactpower,
          apparpower,
          frequency,
          powfactor,
          extra1,
          extra2,
          extra3,
          extra4,
          extra5,
        }: any) => {
          voltageData.push({
            timestamp,
            value: isNaN(voltage) || voltage === undefined ? 0 : voltage,
          });

          currentData.push({
            timestamp,
            value: isNaN(current) || current === undefined ? 0 : current,
          });

          activePowData.push({
            timestamp,
            value: isNaN(actpower) || actpower === undefined ? 0 : actpower,
          });

          reactivePowData.push({
            timestamp,
            value:
              isNaN(reactpower) || reactpower === undefined ? 0 : reactpower,
          });

          apparPowData.push({
            timestamp,
            value:
              isNaN(apparpower) || apparpower === undefined ? 0 : apparpower,
          });

          frequencyData.push({
            timestamp,
            value: isNaN(frequency) || frequency === undefined ? 0 : frequency,
          });

          powFactorData.push({
            timestamp,
            value: isNaN(powfactor) || powfactor === undefined ? 0 : powfactor,
          });

          extra1Data.push({
            timestamp,
            value: isNaN(extra1) || extra1 === undefined ? 0 : extra1,
          });

          extra2Data.push({
            timestamp,
            value: isNaN(extra2) || extra2 === undefined ? 0 : extra2,
          });

          extra3Data.push({
            timestamp,
            value: isNaN(extra3) || extra3 === undefined ? 0 : extra3,
          });

          extra4Data.push({
            timestamp,
            value: isNaN(extra4) || extra4 === undefined ? 0 : extra4,
          });

          extra5Data.push({
            timestamp,
            value: isNaN(extra5) || extra5 === undefined ? 0 : extra5,
          });

          instLineChartData.push({
            TimeStamp: timestamp,
            ...(customTagData?.["v"]?.sensorStatus
              ? {
                  [`${customTagData["v"]?.sensorAlias} (${customTagData["v"]?.sensorUnit})`]:
                    voltage,
                }
              : !customTagData?.["v"]
              ? { "Voltage (kVah)": voltage }
              : {}),

            ...(customTagData?.["a"]?.sensorStatus
              ? {
                  [`${customTagData["a"]?.sensorAlias} (${customTagData["a"]?.sensorUnit})`]:
                    current,
                }
              : !customTagData?.["a"]
              ? { "Current (V)": current }
              : {}),

            ...(customTagData?.["kW"]?.sensorStatus
              ? {
                  [`${customTagData["kW"]?.sensorAlias} (${customTagData["kW"]?.sensorUnit})`]:
                    actpower,
                }
              : !customTagData?.["kW"]
              ? { "Active Energy (A)": actpower }
              : {}),

            ...(customTagData?.["kvar"]?.sensorStatus
              ? {
                  [`${customTagData["kvar"]?.sensorAlias} (${customTagData["kvar"]?.sensorUnit})`]:
                    reactpower,
                }
              : !customTagData?.["kvar"]
              ? { "Reactive Energy (kW)": reactpower }
              : {}),

            ...(customTagData?.["kva"]?.sensorStatus
              ? {
                  [`${customTagData["kva"]?.sensorAlias} (${customTagData["kva"]?.sensorUnit})`]:
                    apparpower,
                }
              : !customTagData?.["kva"]
              ? { "Apparent Energy (kVar)": apparpower }
              : {}),

            ...(customTagData?.["hz"]?.sensorStatus
              ? {
                  [`${customTagData["hz"]?.sensorAlias} (${customTagData["hz"]?.sensorUnit})`]:
                    frequency,
                }
              : !customTagData?.["hz"]
              ? { "Frequncy (hz)": frequency }
              : {}),

            ...(customTagData?.["pf"]?.sensorStatus
              ? {
                  [`${customTagData["pf"]?.sensorAlias} (${customTagData["pf"]?.sensorUnit})`]:
                    frequency,
                }
              : !customTagData?.["pf"]
              ? { "Power Factor (-)": powfactor }
              : {}),

            ...(customTagData?.["ex1"]?.sensorStatus
              ? {
                  [`${customTagData["ex1"]?.sensorAlias} (${customTagData["ex1"]?.sensorUnit})`]:
                    extra1,
                }
              : !customTagData?.["ex1"]
              ? { "Extra 1 (0)": extra1 }
              : {}),

            ...(customTagData?.["ex2"]?.sensorStatus
              ? {
                  [`${customTagData["ex2"]?.sensorAlias} (${customTagData["ex2"]?.sensorUnit})`]:
                    extra2,
                }
              : !customTagData?.["ex2"]
              ? { "Extra 2 (0)": extra2 }
              : {}),

            ...(customTagData?.["ex3"]?.sensorStatus
              ? {
                  [`${customTagData["ex3"]?.sensorAlias} (${customTagData["ex3"]?.sensorUnit})`]:
                    extra3,
                }
              : !customTagData?.["ex3"]
              ? { "Extra 3 (0)": extra3 }
              : {}),

            ...(customTagData?.["ex4"]?.sensorStatus
              ? {
                  [`${customTagData["ex4"]?.sensorAlias} (${customTagData["ex4"]?.sensorUnit})`]:
                    extra4,
                }
              : !customTagData?.["ex4"]
              ? { "Extra 4 (0)": extra4 }
              : {}),

            ...(customTagData?.["ex5"]?.sensorStatus
              ? {
                  [`${customTagData["ex5"]?.sensorAlias} (${customTagData["ex5"]?.sensorUnit})`]:
                    extra5,
                }
              : !customTagData?.["ex5"]
              ? { "Extra 5 (0)": extra5 }
              : {}),
          });
        }
      );
    }
  }

  const linechartEnergyData: any = [
    ...(customTagData?.["t_acte"]?.sensorStatus === true ||
    !customTagData?.["t_acte"]
      ? [
          {
            name: customTagData?.["t_acte"]?.sensorAlias || "Active Energy",
            color: "#FF0000",
            data: active_energy_last24hour,
            postfix: customTagData?.["t_acte"]?.sensorUnit || " kWh",
          },
        ]
      : []),

    ...(customTagData?.["t_re"]?.sensorStatus === true ||
    !customTagData?.["t_re"]
      ? [
          {
            name: customTagData?.["t_re"]?.sensorAlias || "Reactive Energy",
            color: "#FFFF00",
            data: reactive_energy_last24hour,
            postfix: customTagData?.["t_re"]?.sensorUnit || " kVarh",
          },
        ]
      : []),

    ...(customTagData?.["t_apte"]?.sensorStatus === true ||
    !customTagData?.["t_apte"]
      ? [
          {
            name: customTagData?.["t_apte"]?.sensorAlias || "Apparent Energy",
            color: "#0000FF",
            data: apparent_energy_last24hour,
            postfix: customTagData?.["t_apte"]?.sensorUnit || " kVah",
          },
        ]
      : []),

    ...(customTagData?.["r_hr"]?.sensorStatus === true ||
    !customTagData?.["r_hr"]
      ? [
          {
            name: customTagData?.["r_hr"]?.sensorAlias || "Run Hours",
            color: "#228B22",
            data: runhours_energy_last24hour,
            postfix: customTagData?.["r_hr"]?.sensorUnit || " hr",
          },
        ]
      : []),
  ];

  const lineChartOfInstData: any = [
    ...(customTagData?.["v"]?.sensorStatus === true || !customTagData?.["v"]
      ? [
          {
            name: customTagData?.["v"]?.sensorAlias || "Voltage (kVah)",
            color: "#8B0000",
            data: voltageData,
            postfix: customTagData?.["v"]?.sensorUnit || " kVah",
          },
        ]
      : []),

    ...(customTagData?.["a"]?.sensorStatus === true || !customTagData?.["a"]
      ? [
          {
            name: customTagData?.["a"]?.sensorAlias || "Current (V)",
            color: "#00008B",
            data: currentData,
            postfix: customTagData?.["a"]?.sensorUnit || " V",
          },
        ]
      : []),

    ...(customTagData?.["kW"]?.sensorStatus === true || !customTagData?.["kW"]
      ? [
          {
            name: customTagData?.["kW"]?.sensorAlias || "Active Power (A)",
            color: "#006400",
            data: activePowData,
            postfix: customTagData?.["kW"]?.sensorUnit || " A",
          },
        ]
      : []),

    ...(customTagData?.["kvar"]?.sensorStatus === true ||
    !customTagData?.["kvar"]
      ? [
          {
            name: customTagData?.["kvar"]?.sensorAlias || "Reactive Power (kW)",
            color: "#FF8C00",
            data: reactivePowData,
            postfix: customTagData?.["kvar"]?.sensorUnit || " kW",
          },
        ]
      : []),

    ...(customTagData?.["kva"]?.sensorStatus === true || !customTagData?.["kva"]
      ? [
          {
            name:
              customTagData?.["kva"]?.sensorAlias || "Apparent Power (kVar)",
            color: "#9400D3",
            data: apparPowData,
            postfix: customTagData?.["kva"]?.sensorUnit || " kVar",
          },
        ]
      : []),

    ...(customTagData?.["hz"]?.sensorStatus === true || !customTagData?.["hz"]
      ? [
          {
            name: customTagData?.["hz"]?.sensorAlias || "Frequncy (kVa)",
            color: "#2F4F4F",
            data: frequencyData,
            postfix: customTagData?.["hz"]?.sensorUnit || " kVa",
          },
        ]
      : []),

    ...(customTagData?.["pf"]?.sensorStatus === true || !customTagData?.["pf"]
      ? [
          {
            name: customTagData?.["pf"]?.sensorAlias || "Power Factor (-)",
            color: "#8B008B",
            data: powFactorData,
            postfix: customTagData?.["pf"]?.sensorUnit || " -",
          },
        ]
      : []),

    ...(customTagData?.["ex1"]?.sensorStatus === true || !customTagData?.["ex1"]
      ? [
          {
            name: customTagData?.["ex1"]?.sensorAlias || "Extra 1 (0)",
            color: "#556B2F",
            data: extra1Data,
            postfix: customTagData?.["ex1"]?.sensorUnit || " 0",
          },
        ]
      : []),

    ...(customTagData?.["ex2"]?.sensorStatus === true || !customTagData?.["ex2"]
      ? [
          {
            name: customTagData?.["ex2"]?.sensorAlias || "Extra 2 (0)",
            color: "#00CED1",
            data: extra2Data,
            postfix: customTagData?.["ex2"]?.sensorUnit || " 0",
          },
        ]
      : []),

    ...(customTagData?.["ex3"]?.sensorStatus === true || !customTagData?.["ex3"]
      ? [
          {
            name: customTagData?.["ex3"]?.sensorAlias || "Extra 3 (0)",
            color: "#B8860B",
            data: extra3Data,
            postfix: customTagData?.["ex3"]?.sensorUnit || " 0",
          },
        ]
      : []),

    ...(customTagData?.["ex4"]?.sensorStatus === true || !customTagData?.["ex4"]
      ? [
          {
            name: customTagData?.["ex4"]?.sensorAlias || "Extra 4 (0)",
            color: "#BDB76B",
            data: extra4Data,
            postfix: customTagData?.["ex4"]?.sensorUnit || " 0",
          },
        ]
      : []),

    ...(customTagData?.["ex5"]?.sensorStatus === true || !customTagData?.["ex5"]
      ? [
          {
            name: customTagData?.["ex5"]?.sensorAlias || "Extra 5 (0)",
            color: "#8FBC8F",
            data: extra5Data,
            postfix: customTagData?.["ex5"]?.sensorUnit || " 0",
          },
        ]
      : []),
  ];

  const handleExportBarChart = async (
    exportOption: string,
    dataValue: any,
    chart: any,
    label?: string
  ) => {
    switch (exportOption) {
      case "Download as CSV":
        generateCSV1(dataValue);
        break;
      case "Download as PDF":
        if (dataValue && chart) {
          reportDataWithGraph(
            chart,
            dataValue,
            devices?.deviceName,
            devices?.Customer?.name,
            "Energy Summary",
            label ? label : "Realtime - Last 24 Hour | IST"
          );
        } else {
        }
        return;
      default:
        return;
    }
  };

  let emptyBar: any[] | undefined;
  let consumtionData: any[] | undefined;
  if (valueAnalysis === 0) {
    const filterLast24hourDataOfEachHOur: any =
      filterDataFromEachHourEnergy(apiData);
    if (filterLast24hourDataOfEachHOur.length > 0) {
      const hourData = processHourlyDataEnergy(filterLast24hourDataOfEachHOur);
      consumtionData = processConsumptionDataEnergy(hourData);
      emptyBar = consumtionData ? fillMissingHours(consumtionData) : [];
    }
  } else if (valueAnalysis === 1) {
    let t: any = filterDataFromEachDayEnergy(apiData);
    if (t.length > 0) {
      const dailyData = processHourlyDataEnergyDaily(t);
      consumtionData = processMonthlyConsumptionEnergy(dailyData);

      emptyBar = consumtionData ? fillMissingDays(consumtionData) : [];
    }
  }

  const bargraph: any = [
    ...(customTagData?.["t_acte"]?.sensorStatus === true ||
    !customTagData?.["t_acte"]
      ? [
          {
            name: customTagData?.["t_acte"]?.sensorAlias || "Active Energy",
            color: "#101828",
            values: emptyBar
              ? emptyBar.map((item: any) => item.activeconsumption)
              : [],
            postfix: customTagData?.["t_acte"]?.sensorUnit || " kWh",
          },
        ]
      : []),

    ...(customTagData?.["t_re"]?.sensorStatus === true ||
    !customTagData?.["t_re"]
      ? [
          {
            name: customTagData?.["t_re"]?.sensorAlias || "Reactive Energy",
            color: "#0777A7",
            values: emptyBar
              ? emptyBar.map((item: any) => item.reactiveconsumption)
              : [],
            postfix: customTagData?.["t_re"]?.sensorUnit || " kVarh",
          },
        ]
      : []),

    ...(customTagData?.["t_apte"]?.sensorStatus === true ||
    !customTagData?.["t_apte"]
      ? [
          {
            name: customTagData?.["t_apte"]?.sensorAlias || "Apparent Energy",
            color: "#5EAFCF",
            values: emptyBar
              ? emptyBar.map((item: any) => item.apparentconsumption)
              : [],
            postfix: customTagData?.["t_apte"]?.sensorUnit || " kVAh",
          },
        ]
      : []),

    ...(customTagData?.["r_hr"]?.sensorStatus === true ||
    !customTagData?.["r_hr"]
      ? [
          {
            name: customTagData?.["r_hr"]?.sensorAlias || "Run Hours",
            color: "#59E659",
            values: emptyBar
              ? emptyBar.map((item: any) => item.runhoursconsumption)
              : [],
            postfix: customTagData?.["r_hr"]?.sensorUnit || " rh",
          },
        ]
      : []),
  ];
  const xAxisCategories = emptyBar
    ? emptyBar.map((item) =>
        valueAnalysis === 0 ? item.hour + ":00" : item.day
      )
    : [];
  // console.log("xAxisCategories1212", xAxisCategories);
  const handleTabClick = useCallback((index: number) => {
    setvalueAnalysis(+index);
    if (index === 1) {
      setLoader(true);
    }
  }, []);


  return (
    <div>
      <div className="p-2 mb-4">
        <section style={{ display: "flex", alignItems: "center" }}>
          <div style={{ flex: 1 }}>
            <div style={{ display: "flex", alignItems: "start" }}>
              <MobileTowerIcon
                levels={dashboardSts?.rssi || 0}
                time={dashboardSts?.timestamp}
              />
              <p className="ml-2">
                {"Last Data Received at " +
                  (dashboardSts?.timestamp || "YYYY-MM-DD HH:MM:SS")}
              </p>
            </div>
          </div>

          <div className="w-[280px] ">
            <ul className="text-sm font-medium text-center text-gray-500 rounded-lg shadow sm:flex dark:divide-gray-700 dark:text-gray-400">
              <li className="w-full focus-within:z-10">
                <button
                  className={`inline-block w-full border border-gray-300 px-2 py-2 text-[1rem] text-gray-900 rounded-s-lg  transition-colors duration-300`}
                  aria-current="page"
                  style={value === 0 ? { backgroundColor: "#dadada" } : {}}
                  onClick={() => setValue(0)}
                >
                  Analysis
                </button>
              </li>

              <li className="w-full focus-within:z-10">
                <button
                  className={`inline-block w-full border border-gray-300 px-2 py-2 text-[1rem] text-gray-900 rounded-e-lg transition-colors duration-300`}
                  style={value === 1 ? { backgroundColor: "#dadada" } : {}}
                  onClick={() => setValue(1)}
                >
                  Instantaneous
                </button>
              </li>
            </ul>
          </div>
        </section>
      </div>
      {value === 0 && (
        <div className="border border-gray-400 p-2 mb-4 mt-5">
          <div className="w-[350px]">
            <ul className="text-sm font-medium text-center text-gray-500 rounded-lg shadow sm:flex dark:divide-gray-700 dark:text-gray-400">
              {["Meter Reading", "Daily Consumption"].map((item, index) => (
                <li key={item} className="w-full focus-within:z-10">
                  <button
                    className={`inline-block w-full border border-gray-300 px-1 py-2 text-[1rem] text-gray-900 ${
                      index === 0 ? "rounded-s-lg" : "rounded-e-lg"
                    } transition-colors duration-300`}
                    style={
                      valueAnalysis === index
                        ? { backgroundColor: "#dadada" }
                        : {}
                    }
                    onClick={() => handleTabClick(index)}
                  >
                    {item}
                  </button>
                </li>
              ))}
            </ul>
          </div>

          {value === 0 && valueAnalysis === 0 && (
            <div>
              <div className="flex flex-col justify-between mt-5">
                <div className="border rounded-md border-gray-400 h-1/2 p-4">
                  <div className="grid grid-cols-4 gap-5 my-cardTwo">
                    {customTagData?.["t_acte"]?.sensorStatus ? (
                      <CardThree
                        title={
                          customTagData?.["t_acte"]?.sensorAlias ||
                          "Active Energy"
                        }
                        amount={
                          active_energy_last24hour.length !== 0
                            ? `${
                                active_energy_last24hour[
                                  active_energy_last24hour.length - 1
                                ].value
                              } ${
                                customTagData?.["t_acte"]?.sensorUnit || "kWh"
                              }`
                            : `N/A kWh`
                        }
                      />
                    ) : !customTagData?.["t_acte"] ? (
                      <CardThree
                        title="Active Energy"
                        amount={
                          active_energy_last24hour.length !== 0
                            ? `${
                                active_energy_last24hour[
                                  active_energy_last24hour.length - 1
                                ].value
                              } kWh`
                            : `N/A kWh`
                        }
                      />
                    ) : null}

                    {/* Reactive Energy Card */}
                    {customTagData?.["t_re"]?.sensorStatus ? (
                      <CardThree
                        title={
                          customTagData?.["t_re"]?.sensorAlias ||
                          "Reactive Energy"
                        }
                        amount={
                          reactive_energy_last24hour.length !== 0
                            ? `${
                                reactive_energy_last24hour[
                                  reactive_energy_last24hour.length - 1
                                ].value
                              } ${
                                customTagData?.["t_re"]?.sensorUnit || "kVarh"
                              }`
                            : `N/A kVarh`
                        }
                      />
                    ) : !customTagData?.["t_re"] ? (
                      <CardThree
                        title="Reactive Energy"
                        amount={
                          reactive_energy_last24hour.length !== 0
                            ? `${
                                reactive_energy_last24hour[
                                  reactive_energy_last24hour.length - 1
                                ].value
                              } kVarh`
                            : `N/A kVarh`
                        }
                      />
                    ) : null}

                    {/* Apparent Energy Card */}
                    {customTagData?.["t_apte"]?.sensorStatus ? (
                      <CardThree
                        title={
                          customTagData?.["t_apte"]?.sensorAlias ||
                          "Apparent Energy"
                        }
                        amount={
                          apparent_energy_last24hour.length !== 0
                            ? `${
                                apparent_energy_last24hour[
                                  apparent_energy_last24hour.length - 1
                                ].value
                              } ${
                                customTagData?.["t_apte"]?.sensorUnit || "kVah"
                              }`
                            : `N/A kVah`
                        }
                      />
                    ) : !customTagData?.["t_apte"] ? (
                      <CardThree
                        title="Apparent Energy"
                        amount={
                          apparent_energy_last24hour.length !== 0
                            ? `${
                                apparent_energy_last24hour[
                                  apparent_energy_last24hour.length - 1
                                ].value
                              } kVah`
                            : `N/A kVah`
                        }
                      />
                    ) : null}

                    {/* Run Hours Energy Card */}
                    {customTagData?.["r_hr"]?.sensorStatus ? (
                      <CardThree
                        title={
                          customTagData?.["r_hr"]?.sensorAlias || "Run Hours"
                        }
                        amount={
                          runhours_energy_last24hour.length !== 0
                            ? `${
                                runhours_energy_last24hour[
                                  runhours_energy_last24hour.length - 1
                                ].value
                              } ${customTagData?.["r_hr"]?.sensorUnit || "hr"}`
                            : `N/A hr`
                        }
                      />
                    ) : !customTagData?.["r_hr"] ? (
                      <CardThree
                        title="Run Hours"
                        amount={
                          runhours_energy_last24hour.length !== 0
                            ? `${
                                runhours_energy_last24hour[
                                  runhours_energy_last24hour.length - 1
                                ].value
                              } hr`
                            : `N/A hr`
                        }
                      />
                    ) : null}
                  </div>
                </div>
                <div className="h-4"></div>
                <div className="border rounded-md border-gray-400 h-1/2 p-2">
                  <div className="flex justify-center p-2">
                    <div
                      className="my_temp_humidity"
                      style={{
                        padding: "10px",
                        width: "100%",
                      }}
                    >
                      <div className="relative">
                        <p>
                          Energy meter reading data variation with time(between{" "}
                          {apiData.length > 0
                            ? apiData[0].timestamp +
                              " to " +
                              apiData[apiData.length - 1].timestamp
                            : " "}
                          )
                        </p>
                        <div className="absolute top-0 right-0">
                          <LongMenu
                            options={["Download as CSV", "Download as PDF"]}
                            onOptionClick={(exportOption) =>
                              handleExportBarChart(
                                exportOption,
                                energy_data_pdfData,
                                chartRef
                              )
                            }
                            id={1}
                          />
                        </div>
                        <div>
                          <div
                            ref={chartRef || ""}
                            style={{ marginTop: "20px" }}
                          >
                            <LineChart
                              data={linechartEnergyData}
                              yAxisName="Energy Data"
                              xAxisName="Time"
                              stroke={true}
                            />
                          </div>
                        </div>
                        <div className="mb-4 mt-10">
                          <div className="relative mb-2 mt-1">
                            <p>
                              Hourly energy data variation with time(between{" "}
                              {emptyBar
                                ? emptyBar[0].hour +
                                  ":00 to " +
                                  emptyBar[emptyBar.length - 1].hour +
                                  ":00"
                                : " "}
                              )
                            </p>
                            <div className="absolute top-0 right-0">
                              <LongMenu
                                options={["Download as CSV", "Download as PDF"]}
                                onOptionClick={(exportOption) =>
                                  handleExportBarChart(
                                    exportOption,
                                    consumtionData?.map(
                                      ({
                                        hour,
                                        activeconsumption,
                                        reactiveconsumption,
                                        apparentconsumption,
                                        runhoursconsumption,
                                        firstTimeStamp,
                                        lastTimeStamp,
                                      }: any) => {
                                        return {
                                          Hour: hour + ": 00",
                                          "First Timestamp": firstTimeStamp,
                                          "Last Timestamp": lastTimeStamp,
                                          // Active Energy
                                          ...(customTagData?.["t_acte"]
                                            ?.sensorStatus
                                            ? {
                                                [`${customTagData["t_acte"]?.sensorAlias} (${customTagData["t_acte"]?.sensorUnit})`]:
                                                  activeconsumption,
                                              }
                                            : !customTagData?.["t_acte"]
                                            ? {
                                                "Active(kWh)":
                                                  activeconsumption,
                                              }
                                            : {}),

                                          // Reactive Energy
                                          ...(customTagData?.["t_re"]
                                            ?.sensorStatus
                                            ? {
                                                [`${customTagData["t_re"]?.sensorAlias} (${customTagData["t_re"]?.sensorUnit})`]:
                                                  reactiveconsumption,
                                              }
                                            : !customTagData?.["t_re"]
                                            ? {
                                                "Reactive(kVarh)":
                                                  reactiveconsumption,
                                              }
                                            : {}),

                                          // Apparent Energy
                                          ...(customTagData?.["t_apte"]
                                            ?.sensorStatus
                                            ? {
                                                [`${customTagData["t_apte"]?.sensorAlias} (${customTagData["t_apte"]?.sensorUnit})`]:
                                                  apparentconsumption,
                                              }
                                            : !customTagData?.["t_apte"]
                                            ? {
                                                "Apparent(kVah)":
                                                  apparentconsumption,
                                              }
                                            : {}),

                                          // Run Hours
                                          ...(customTagData?.["r_hr"]
                                            ?.sensorStatus
                                            ? {
                                                [`${customTagData["r_hr"]?.sensorAlias} (${customTagData["r_hr"]?.sensorUnit})`]:
                                                  runhoursconsumption,
                                              }
                                            : !customTagData?.["r_hr"]
                                            ? {
                                                "Run Hours (rh)":
                                                  runhoursconsumption,
                                              }
                                            : {}),
                                        };
                                      }
                                    ),
                                    barchartref1,
                                    "Hourly Data Variations"
                                  )
                                }
                                id={1}
                              />
                            </div>
                          </div>
                          <div ref={barchartref1 || ""}>
                            <DynamicBarChart
                              data={bargraph}
                              xAxisCategories={xAxisCategories}
                              yAxisName="Energy Consumption"
                              xAxisName="Time"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {value === 0 && valueAnalysis === 1 && (
            <div>
              <div className="flex flex-col justify-between mt-5">
                <div className="border rounded-md border-gray-400 h-1/2 p-4">
                  <div className="grid grid-cols-4 gap-5 my-cardTwo">
                    {customTagData?.["t_acte"]?.sensorStatus ? (
                      <CardThree
                        title={
                          customTagData?.["t_acte"]?.sensorAlias ||
                          "Active Energy"
                        }
                        amount={
                          active_energy_last24hour.length !== 0
                            ? `${
                                active_energy_last24hour[
                                  active_energy_last24hour.length - 1
                                ].value
                              } ${
                                customTagData?.["t_acte"]?.sensorUnit || "kWh"
                              }`
                            : `N/A kWh`
                        }
                      />
                    ) : !customTagData?.["t_acte"] ? (
                      <CardThree
                        title="Active Energy"
                        amount={
                          active_energy_last24hour.length !== 0
                            ? `${
                                active_energy_last24hour[
                                  active_energy_last24hour.length - 1
                                ].value
                              } kWh`
                            : `N/A kWh`
                        }
                      />
                    ) : null}

                    {/* Reactive Energy Card */}
                    {customTagData?.["t_re"]?.sensorStatus ? (
                      <CardThree
                        title={
                          customTagData?.["t_re"]?.sensorAlias ||
                          "Reactive Energy"
                        }
                        amount={
                          reactive_energy_last24hour.length !== 0
                            ? `${
                                reactive_energy_last24hour[
                                  reactive_energy_last24hour.length - 1
                                ].value
                              } ${
                                customTagData?.["t_re"]?.sensorUnit || "kVarh"
                              }`
                            : `N/A kVarh`
                        }
                      />
                    ) : !customTagData?.["t_re"] ? (
                      <CardThree
                        title="Reactive Energy"
                        amount={
                          reactive_energy_last24hour.length !== 0
                            ? `${
                                reactive_energy_last24hour[
                                  reactive_energy_last24hour.length - 1
                                ].value
                              } kVarh`
                            : `N/A kVarh`
                        }
                      />
                    ) : null}

                    {/* Apparent Energy Card */}
                    {customTagData?.["t_apte"]?.sensorStatus ? (
                      <CardThree
                        title={
                          customTagData?.["t_apte"]?.sensorAlias ||
                          "Apparent Energy"
                        }
                        amount={
                          apparent_energy_last24hour.length !== 0
                            ? `${
                                apparent_energy_last24hour[
                                  apparent_energy_last24hour.length - 1
                                ].value
                              } ${
                                customTagData?.["t_apte"]?.sensorUnit || "kVah"
                              }`
                            : `N/A kVah`
                        }
                      />
                    ) : !customTagData?.["t_apte"] ? (
                      <CardThree
                        title="Apparent Energy"
                        amount={
                          apparent_energy_last24hour.length !== 0
                            ? `${
                                apparent_energy_last24hour[
                                  apparent_energy_last24hour.length - 1
                                ].value
                              } kVah`
                            : `N/A kVah`
                        }
                      />
                    ) : null}

                    {/* Run Hours Energy Card */}
                    {customTagData?.["r_hr"]?.sensorStatus ? (
                      <CardThree
                        title={
                          customTagData?.["r_hr"]?.sensorAlias || "Run Hours"
                        }
                        amount={
                          runhours_energy_last24hour.length !== 0
                            ? `${
                                runhours_energy_last24hour[
                                  runhours_energy_last24hour.length - 1
                                ].value
                              } ${customTagData?.["r_hr"]?.sensorUnit || "hr"}`
                            : `N/A hr`
                        }
                      />
                    ) : !customTagData?.["r_hr"] ? (
                      <CardThree
                        title="Run Hours"
                        amount={
                          runhours_energy_last24hour.length !== 0
                            ? `${
                                runhours_energy_last24hour[
                                  runhours_energy_last24hour.length - 1
                                ].value
                              } hr`
                            : `N/A hr`
                        }
                      />
                    ) : null}
                  </div>
                </div>

                {/* Space between columns */}
                <div className="h-4"></div>

                {/* Second Column */}
                <div className="border rounded-md border-gray-400 h-1/2 p-2">
                  {/* Content for the second column */}
                  {/* You can add your bar graph component here */}
                  <div className="flex justify-center p-2">
                    <div
                      className="my_temp_humidity"
                      style={{
                        padding: "10px",
                        width: "100%",
                      }}
                    >
                      <div className="relative">
                        <div className="mb-4 mt-10">
                          {loader ? (
                            <div className="h-96 w-full flex justify-center items-center">
                              <CircularProgress color="inherit" />
                            </div>
                          ) : (
                            <>
                              <div className="relative mb-2 mt-1">
                                <p>
                                  Daily energy data variation with time(between{" "}
                                  {emptyBar
                                    ? emptyBar[0].day +
                                      " to " +
                                      emptyBar[emptyBar.length - 1].day
                                    : " "}
                                  )
                                </p>
                                <div className="absolute top-0 right-0">
                                  <LongMenu
                                    options={[
                                      "Download as CSV",
                                      "Download as PDF",
                                    ]}
                                    onOptionClick={(exportOption) =>
                                      handleExportBarChart(
                                        exportOption,
                                        consumtionData?.map(
                                          ({
                                            day,
                                            activeconsumption,
                                            reactiveconsumption,
                                            apparentconsumption,
                                            runhoursconsumption,
                                            firstTimeStamp,
                                            lastTimeStamp,
                                          }: any) => {
                                            return {
                                              Day: day,
                                              "First Timestamp": firstTimeStamp,
                                              "Last Timestamp": lastTimeStamp,
                                              // Active Energy
                                              ...(customTagData?.["t_acte"]
                                                ?.sensorStatus
                                                ? {
                                                    [`${customTagData["t_acte"]?.sensorAlias} (${customTagData["t_acte"]?.sensorUnit})`]:
                                                      activeconsumption,
                                                  }
                                                : !customTagData?.["t_acte"]
                                                ? {
                                                    "Active(kWh)":
                                                      activeconsumption,
                                                  }
                                                : {}),

                                              // Reactive Energy
                                              ...(customTagData?.["t_re"]
                                                ?.sensorStatus
                                                ? {
                                                    [`${customTagData["t_re"]?.sensorAlias} (${customTagData["t_re"]?.sensorUnit})`]:
                                                      reactiveconsumption,
                                                  }
                                                : !customTagData?.["t_re"]
                                                ? {
                                                    "Reactive(kVarh)":
                                                      reactiveconsumption,
                                                  }
                                                : {}),

                                              // Apparent Energy
                                              ...(customTagData?.["t_apte"]
                                                ?.sensorStatus
                                                ? {
                                                    [`${customTagData["t_apte"]?.sensorAlias} (${customTagData["t_apte"]?.sensorUnit})`]:
                                                      apparentconsumption,
                                                  }
                                                : !customTagData?.["t_apte"]
                                                ? {
                                                    "Apparent(kVah)":
                                                      apparentconsumption,
                                                  }
                                                : {}),

                                              // Run Hours
                                              ...(customTagData?.["r_hr"]
                                                ?.sensorStatus
                                                ? {
                                                    [`${customTagData["r_hr"]?.sensorAlias} (${customTagData["r_hr"]?.sensorUnit})`]:
                                                      runhoursconsumption,
                                                  }
                                                : !customTagData?.["r_hr"]
                                                ? {
                                                    "Run Hours (rh)":
                                                      runhoursconsumption,
                                                  }
                                                : {}),
                                            };
                                          }
                                        ),
                                        barchartref2,
                                        `Daily data variation`
                                      )
                                    }
                                    id={1}
                                  />
                                </div>
                              </div>
                              <div ref={barchartref2 || ""}>
                                <DynamicBarChart
                                  data={bargraph}
                                  xAxisCategories={xAxisCategories}
                                  yAxisName="Energy Consumption"
                                  xAxisName="Time"
                                />
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
      {value === 1 && (
        <div>
          <div className="border rounded-md border-gray-400 grid grid-rows">
            <div className="grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-6 xl:grid-cols-4 2xl:gap-7.5 mb-2 mt-2 p-2 my-cardTwo">
              {customTagData?.["v"]?.sensorStatus ? (
                <CardThree
                  title={customTagData?.["v"]?.sensorAlias || "Voltage"}
                  amount={`${
                    dashboardSts.voltage ? dashboardSts.voltage : "N/A"
                  } ${customTagData?.["v"]?.sensorUnit || "kVah"}`}
                />
              ) : !customTagData?.["v"] ? (
                <CardThree
                  title="Voltage"
                  amount={`${
                    dashboardSts.voltage ? dashboardSts.voltage : "N/A"
                  } kVah`}
                />
              ) : null}
              {customTagData?.["a"]?.sensorStatus ? (
                <CardThree
                  title={customTagData?.["a"]?.sensorAlias || "Current"}
                  amount={`${
                    dashboardSts.current ? dashboardSts.current : "N/A"
                  } ${customTagData?.["a"]?.sensorUnit || "V"}`}
                />
              ) : !customTagData?.["a"] ? (
                <CardThree
                  title="Current"
                  amount={`${
                    dashboardSts.current ? dashboardSts.current : "N/A"
                  } V`}
                />
              ) : null}

              {customTagData?.["kW"]?.sensorStatus ? (
                <CardThree
                  title={customTagData?.["kW"]?.sensorAlias || "Active Power"}
                  amount={`${
                    dashboardSts.actpower ? dashboardSts.actpower : "N/A"
                  } ${customTagData?.["kW"]?.sensorUnit || "A"}`}
                />
              ) : !customTagData?.["kW"] ? (
                <CardThree
                  title="Active Power"
                  amount={`${
                    dashboardSts.actpower ? dashboardSts.actpower : "N/A"
                  } A`}
                />
              ) : null}

              {customTagData?.["kvar"]?.sensorStatus ? (
                <CardThree
                  title={
                    customTagData?.["kvar"]?.sensorAlias || "Reactive Power"
                  }
                  amount={`${
                    dashboardSts.reactpower ? dashboardSts.reactpower : "N/A"
                  } ${customTagData?.["kvar"]?.sensorUnit || "kW"}`}
                />
              ) : !customTagData?.["kvar"] ? (
                <CardThree
                  title="Reactive Power"
                  amount={`${
                    dashboardSts.reactpower ? dashboardSts.reactpower : "N/A"
                  } kW`}
                />
              ) : null}
            </div>
            <div className="grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-6 xl:grid-cols-4 2xl:gap-7.5 mb-2 mt-2 p-2 my-cardTwo">
              {customTagData?.["kva"]?.sensorStatus ? (
                <CardThree
                  title={
                    customTagData?.["kva"]?.sensorAlias || "Apparent Power"
                  }
                  amount={`${
                    dashboardSts.apparpower ? dashboardSts.apparpower : "N/A"
                  } ${customTagData?.["kva"]?.sensorUnit || "kVar"}`}
                />
              ) : !customTagData?.["kva"] ? (
                <CardThree
                  title="Apparent Power"
                  amount={`${
                    dashboardSts.apparpower ? dashboardSts.apparpower : "N/A"
                  } kVar`}
                />
              ) : null}

              {customTagData?.["hz"]?.sensorStatus ? (
                <CardThree
                  title={customTagData?.["hz"]?.sensorAlias || "Frequency"}
                  amount={`${
                    dashboardSts.frequency ? dashboardSts.frequency : "N/A"
                  } ${customTagData?.["hz"]?.sensorUnit || "kVa"}`}
                />
              ) : !customTagData?.["hz"] ? (
                <CardThree
                  title="Frequency"
                  amount={`${
                    dashboardSts.frequency ? dashboardSts.frequency : "N/A"
                  } kVa`}
                />
              ) : null}

              {customTagData?.["pf"]?.sensorStatus ? (
                <CardThree
                  title={customTagData?.["pf"]?.sensorAlias || "Power Factor"}
                  amount={`${
                    dashboardSts.powfactor ? dashboardSts.powfactor : "N/A"
                  } ${customTagData?.["pf"]?.sensorUnit || "-"}`}
                />
              ) : !customTagData?.["pf"] ? (
                <CardThree
                  title="Power Factor"
                  amount={`${
                    dashboardSts.powfactor ? dashboardSts.powfactor : "N/A"
                  } -`}
                />
              ) : null}

              {customTagData?.["ex1"]?.sensorStatus ? (
                <CardThree
                  title={customTagData?.["ex1"]?.sensorAlias || "Extra 1"}
                  amount={`${
                    dashboardSts.extra1 ? dashboardSts.extra1 : "N/A"
                  } ${customTagData?.["ex1"]?.sensorUnit || "0"}`}
                />
              ) : !customTagData?.["ex1"] ? (
                <CardThree
                  title="Extra 1"
                  amount={`${
                    dashboardSts.extra1 ? dashboardSts.extra1 : "N/A"
                  } 0`}
                />
              ) : null}
            </div>
            <div className="grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-6 xl:grid-cols-4 2xl:gap-7.5 mb-2 mt-2 p-2 my-cardTwo">
              {customTagData?.["ex2"]?.sensorStatus ? (
                <CardThree
                  title={customTagData?.["ex2"]?.sensorAlias || "Extra 2"}
                  amount={`${
                    dashboardSts.extra2 ? dashboardSts.extra2 : "N/A"
                  } ${customTagData?.["ex2"]?.sensorUnit || "0"}`}
                />
              ) : !customTagData?.["ex2"] ? (
                <CardThree
                  title="Extra 2"
                  amount={`${
                    dashboardSts.extra2 ? dashboardSts.extra2 : "N/A"
                  } 0`}
                />
              ) : null}

              {customTagData?.["ex3"]?.sensorStatus ? (
                <CardThree
                  title={customTagData?.["ex3"]?.sensorAlias || "Extra 3"}
                  amount={`${
                    dashboardSts.extra3 ? dashboardSts.extra3 : "N/A"
                  } ${customTagData?.["ex3"]?.sensorUnit || "0"}`}
                />
              ) : !customTagData?.["ex3"] ? (
                <CardThree
                  title="Extra 3"
                  amount={`${
                    dashboardSts.extra3 ? dashboardSts.extra3 : "N/A"
                  } 0`}
                />
              ) : null}

              {customTagData?.["ex4"]?.sensorStatus ? (
                <CardThree
                  title={customTagData?.["ex4"]?.sensorAlias || "Extra 4"}
                  amount={`${
                    dashboardSts.extra4 ? dashboardSts.extra4 : "N/A"
                  } ${customTagData?.["ex4"]?.sensorUnit || "0"}`}
                />
              ) : !customTagData?.["ex4"] ? (
                <CardThree
                  title="Extra 4"
                  amount={`${
                    dashboardSts.extra4 ? dashboardSts.extra4 : "N/A"
                  } 0`}
                />
              ) : null}

              {customTagData?.["ex5"]?.sensorStatus ? (
                <CardThree
                  title={customTagData?.["ex5"]?.sensorAlias || "Extra 5"}
                  amount={`${
                    dashboardSts.extra5 ? dashboardSts.extra5 : "N/A"
                  } ${customTagData?.["ex5"]?.sensorUnit || "0"}`}
                />
              ) : !customTagData?.["ex5"] ? (
                <CardThree
                  title="Extra 5"
                  amount={`${
                    dashboardSts.extra5 ? dashboardSts.extra5 : "N/A"
                  } 0`}
                />
              ) : null}
            </div>
          </div>
          <div className="pb-[10px] border border-gray-400 rounded-md p-2">
            <div className="pb-[20px]">
              <div className="relative mt-2 mb-5">
                <p>
                  Current data variation with time(between{" "}
                  {apiData.length > 0
                    ? apiData[0].timestamp +
                      " to " +
                      apiData[apiData.length - 1].timestamp
                    : " "}
                  )
                </p>
                <div className="absolute top-0 right-0">
                  <LongMenu
                    options={["Download as CSV"]}
                    onOptionClick={(exportOption) =>
                      handleExportBarChart(
                        exportOption,
                        instLineChartData,
                        chartRef4
                      )
                    }
                    id={1}
                  />
                </div>
                <div ref={chartRef4}>
                  <DynamicLineChart
                    data={lineChartOfInstData}
                    stroke={true}
                    xAxisName="Time"
                    yAxisName="Data"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DashboardEnergySinglePhase;
