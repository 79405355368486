import { Button, Toolbar, Typography } from "@mui/material";
import React from "react";
import AddIcon from "@mui/icons-material/Add";
import { useAlertState } from "../../app-context/alert-context/AlertState";
import { http } from "../../helper/http";
// import AddAlertForm from "./AddAlertForm";
import ProtectedComponent from "../../utils/protected-route/ProtectedComponent";
import AddSchedulingForm from "./AddSchedulingForm";





const baseUrl = process.env.REACT_APP_BASE_URL;
export function EnhancedTableToolbar() {
  
  const [formModalIsOpen, setFormModalIsOpen] = React.useState(false);
  const { dispatch } = useAlertState();
  const toggleForm = async () => {
    setFormModalIsOpen((prevVal) => !prevVal);
    // const resp = (await http(baseUrl + "/customer?allcustomer=all") as any);
    // setAllCustomer(dispatch, resp.customers)
  };

  // const handleFilterChange = async (
  //   event: React.ChangeEvent<HTMLInputElement>
  // ) => {
  //   const value = event.target.value;
  //   try {
  //     let resp;

  //     if (value === "") {
  //       resp = (await http(
  //         baseUrl + "/user?page=1"
  //       )) as UserApiResponse;
  //     } else {
  //       resp = (await http(
  //         baseUrl + "/user?searchUsers=" + value
  //       )) as UserApiResponse;
  //     }

  //     getUserPageClick(userDispatch, resp.users);
  //   } catch (error) {
  //     console.log("no device found");
  //   }
  // };

  
  // function handleFilterSubmit() {
  //   throw new Error("Function not implemented.");
  // }

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
      }}
    >
      <Typography
        sx={{
          flex: "1 1 100%",
          fontWeight: 700,
          fontSize: [
            "text-sm",
            "text-md",
            "text-lg",
            "text-xl",
            "text-2xl",
            "text-3xl",
          ],
        }}
        variant="h6"
        id="tableTitle"
        component="div"
        className="shadow-gray-500 text-black font-bold"
      >
        Scheduling
      </Typography>
      <div className="mr-2">
        <Button
          sx={{
            backgroundColor: "teal",
            color: "white",
            height: 32,
            fontWeight: "bold",
            fontSize: "1rem",
            transition: "background-color 200ms",
            "&:hover": {
              backgroundColor: "teal",
            },
          }}
          variant="contained"
          onClick={toggleForm}
        >
          <AddIcon />
          Add
        </Button>
        {/* <AddCustomerForm toggleModal={true} /> */}
        {formModalIsOpen && (
          <AddSchedulingForm
            uniqueId="ADD"
            data={[] as any}
            toggleForm={formModalIsOpen}
            toggleFormFun={toggleForm}
          />
        )}
      </div>
      <div className="flex flex-col sm:flex-row items-center space-y-2 sm:space-y-0 sm:space-x-2">
        <input
          type="text"
          placeholder="Search"
          onChange={()=>{}}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              // handleFilterSubmit();
            }
          }}
          className="border border-black rounded p-1 w-full sm:w-auto"
        />
      </div>
    </Toolbar>
  );
}
