import { TableCell, TableHead, TableRow } from "@mui/material";

function EnhancedTableHead() {
    const tableCellsData = [
      { label: "Device Name", id: "unithead" },
      { label: "Device Alias", id: "deviceAlias" },
      { label: "Status", id: "action" },

    ];
  
    return (
      <TableHead className="bg-gray-800 sticky top-0 z-10">
        <TableRow className="border-b">
          {tableCellsData.map((cellData) => (
            <TableCell
              key={cellData.id}
              padding="normal"
              className="p-2"
              sx={{ color: "white", fontWeight: 400, textAlign: "start" }}
            >
              {cellData.label}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }
  
export default EnhancedTableHead