import React, { useEffect, useRef, useState } from "react";
import CardTwo from "../../../components/dashBoardComponents/CardTwo";
import { IconButton, Paper, Tab, Tabs } from "@mui/material";
import LineChart from "../../../components/apex-chart/LineChart";
import MobileTowerIcon from "../../../components/apex-chart/HeatMapChart";
import {
  formatTimestamp,
  generateCSV1,
  reportDataWithGraph,
} from "../../../common-function/CommonFunction";
import { http } from "../../../helper/http";
import power from "../../../images/power_1184147 1.png";
import clock from "../../../images/clock.png";
import calender from "../../../images/calendar.png";
import solarPower from "../../../images/solar_power.png";
import flash from "../../../images/flash.png";
import vector from "../../../images/Vector.png";
import percentage from "../../../images/percentage.png";
import HotTemp from "../../../images/hot-temperature_12204259 1.png";
import RoomTemp from "../../../images/room-temperature 1.png";
import voltIcon from "../../../images/voltage_12062774 1.png";
import CustomBarChart from "../../../components/dashBoardComponents/BarChart";
import SingleLineBarChart from "../../../components/apex-chart/SingleLineBarChart";
import LongMenu from "../../../utils/longMenu/LongMenu";
import { Button, ButtonGroup } from "@material-ui/core";

const baseUrl = process.env.REACT_APP_BASE_URL;

const DashboardSolar1 = ({ selectedDevice, devices }: any) => {

  
  //material tabs
  const [value, setValue] = React.useState(0);
  const [valueInst, setInst] = React.useState(0);
  const [innerValueInst, setInnerValueInst] = React.useState(0);
  const [innerValueInst1, setInnerValueInst1] = React.useState(0);

  //   const [value, setValue] = useState(0);
  const [apiData, setApiData] = useState<any>([]);
  const [dashboardSts, setDashboardSts] = useState<any>([]);
  const chartRef = useRef<HTMLDivElement>(null);
  const chartRef1 = useRef<HTMLDivElement>(null);
  const chartRef2 = useRef<HTMLDivElement>(null);
  const chartRef3 = useRef<HTMLDivElement>(null);
  const chartRef4 = useRef<HTMLDivElement>(null);
  const chartRef5 = useRef<HTMLDivElement>(null);
  const chartRef6 = useRef<HTMLDivElement>(null);

  const currentDate = new Date();

  const fetchData = async () => {
    let response: any = null;
    try {
      response = (await http(
        baseUrl + `/device-data-log?deviceId=${selectedDevice}?last24=last24`
      )) as any;

      const _data = response.map((d: any, i: any) => {
        return {
          timestamp: formatTimestamp(d.timeStamp),
          invertStats: d["inver_status"],
          todayEnrgy: d["today_energy"],
          totalEnrgy: d["total_energy"],
          actPowr: d["act_po"],
          powrFact: d["pf"],
          efficiency: d["effic"],
          prRatio: d["pr_ratio"],
          cuf: d["cuf"],
          batLevel: d["bat_level"],
          frequency: d["frequency"],

          mpptPowr: d["mppt_po"],
          mpptVolt: d["mppt_vtg"],
          mpptCurr: d["mppt_crt"],
          acVolt: d["ac_vtg"],
          acCurr: d["ac_crt"],
          strVolt: d["str_vtg"],
          strCurr: d["str_crt"],
          itAmb: d["inver_temp_amb"],
          itInter: d["inver_temp_intr"],
          rssi: d["rssi"],
        };
      }) .filter((d: any) => {
        return !Object.values(d).some(
          (val: any) =>
            typeof val === "string" && val.toLowerCase() === "error"
        );
      });
      setApiData(_data);
      setDashboardSts(_data.length ? _data[0] : "");
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const last1HourData = apiData.filter((entry: any) => {
    const entryDate = new Date(entry.timestamp);
    const timeDifference = currentDate.getTime() - entryDate.getTime();
    const hoursDifference = timeDifference / (60 * 60 * 1000); // Convert milliseconds to hours
    return hoursDifference <= 1 && hoursDifference >= 0;
  });

  const last24HoursData = apiData.filter((entry: any) => {
    const entryDate = new Date(entry.timestamp);
    const timeDifference = currentDate.getTime() - entryDate.getTime();
    return timeDifference <= 24 * 60 * 60 * 1000;
  });

  const power_data_of_last24Hour =
    last24HoursData.length > 0
      ? last24HoursData.map(({ timestamp, actPowr }: any) => ({
          timestamp,
          value: actPowr === "" ? 0 : actPowr,
        }))
      : [];
  const frequency_data_of_last24Hour =
    last24HoursData.length > 0
      ? last24HoursData.map(({ timestamp, frequency }: any) => ({
          timestamp,
          value: frequency === "" ? 0 : frequency,
        }))
      : [];
  const powerFactor_data_of_last24Hour =
    last24HoursData.length > 0
      ? last24HoursData.map(({ timestamp, powrFact }: any) => ({
          timestamp,
          value: powrFact === "" ? 0 : powrFact,
        }))
      : [];
  const prRation_data_of_last24Hour =
    last24HoursData.length > 0
      ? last24HoursData.map(({ timestamp, prRatio }: any) => ({
          timestamp,
          value: prRatio === "" ? 0 : prRatio,
        }))
      : [];

  const analysisPdfData =
    last24HoursData.length > 0
      ? last24HoursData.map(
          ({ timestamp, actPowr, frequency, powrFact, prRatio }: any) => ({
            timestamp,
            "Active Power": actPowr,
            Frequency: frequency,
            "Power Factor": powrFact,
            "PR Ratio": prRatio,
          })
        )
      : [];
  const inverterTempPdfData =
    last24HoursData.length > 0
      ? last24HoursData.map(({ timestamp, itAmb, itInter }: any) => ({
          timestamp,
          "Ambient Temp": itAmb,
          "Internal Temp": itInter,
        }))
      : [];

  const lineChartOfAnalysis: any = [
    {
      name: "Power",
      color: "#008000",
      data: power_data_of_last24Hour.reverse(),
      postfix: " kW",
    },
    {
      name: "Frequency",
      color: "#FF0000",
      data: frequency_data_of_last24Hour.reverse(),
      postfix: " Hz",
    },
    {
      name: "Power Factor",
      color: "#FFFF00",
      data: powerFactor_data_of_last24Hour.reverse(),
      postfix: " ",
    },
    {
      name: "PR Ratio",
      color: "#0000FF",
      data: prRation_data_of_last24Hour.reverse(),
      postfix: " %",
    },
  ];

  const energy_data_of_last1Hour =
    last1HourData.length > 0
      ? last1HourData.map(({ timestamp, todayEnrgy }: any) => ({
          timestamp,
          value: todayEnrgy,
        }))
      : [];

  const energy_data_of_last24Hour =
    last24HoursData.length > 0
      ? last24HoursData.map(({ timestamp, todayEnrgy }: any) => ({
          timestamp,
          value: todayEnrgy,
        }))
      : [];

  const mppt_power_data_of_last24Hour =
    last24HoursData.length > 0
      ? last24HoursData.map(({ timestamp, mpptPowr }: any) => ({
          timestamp,
          value: mpptPowr === "" ? 0 : mpptPowr,
        }))
      : [];
  const mppt_voltage_data_of_last24Hour =
    last24HoursData.length > 0
      ? last24HoursData.map(({ timestamp, mpptVolt }: any) => ({
          timestamp,
          value: mpptVolt === "" ? 0 : mpptVolt,
        }))
      : [];
  const mppt_current_data_of_last24Hour =
    last24HoursData.length > 0
      ? last24HoursData.map(({ timestamp, mpptCurr }: any) => ({
          timestamp,
          value: mpptCurr === "" ? 0 : mpptCurr,
        }))
      : [];
  const ac_voltage_data_of_last24Hour =
    last24HoursData.length > 0
      ? last24HoursData.map(({ timestamp, acVolt }: any) => ({
          timestamp,
          value: acVolt === "" ? 0 : acVolt,
        }))
      : [];
  const ac_current_data_of_last24Hour =
    last24HoursData.length > 0
      ? last24HoursData.map(({ timestamp, acCurr }: any) => ({
          timestamp,
          value: acCurr === "" ? 0 : acCurr,
        }))
      : [];
  const string_voltage_data_of_last24Hour =
    last24HoursData.length > 0
      ? last24HoursData.map(({ timestamp, strVolt }: any) => ({
          timestamp,
          value: strVolt === "" ? 0 : strVolt,
        }))
      : [];
  const string_current_data_of_last24Hour =
    last24HoursData.length > 0
      ? last24HoursData.map(({ timestamp, strCurr }: any) => ({
          timestamp,
          value: strCurr === "" ? 0 : strCurr,
        }))
      : [];
  const ambient_temp_data_of_last24Hour =
    last24HoursData.length > 0
      ? last24HoursData.map(({ timestamp, itAmb }: any) => ({
          timestamp,
          value: itAmb === "" ? 0 : itAmb,
        }))
      : [];
  const internal_temp_data_of_last24Hour =
    last24HoursData.length > 0
      ? last24HoursData.map(({ timestamp, itInter }: any) => ({
          timestamp,
          value: itInter === "" ? 0 : itInter,
        }))
      : [];

  const lineChartDataOfMPPTPower: any = [
    {
      name: "Power",
      color: "#4444FF",
      data: mppt_power_data_of_last24Hour.reverse(),
      postfix: " kW",
    },
  ];

  const lineChartDataOfMPPTVoltage: any = [
    {
      name: "Voltage",
      color: "#4444FF",
      data: mppt_voltage_data_of_last24Hour.reverse(),
      postfix: " V",
    },
  ];

  const lineChartDataOfMPPTCurrent: any = [
    {
      name: "Current",
      color: "#4444FF",
      data: mppt_current_data_of_last24Hour.reverse(),
      postfix: " A",
    },
  ];

  const lineChartDataOfACVoltage: any = [
    {
      name: "Voltage",
      color: "#4444FF",
      data: ac_voltage_data_of_last24Hour.reverse(),
      postfix: " V",
    },
  ];

  const lineChartDataOfACCurrent: any = [
    {
      name: "Current",
      color: "#4444FF",
      data: ac_current_data_of_last24Hour.reverse(),
      postfix: " A",
    },
  ];

  const lineChartDataOfSTRINGVoltage: any = [
    {
      name: "Voltage",
      color: "#4444FF",
      data: string_voltage_data_of_last24Hour.reverse(),
      posfix: " V",
    },
  ];

  const lineChartDataOfSTRINGCurrent: any = [
    {
      name: "Current",
      color: "#4444FF",
      data: string_current_data_of_last24Hour.reverse(),
      postfix: " A",
    },
  ];

  const lineChartDataOfInverterTemp: any = [
    {
      name: "Ambient Temperature",
      color: "#4444FF",
      data: ambient_temp_data_of_last24Hour.reverse(),
      postfix: " °C",
    },
    {
      name: "Internal Temperature",
      color: "#FF0000",
      data: internal_temp_data_of_last24Hour.reverse(),
      postfix: " °C",
    },
  ];

  const getSTRINGDataToExport = (innerValueInst: number) => {
    if (innerValueInst === 0) {
      return string_voltage_data_of_last24Hour.map(
        ({ timestamp, value }: any) => {
          return {
            TIMESTAMP: timestamp,
            Voltage: value,
          };
        }
      );
    } else if (innerValueInst === 1) {
      return string_current_data_of_last24Hour.map(
        ({ timestamp, value }: any) => {
          return {
            TIMESTAMP: timestamp,
            Current: value,
          };
        }
      );
    }
  };

  const getACDataToExport = (innerValueInst: number) => {
    if (innerValueInst === 0) {
      return ac_voltage_data_of_last24Hour.map(({ timestamp, value }: any) => {
        return {
          TIMESTAMP: timestamp,
          Voltage: value,
        };
      });
    } else if (innerValueInst === 1) {
      return ac_current_data_of_last24Hour.map(({ timestamp, value }: any) => {
        return {
          TIMESTAMP: timestamp,
          Current: value,
        };
      });
    }
  };

  const getMPPTDataToExport = (innerValueInst: number) => {
    if (innerValueInst === 0) {
      return mppt_power_data_of_last24Hour.map(({ timestamp, value }: any) => {
        return {
          TIMESTAMP: timestamp,
          Power: value,
        };
      });
    } else if (innerValueInst === 1) {
      return mppt_voltage_data_of_last24Hour.map(
        ({ timestamp, value }: any) => {
          return {
            TIMESTAMP: timestamp,
            Voltage: value,
          };
        }
      );
    } else if (innerValueInst === 2) {
      return mppt_current_data_of_last24Hour.map(
        ({ timestamp, value }: any) => {
          return {
            TIMESTAMP: timestamp,
            Current: value,
          };
        }
      );
    }
  };

  const handleExportBarChart = async (
    exportOption: string,
    dataValue: any,
    chart: any
  ) => {
    switch (exportOption) {
      case "Download as CSV":
        generateCSV1(dataValue);
        break;
      case "Download as PDF":
        if (dataValue && chart) {
          reportDataWithGraph(
            chart,
            dataValue,
            devices?.deviceName,
            devices?.Customer?.name,
            "Energy Summary",
            "Realtime - Last 24 Hour | IST"
          );
        } else {
        }
        return;
      default:
        return;
    }
  };

  useEffect(() => {
    fetchData();
    const intervalId = setInterval(fetchData, 120000);
    return () => clearInterval(intervalId);
  }, [selectedDevice]);

  return (
    <div>
      <div className="p-2 mb-4">
        <section style={{ display: "flex", alignItems: "center" }}>
          <MobileTowerIcon levels={dashboardSts?.rssi || 0} time={dashboardSts?.timestamp} />
          <p className="ml-2">
            {"    Last Data Received at  " + dashboardSts?.timestamp ||
              "  YYYY-MM-DD HH:MM:SS"}
          </p>
          {/* <Paper square style={{ marginLeft: "auto" }}>
            <Tabs
              value={value}
              indicatorColor="primary"
              textColor="primary"
              onChange={(event, newValue) => setValue(newValue)}
            >
              <Tab label="Analysis" />
              <Tab label="Instantaneous" />
            </Tabs>
          </Paper> */}
          <ButtonGroup
            variant="outlined"
            color="default"
            aria-label="Basic button group"
            style={{ marginLeft: "auto" }}
          >
            <Button
            variant="outlined"
            style={value === 0 ? { backgroundColor: "#dadada" } : {}}
              onClick={() => setValue(0)}
            >
              Analysis
            </Button>
            <Button
            variant="outlined"
            style={value === 1 ? { backgroundColor: "#dadada" } : {}}
              onClick={() => setValue(1)}
            >
              Instantaneous
            </Button>
          </ButtonGroup>
        </section>
      </div>

      {value === 0 && (
        <div>
          <div className="border border-gray-400 p-[10px]  grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-6 xl:grid-cols-4 2xl:gap-7.5 rounded-lg">
            <CardTwo
              title="Inverter Status"
              amount={`${dashboardSts?.invertStats || 0} `}
            />
            <CardTwo
              title="Today's Energy"
              amount={`${dashboardSts?.todayEnrgy || 0} kWh`}
              icon={clock}
            />
            <CardTwo
              title="Total Energy"
              amount={`${dashboardSts?.totalEnrgy || 0} kWh`}
              icon={calender}
            />
            <CardTwo
              title="Active Power"
              amount={`${dashboardSts?.actPowr || 0 + ` `} kW`}
              icon={solarPower}
            />
            <CardTwo
              title="Power Factor"
              amount={`${dashboardSts?.powrFact || 0} `}
              icon={flash}
            />
            <CardTwo
              title="Frequency"
              amount={`${dashboardSts?.frequency || 0} Hz `}
              icon={vector}
            />
            <CardTwo
              title="Efficiency"
              amount={`${dashboardSts?.efficiency || 0} %`}
              icon={percentage}
            />
            <CardTwo
              title="PR Ratio"
              amount={`${dashboardSts?.prRatio || 0} %`}
              icon={percentage}
            />
          </div>
          <div className=" mt-[5px] border border-gray-400  rounded-lg">
            <div className="relative">
              <div className="m-2">
                <p> Data variation with time Last 24 Hours</p>
                <div className="absolute top-0 right-0">
                  <IconButton>
                    <LongMenu
                      options={["Download as CSV", "Download as PDF"]}
                      onOptionClick={(exportOption) =>
                        handleExportBarChart(
                          exportOption,
                          analysisPdfData,
                          chartRef
                        )
                      }
                      id={1}
                    />
                  </IconButton>
                </div>
              </div>
              <div ref={chartRef || ""}>
                <LineChart
                  yAxisName="Data"
                  xAxisName="Time"
                  data={lineChartOfAnalysis.length ? lineChartOfAnalysis : []}
                  stroke={true}
                />
              </div>
            </div>
          </div>
          <div className="flex justify-between mt-2">
            <div className="border border-gray-400 w-1/2 p-2 rounded-lg">
              <div className="relative">
                <p>Hourly Energy data summary</p>
                <p>
                  (between{" "}
                  {energy_data_of_last1Hour.length > 0
                    ? energy_data_of_last1Hour[
                        energy_data_of_last1Hour.length - 1
                      ].timestamp +
                      " to " +
                      energy_data_of_last1Hour[0].timestamp
                    : " "}
                  )
                </p>
                <div className="absolute top-0 right-0">
                  <IconButton>
                    <LongMenu
                      options={["Download as CSV", "Download as PDF"]}
                      onOptionClick={(exportOption) =>
                        handleExportBarChart(
                          exportOption,
                          energy_data_of_last1Hour,
                          chartRef1
                        )
                      }
                      id={1}
                    />
                  </IconButton>
                </div>
              </div>
              <div ref={chartRef1 || ""}>
                <SingleLineBarChart
                  data={energy_data_of_last1Hour.reverse()}
                  title="Time"
                  postfix="kWh"
                />
              </div>
            </div>
            <div className="w-4"></div>
            <div className="border border-gray-400 w-1/2 p-2 rounded-lg">
              <div className="relative">
                <p>Daily Energy data summary</p>
                <p>
                  (between{" "}
                  {energy_data_of_last24Hour.length > 0
                    ? energy_data_of_last24Hour[
                        energy_data_of_last24Hour.length - 1
                      ].timestamp +
                      " to " +
                      energy_data_of_last24Hour[0].timestamp
                    : " "}
                  )
                </p>
                <div className="absolute top-0 right-0">
                  <IconButton>
                    <LongMenu
                      options={["Download as CSV", "Download as PDF"]}
                      onOptionClick={(exportOption) =>
                        handleExportBarChart(
                          exportOption,
                          energy_data_of_last24Hour,
                          chartRef2
                        )
                      }
                      id={1}
                    />
                  </IconButton>
                </div>
              </div>
              <div ref={chartRef2 || ""}>
                <SingleLineBarChart
                  data={energy_data_of_last24Hour.reverse()}
                  title="Time"
                  postfix="kWh"
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {value === 1 && (
        <div>
          <div className="border border-gray-400 p-2 mb-4 mt-5 rounded-lg">
            {/* <Paper square>
              <Tabs
                value={valueInst}
                indicatorColor="primary"
                textColor="primary"
                onChange={(event, newValue) => setInst(newValue)}
              >
                <Tab label="MPPT" />
                <Tab label="AC" />
                <Tab label="String" />
                <Tab label="Inverter Temperature" />
              </Tabs>
            </Paper> */}
            <ButtonGroup
              variant="outlined"
              color="default"
              aria-label="Basic button group"
            >
              <Button
              variant="outlined"
              style={valueInst === 0 ? { backgroundColor: "#dadada" } : {}}
                onClick={() => setInst(0)}
              >
                MPPT
              </Button>
              <Button
              variant="outlined"
              style={valueInst === 1 ? { backgroundColor: "#dadada" } : {}}
                onClick={() => setInst(1)}
              >
                AC
              </Button>
              <Button
              variant="outlined"
              style={valueInst === 2 ? { backgroundColor: "#dadada" } : {}}
                onClick={() => setInst(2)}
              >
                String
              </Button>
              <Button
              variant="outlined"
              style={valueInst === 3 ? { backgroundColor: "#dadada" } : {}}
                onClick={() => setInst(3)}
              >
                Inverter Temperature
              </Button>
            </ButtonGroup>
            {value === 1 && valueInst === 0 && (
              //this is for mppt and power
              <>
                <div className="border border-gray-400 grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-6 xl:grid-cols-4 2xl:gap-7.5 mb-2 mt-2 p-2 my-cardTwo rounded-lg">
                  <CardTwo
                    title="MPPT Power"
                    amount={`${dashboardSts?.mpptPowr || 0} kW`}
                    icon={power}
                  />
                  <CardTwo
                    title="MPPT Voltage"
                    amount={`${dashboardSts?.mpptVolt || 0}  V`}
                    icon={voltIcon}
                  />
                  <CardTwo
                    title="MPPT Current"
                    amount={`${dashboardSts?.mpptCurr || 0}  A`}
                    icon={flash}
                  />
                </div>
                <div className="  border border-gray-400 p-[20px] rounded-lg">
                  <div className="relative">
                    <div className="mt-2 mb-5">
                      <p>Power data variation with time </p>
                      <p>
                        (between{" "}
                        {mppt_power_data_of_last24Hour.length > 0
                          ? mppt_power_data_of_last24Hour[
                            mppt_power_data_of_last24Hour.length - 1
                            ].timestamp +
                            " to " +
                            mppt_power_data_of_last24Hour[0].timestamp
                          : " "}
                        )
                      </p>
                      <div className="absolute top-0 right-0">
                        <IconButton>
                          <LongMenu
                            options={["Download as CSV", "Download as PDF"]}
                            onOptionClick={(exportOption) =>
                              handleExportBarChart(
                                exportOption,
                                getMPPTDataToExport(innerValueInst),
                                chartRef3
                              )
                            }
                            id={1}
                          />
                        </IconButton>
                      </div>
                    </div>
                    {/* <Paper square style={{ marginRight: "550px" }}>
                      <Tabs
                        value={innerValueInst}
                        indicatorColor="primary"
                        textColor="primary"
                        onChange={(event, newValue) =>
                          setInnerValueInst(newValue)
                        }
                      >
                        <Tab label="Power" />
                        <Tab label="Voltage" />
                        <Tab label="Current" />
                      </Tabs>
                    </Paper> */}
                    <ButtonGroup
                      variant="outlined"
                      color="default"
                      aria-label="Basic button group"
                    >
                      <Button
                      variant="outlined"
                      style={innerValueInst === 0 ? { backgroundColor: "#dadada" } : {}}
                        onClick={() => setInnerValueInst(0)}
                      >
                        Power
                      </Button>
                      <Button
                      variant="outlined"
                      style={innerValueInst === 1 ? { backgroundColor: "#dadada" } : {}}
                        onClick={() => setInnerValueInst(1)}
                      >
                        Voltage
                      </Button>
                      <Button
                        variant="outlined"
                        style={innerValueInst === 2 ? { backgroundColor: "#dadada" } : {}}
                        onClick={() => setInnerValueInst(2)}
                      >
                        Current
                      </Button>
                    </ButtonGroup>

                    <div ref={chartRef3 || ""}>
                      {valueInst === 0 && innerValueInst === 0 && (
                        <>
                          {/* Bar chart of Power */}
                          <div>
                            <LineChart
                              yAxisName="Power (kW)"
                              xAxisName="Time"
                              data={lineChartDataOfMPPTPower}
                              stroke={true}
                            />
                          </div>
                        </>
                      )}
                      {valueInst === 0 && innerValueInst === 1 && (
                        <>
                          {/* Bar chart of Voltage */}
                          <div>
                            <LineChart
                              yAxisName="Voltage (V)"
                              xAxisName="Time"
                              data={lineChartDataOfMPPTVoltage}
                              stroke={true}
                            />
                          </div>
                        </>
                      )}
                      {valueInst === 0 && innerValueInst === 2 && (
                        <>
                          {/* Bar chart of Current */}
                          <div>
                            <LineChart
                              yAxisName="Current (A)"
                              xAxisName="Time"
                              data={lineChartDataOfMPPTCurrent}
                              stroke={true}
                            />
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </>
            )}
            {value === 1 && valueInst === 1 && (
              <>
                <div className="border border-gray-400 grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-6 xl:grid-cols-4 2xl:gap-7.5 mb-2 mt-2 p-2 my-cardTwo rounded-lg">
                  <CardTwo
                    title="AC Voltage"
                    amount={`${dashboardSts?.acVolt || 0} V`}
                    icon={voltIcon}
                  />
                  <CardTwo
                    title="AC Current"
                    amount={`${dashboardSts?.acCurr || 0}  A`}
                    icon={flash}
                  />
                </div>
                <div className="  border border-gray-400 p-[20px] rounded-lg">
                  <div className="relative">
                    <div className="mt-2 mb-5">
                      <p>Voltage data variation with time </p>
                      <p>
                        (between{" "}
                        {ac_voltage_data_of_last24Hour.length > 0
                          ? ac_voltage_data_of_last24Hour[
                            ac_voltage_data_of_last24Hour.length - 1
                            ].timestamp +
                            " to " +
                            ac_voltage_data_of_last24Hour[0].timestamp
                          : " "}
                        )
                      </p>
                      <div className="absolute top-0 right-0">
                        <IconButton>
                          <LongMenu
                            options={["Download as CSV", "Download as PDF"]}
                            onOptionClick={(exportOption) =>
                              handleExportBarChart(
                                exportOption,
                                getACDataToExport(innerValueInst1),
                                chartRef4
                              )
                            }
                            id={1}
                          />
                        </IconButton>
                      </div>
                    </div>
                    {/* <Paper square style={{ marginRight: "550px" }}>
                      <Tabs
                        value={innerValueInst1}
                        indicatorColor="primary"
                        textColor="primary"
                        onChange={(event, newValue) =>
                          setInnerValueInst1(newValue)
                        }
                      >
                        <Tab label="Voltage" />
                        <Tab label="Current" />
                      </Tabs>
                    </Paper> */}
                    <ButtonGroup
                      variant="outlined"
                      color="default"
                      aria-label="Basic button group"
                    >
                      <Button
                      variant="outlined"
                      style={innerValueInst1 === 0 ? { backgroundColor: "#dadada" } : {}}
                        onClick={() => setInnerValueInst1(0)}
                      >
                        Voltage
                      </Button>
                      <Button
                        variant="outlined"
                        style={innerValueInst1 === 1 ? { backgroundColor: "#dadada" } : {}}
                        onClick={() => setInnerValueInst1(1)}
                      >
                        Current
                      </Button>
                    </ButtonGroup>
                    <div ref={chartRef4 || ""}>
                      {valueInst === 1 && innerValueInst1 === 0 && (
                        <>
                          <div>
                            <LineChart
                              yAxisName="Voltage (V)"
                              xAxisName="Time"
                              data={lineChartDataOfACVoltage}
                              stroke={true}
                            />
                          </div>
                        </>
                      )}
                      {valueInst === 1 && innerValueInst1 === 1 && (
                        <>
                          <div>
                            <LineChart
                              yAxisName="Current (A)"
                              xAxisName="Time"
                              data={lineChartDataOfACCurrent}
                              stroke={true}
                            />
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </>
            )}
            {value === 1 && valueInst === 2 && (
              <>
                <div className="border border-gray-400 grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-6 xl:grid-cols-4 2xl:gap-7.5 mb-2 mt-2 p-2 my-cardTwo rounded-lg">
                  <CardTwo
                    title="String Voltage"
                    amount={`${dashboardSts?.strVolt || 0} V`}
                    icon={voltIcon}
                  />
                  <CardTwo
                    title="String Current"
                    amount={`${dashboardSts?.strCurr || 0}  A`}
                    icon={flash}
                  />
                </div>
                <div className="  border border-gray-400 p-[20px] rounded-lg">
                  <div className="relative">
                    <div className="mt-2 mb-5">
                      <p>Voltage data variation with time </p>
                      <p>
                        (between{" "}
                        {mppt_voltage_data_of_last24Hour.length > 0
                          ? mppt_voltage_data_of_last24Hour[
                            mppt_voltage_data_of_last24Hour.length - 1
                            ].timestamp +
                            " to " +
                            mppt_voltage_data_of_last24Hour[0].timestamp
                          : " "}
                        )
                      </p>
                      <div className="absolute top-0 right-0">
                        <IconButton>
                          <LongMenu
                            options={["Download as CSV", "Download as PDF"]}
                            onOptionClick={(exportOption) =>
                              handleExportBarChart(
                                exportOption,
                                getSTRINGDataToExport(innerValueInst1),
                                chartRef5
                              )
                            }
                            id={1}
                          />
                        </IconButton>
                      </div>
                    </div>
                    {/* <Paper square style={{ marginRight: "550px" }}>
                      <Tabs
                        value={innerValueInst1}
                        indicatorColor="primary"
                        textColor="primary"
                        onChange={(event, newValue) =>
                          setInnerValueInst1(newValue)
                        }
                      >
                        <Tab label="Voltage" />
                        <Tab label="Current" />
                      </Tabs>
                    </Paper> */}
                    <ButtonGroup
                      variant="outlined"
                      color="default"
                      aria-label="Basic button group"
                    >
                      <Button
                        variant="outlined"
                        style={innerValueInst1 === 0 ? { backgroundColor: "#dadada" } : {}}
                        onClick={() => setInnerValueInst1(0)}
                      >
                        Voltage
                      </Button>
                      <Button
                        variant="outlined"
                        style={innerValueInst1 === 1 ? { backgroundColor: "#dadada" } : {}}
                        onClick={() => setInnerValueInst1(1)}
                      >
                        Current
                      </Button>
                    </ButtonGroup>
                    <div ref={chartRef5 || ""}>
                      {valueInst === 2 && innerValueInst1 === 0 && (
                        <>
                          <div>
                            <LineChart
                              yAxisName="Voltage (V)"
                              xAxisName="Time"
                              data={lineChartDataOfSTRINGVoltage}
                              stroke={true}
                            />
                          </div>
                        </>
                      )}
                      {valueInst === 2 && innerValueInst1 === 1 && (
                        <>
                          <div>
                            <LineChart
                              yAxisName="Current (A)"
                              xAxisName="Time"
                              data={lineChartDataOfSTRINGCurrent}
                              stroke={true}
                            />
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </>
            )}
            {value === 1 && valueInst === 3 && (
              <>
                <div className="border border-gray-400 grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-6 xl:grid-cols-4 2xl:gap-7.5 mb-2 mt-2 p-2 my-cardTwo rounded-lg">
                  <CardTwo
                    title="Ambient Temperature"
                    amount={`${dashboardSts?.itAmb || 0} °C`}
                    icon={HotTemp}
                  />
                  <CardTwo
                    title="Internal Temperture"
                    amount={`${dashboardSts?.itInter || 0} °C`}
                    icon={RoomTemp}
                  />
                </div>
                <div className="  border border-gray-400 p-[20px] rounded-lg">
                  <div className="relative">
                    <div className="mt-2 mb-5">
                      <p>Temperature data variation with time </p>
                      <p>
                        (between{" "}
                        {ambient_temp_data_of_last24Hour.length > 0
                          ? ambient_temp_data_of_last24Hour[
                            ambient_temp_data_of_last24Hour.length - 1
                            ].timestamp +
                            " to " +
                            ambient_temp_data_of_last24Hour[0].timestamp
                          : " "}
                        )
                      </p>
                      <div className="absolute top-0 right-0">
                        <IconButton>
                          <LongMenu
                            options={["Download as CSV", "Download as PDF"]}
                            onOptionClick={(exportOption) =>
                              handleExportBarChart(
                                exportOption,
                                inverterTempPdfData,
                                chartRef6
                              )
                            }
                            id={1}
                          />
                        </IconButton>
                      </div>
                    </div>
                    <div ref={chartRef6 || ""}>
                      <LineChart
                        yAxisName="Temperature (°C)"
                        xAxisName="Time"
                        data={lineChartDataOfInverterTemp}
                        stroke={true}
                      />
                    </div>
                  </div>
                </div>
              </>
            )}
            <div></div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DashboardSolar1;
