import React, { useEffect, useRef, useState } from "react";
import { useDeviceState } from "../../app-context/device-context/DeviceState";
import { http } from "../../helper/http";
import temp from "../../images/temp2.jpeg";
import humadity from "../../images/humidity.jpeg";
import editLogo from "../../images/edit_1159633.png";
// import buzzerOn from "../../images/BuzzerOn.png"
// import buzzerOff from "../../images/BuzzerOff.png";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import {
  formatTimestamp,
  generateCSV1,
  reportDataWithGraph,
} from "../../common-function/CommonFunction";
import { toast } from "react-toastify";
import { io } from "socket.io-client";
import Switch from "@mui/material/Switch";
import MobileTowerIcon from "../../components/apex-chart/HeatMapChart";
import CardTwo from "../../components/dashBoardComponents/CardTwo";
import LongMenu from "../../utils/longMenu/LongMenu";
import LineChartForBuz from "../../components/apex-chart/LineChartForBuz";

const baseUrl = process.env.REACT_APP_BASE_URL;
const DashboardBuzMonitoring = ({
  deviceId,
  devices,
  selectedDevice,
}: any) => {
  const [dashboardSts, setDashboardSts] = useState<any>({});
  const [apiData, setApiData] = useState<any[]>([]);
  const [checked, setChecked] = useState(false);
  const [openSetTemp, setOpenSetTemp] = useState(false);
  const [tempData, setTempData] = useState<any>(null);
  const [tempData1, setTempData1] = useState<any>(null);
  const chartRef = useRef<HTMLDivElement>(null);
  const { devices: d } = useDeviceState();
  const modIORef = useRef<any>(null);
  const [openLoader, setOpenLoader] = useState(false);
  const responseTimeoutRef: any = useRef<NodeJS.Timeout | null>(null);

  const fetchData = async () => {
    let response: any = null;
    try {
      response = await http(
        baseUrl + `/device-data-log?deviceId=${selectedDevice}?last24=last24`
      );

      const _data = response
        .map((d: any) => {
          return {
            timestamp: formatTimestamp(d.timeStamp),
            rssi: d["rssi"] || "N/A",
            temperature: d["temp"] || "N/A",
            humadity: d["hum"] || "N/A",
            buzz: d["buz"] || "N/A",
            setTemp: d["set_temp"] || "N/A",
          };
        })

        .filter((d: any) => {
          return !Object.values(d).some(
            (val: any) =>
              typeof val === "string" && val.toLowerCase() === "error"
          );
        });
      const dummyTempData = parseInt(_data[0].setTemp);
      setDashboardSts(_data.length ? _data[0] : "");
      setChecked(_data[0].status === "ON" ? true : false);
      setTempData(dummyTempData);
      setTempData1(dummyTempData);
      setApiData(_data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };


  const handleChangeTesxt = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value === "") {
      setTempData(null);
    } else {
      const numberValue = parseInt(value);
      if (numberValue) {
        setTempData(numberValue);
      }
    }
  };

  const handleClickOpenSetTemp = () => {
    setOpenSetTemp(true);
  };

  const handleClickCloseSetTemp = () => {
    setOpenSetTemp(false);
  };

  const publishToMQTT = () => {
    if (modIORef.current) {
      modIORef.current.disconnect();
      modIORef.current = null;
    }
    const IO: any = io(`${baseUrl}/buz-config?deviceId=${selectedDevice}`);
    console.log("IIIIIIIPPPPPPPPPP", IO);
    
    modIORef.current = IO;

    IO.on("buz-response", (data: any) => {
        console.log("daataaa", data);
      if (data) {
        clearTimeout(responseTimeoutRef.current);
        setOpenLoader(false);
        
      }
    });

    return () => {
      if (modIORef.current) {
        modIORef.current.disconnect();
        modIORef.current = null;
      }
    };
  };

  const handleSave = async () => {
    setOpenLoader(true);
    const device = d.devices.find((d) => d.id === selectedDevice);
    const message = {
      set_temp: tempData,
      IMEI: device?.deviceId,
    };

    try {
      modIORef.current.emit("buz-config-data", message);
      setOpenSetTemp(false);
      responseTimeoutRef.current = setTimeout(() => {
        toast.warning("Gateway communication failed.");
        setOpenLoader(false);
      }, 30000);

    } catch (error) {
      console.error(error);
      setOpenSetTemp(false);
    }
  };
  const temperaturedatalast24hour =
    apiData.length > 0
      ? apiData.map(({ timestamp, temperature }: any) => ({
          timestamp,
          value: temperature,
        }))
      : [];

  const humaditydatalast24hour =
    apiData.length > 0
      ? apiData.map(({ timestamp, humadity }: any) => ({
          timestamp,
          value: humadity,
        }))
      : [];

  const buzzerStatusLast24hour =
    apiData.length > 0
      ? apiData.map(({ timestamp, buz }: any) => ({
          timestamp,
          value: buz,
        }))
      : [];

  const templinegraph: any = [
    {
      name: "Temprature",
      color: "#ed2e05",
      postfix: " \u2103",
      data: temperaturedatalast24hour.reverse(),
    },
    {
      name: "Humidity",
      color: "#2fed05",
      postfix: " %",
      data: humaditydatalast24hour.reverse(),
    },
    {
      name: "Buzzer Status",
      color: "#05ede6",
      data: buzzerStatusLast24hour.reverse(),
    },
  ];

  const handleExportBarChart = async (exportOption: string) => {
    let lineChartData = apiData?.map(
      ({ timestamp, temperature, humadity, buz }) => {
        return {
          TIMESTAMP: timestamp,
          "TEMPERATURE (°C)": temperature,
          "HUMIDITY (%)": humadity,
          STATUS: buz,
        };
      }
    );

    switch (exportOption) {
      case "Download as CSV":
        generateCSV1(lineChartData);
        break;
      case "Download as PDF":
        if (lineChartData && chartRef) {
          reportDataWithGraph(
            chartRef,
            lineChartData,
            devices?.deviceName,
            devices?.Customer?.name,
            "Temperature, Humidity & AC Status Summary",
            apiData.length > 0
              ? apiData[apiData.length - 1].timestamp +
                  " - " +
                  apiData[0].timestamp
              : ""
          );
        } else {
        }
        return;
      default:
        return;
    }
  };

  useEffect(() => {
    fetchData();
    publishToMQTT();
    const intervalId = setInterval(fetchData, 120000);
    return () => clearInterval(intervalId);
  }, [selectedDevice, devices.deviceId]);

  return (
    <div>
      <p className="flex  p-[10px] mb-[20px] ">
        <div>
          {/* <h1>MQTT Response: {response || 'Waiting for response...'}</h1> */}
        </div>
        <MobileTowerIcon
          levels={dashboardSts ? dashboardSts.rssi : 0 || 0}
          time={dashboardSts?.timestamp}
        />
        <p className="ml-2">
          {"    Last Data Received at  " + dashboardSts?.timestamp ||
            "  YYYY-MM-DD HH:MM:SS"}
        </p>
      </p>
      <div className="border border-gray-400 rounded-md p-2 grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-6 xl:grid-cols-4 2xl:gap-7.5 mb-2">
        <div className="border border-stroke p-3 bg-white rounded-md">
          <div className="flex">
            <div className="w-3/4">
              <div className="mt-2 flex items-end justify-between">
                <div>
                  <h2 className="text-[16px]">Buzzer Status</h2>
                  <h4
                    style={{ color: checked ? "red" : "green" }}
                    className="text-title-md font-bold text-black text-[24px] py-1"
                  >
                    {checked ? "ON" : "OFF"}{" "}
                  </h4>
                </div>

                {/* <div className="w-1/4 mt-4 flex justify-center items-center rounded-sm">
                  <img
                    src={
                      checked
                        ? buzzerOn
                        : buzzerOff
                    }
                    alt={checked ? "Buzzer ON" : "Buzzer OFF"}
                    className="w-full h-auto"
                  />
                </div> */}
              </div>
            </div>
          </div>
        </div>
        <CardTwo
          title="Set Temperature"
          temperature={`${tempData1 ? tempData1 : "N/A "}\u2103`}
          icon={editLogo}
          onClickIcon={handleClickOpenSetTemp}
        />
        <CardTwo
          title="Temperature"
          temperature={`${
            dashboardSts ? dashboardSts.temperature : "N/A "
          }\u2103`}
          icon={temp}
        />
        <CardTwo
          title="Humidity"
          temperature={`${dashboardSts ? dashboardSts.humadity : "N/A "}%`}
          icon={humadity}
        />
      </div>
      <div className="pt-[5px]">
        <Dialog open={openSetTemp} onClose={handleClickCloseSetTemp}>
          <DialogTitle>Edit Set Temperature Data</DialogTitle>
          <DialogContent>
            <TextField
              margin="dense"
              name="temperature"
              label="Set Temperature"
              type="number"
              fullWidth
              value={tempData}
              onChange={handleChangeTesxt}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClickCloseSetTemp} color="primary">
              Cancel
            </Button>
            <Button onClick={handleSave} color="primary">
              Save
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={openLoader}
          onClose={(event, reason) => {
            if (reason === "backdropClick" || reason === "escapeKeyDown") {
              return;
            }
            setOpenLoader(false);
          }}
          sx={{
            "& .MuiDialog-paper": {
              backgroundColor: "transparent",
              boxShadow: "none",
            },
            "& .MuiBackdrop-root": {
              backgroundColor: "rgba(0, 0, 0, 0.5)",
            },
          }}
        >
          <DialogContent
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              color: "white",
              textAlign: "center",
            }}
          >
            <CircularProgress />
            <p style={{ fontWeight: "bold" }}>
              Processing your request... Please wait
            </p>
          </DialogContent>
        </Dialog>
      </div>
      <div className="border border-gray-400 rounded-md p-2">
        <div className="relative">
          <div className="absolute top-0 right-0">
            <LongMenu
              options={["Download as CSV", "Download as PDF"]}
              onOptionClick={handleExportBarChart}
              id={1}
            />
          </div>
        </div>
        <p>
          Temperature & Humidity v/s Status data variation with time (between{" "}
          {apiData.length > 0
            ? apiData[apiData.length - 1].timestamp +
              " to " +
              apiData[0].timestamp
            : " "}
          )
        </p>

        <div ref={chartRef} style={{ marginTop: "15px" }}>
          <LineChartForBuz data={templinegraph} xAxisName="Time" />
        </div>
      </div>
    </div>
  );
};

export default DashboardBuzMonitoring;
