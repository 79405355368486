import { useState, useEffect, useMemo } from "react";
import Table from "@mui/material/Table";
import Box from "@mui/material/Box";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import AddCustomerForm from "./AddCustomerForm";
import { Customer, CustomerApiResponse } from "../../../types/CustomerTypes";
import { useCustomerState } from "../../../app-context/customer-context/CustomerState";
import LongMenu from "../../../utils/longMenu/LongMenu";
import {
  getCustomerPageClick,
  setCustomer,
  updateCustomerStatus,
} from "../../../app-context/customer-context/CustomerAction";
import { EnhancedTableToolbar } from "./EnhancedTableToolBar";
import { http } from "../../../helper/http";
import { EnhancedTableHead } from "./EnhancedTableHead";
import { ConfirmModal } from "../../../utils/alertui/ConfirmModal";
import { toast } from "react-toastify";
import { generatePDFReport } from "../../../common-function/CommonFunction";
import { useUserState } from "../../../app-context/auth-context/AuthState";
import { getReportAll } from "../../../common-function/Report";

const baseUrl = process.env.REACT_APP_BASE_URL;
export default function Customers() {
  const [page] = useState(0);
  const [rowsPerPage] = useState(10);
  const [updatedRows, setUpdatedRows] = useState<Customer[]>([]);
  const [editModal, setEditModal] = useState(false);
  //this customerData for setEdit data while editing
  const [customerData, setCustomerData] = useState<Customer[]>([]);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [deleteId, setDeleteId] = useState<number | null>(null);
  const { users } = useUserState();

  const { customers, dispatch } = useCustomerState();
  useEffect(() => {
    if(customers.customers.length === 0){
      setCustomer(dispatch, 1);
    }
  }, []);
  useEffect(() => {
    setUpdatedRows(customers.customers);
  }, [customers]);

  const handlePageChange = async (newPage: number) => {
    const direction = newPage > customers.currentPage - 1 ? "next" : "previous";
    const page = +customers.currentPage;
    try {
      const resp = (await http(
        baseUrl + `/customer?page=${direction === "next" ? page + 1 : page - 1}`
      )) as CustomerApiResponse;
      const newCustomers = resp.customers;
      getCustomerPageClick(dispatch, newCustomers);
    } catch (err) {
      console.error(err);
    }
  };

  const toggleCustomerForm = () => {
    setEditModal((prevVal) => !prevVal);
  };
  const emptyRows = 10 - updatedRows.length;

  const visibleRows = useMemo(
    () =>
      updatedRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    [page, rowsPerPage, updatedRows]
  );

  const handleMenuOptionClick = async (option: string, id: number) => {
    try {
      switch (option) {
        case "Delete":
          setShowConfirmModal(true);
          setDeleteId(id);
          break;
        case "Edit":
          const editResp = (await http(
            baseUrl + `/customer?id=${id}`
          )) as CustomerApiResponse;
          const customers = editResp.customers;
          setCustomerData([customers]);
          toggleCustomerForm();
          break;
        case "Report":
          const response = (await http(
            baseUrl + "/report?customerId=" + id
          )) as any; // Adjust the URL as needed
          if (response.length > 0) {
            // generatePDFReport(response,"" );
            getReportAll(response, users?.user?.email || "N/A");
          }
          break;
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleConfirmDelete = async () => {
    if (deleteId !== null) {
      try {
        const deleteResp = (await http(
          baseUrl + `/customer?id=${deleteId}`,
          "DELETE"
        )) as CustomerApiResponse;
        toast.success(deleteResp.message);
        if (customers.customers.length === 1) {
          setCustomer(dispatch, 1);
        } else {
          setCustomer(dispatch, customers.currentPage);
        }
      } catch (err: any) {
        // console.log();
        toast.error(err.message);
      }
    }
    setShowConfirmModal(false);
  };

  return (
    <>
      <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <Paper sx={{ width: "99%" }}>
          <EnhancedTableToolbar />
          <div className="h-[72vh] overflow-y-auto">
            <TableContainer>
              {showConfirmModal && (
                <ConfirmModal
                  title="Confirmation"
                  message="Are you sure you want to delete?"
                  onConfirm={handleConfirmDelete}
                  onCancel={() => {
                    setShowConfirmModal(false);
                  }}
                  isVisible={showConfirmModal}
                />
              )}
              <Table
                className="min-w-750 bg-white"
                aria-labelledby="tableTitle"
                size="medium"
              >
                {editModal && (
                  <AddCustomerForm
                    uniqueId="EDIT"
                    data={customerData}
                    toggleForm={editModal}
                    toggleFormFun={toggleCustomerForm}
                  />
                )}
                <EnhancedTableHead />
                <TableBody>
                  {visibleRows.map((row) => (
                    <TableRow
                      key={row?.id || null}
                      hover
                      className="cursor-pointer"
                    >
                      {row && (
                        <>
                          <TableCell align="center" className="py-3">
                            {row.name}
                          </TableCell>
                          <TableCell align="center" className="py-3">
                            {row.email}
                          </TableCell>
                          {/* <TableCell align="center" className="py-3">
                            <Switch
                              checked={
                                row.status !== undefined ? row.status : false
                              }
                              color="primary"
                              onChange={() =>
                                handleSwitchToggle(row.id || 0, row.status)
                              }
                            />
                          </TableCell> */}
                          <TableCell
                            className="text-center"
                            style={{ textAlign: "center" }}
                          >
                            <LongMenu
                              options={["Edit", "Delete", "Report"]}
                              onOptionClick={handleMenuOptionClick}
                              id={row.id}
                            />
                          </TableCell>
                        </>
                      )}
                    </TableRow>
                  ))}
                  {emptyRows > 0 && (
                    <TableRow
                      style={{
                        height: 45 * emptyRows,
                      }}
                    >
                      <TableCell colSpan={4} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          <TablePagination
            rowsPerPageOptions={[]}
            component="div"
            count={customers?.totalItems || 0}
            rowsPerPage={10}
            page={Math.max(
              0,
              Math.min(customers?.currentPage - 1, customers?.totalPages - 1)
            )}
            onPageChange={(event, newPage) => handlePageChange(newPage)}
            className="bg-gray-100"
          />
        </Paper>
      </Box>
    </>
  );
}
