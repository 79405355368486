import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { TablePagination, TableSortLabel } from "@mui/material";
import { styled } from "@mui/material/styles";

interface TabularComponentsProps {
  headers: string[];
  rows: any[];
  sorting?: boolean;
  sortableColumns?: string[];
  onRowClick?: (row: any) => void; // Function prop for row click
  rowsPerPage?: number; // Controlled rows per page
  page?: number; // Controlled page number
  onPageChange?: (event: unknown, newPage: number) => void; // Page change handler
  onRowsPerPageChange?: (event: React.ChangeEvent<HTMLInputElement>) => void; // Rows per page change handler
  totalItems:number,
  currentPage: number,
  totalPages: number

}

const StylesTableCell = styled(TableCell)(({ theme }) => ({
  "&.MuiTableCell-root": {
    padding: theme.spacing(2),
  },
}));

const StyledTableSortLabel = styled(TableSortLabel)(({ theme }) => ({
  '& .MuiTableSortLabel-icon': {
    opacity: 1,
  },
}));

const TabularComponents2: React.FC<TabularComponentsProps> = ({
  headers,
  rows,
  sorting = true,
  sortableColumns = [],
  onRowClick,
  rowsPerPage = 10,
  page = 0,
  onPageChange = () => {}, // Default to an empty function
  onRowsPerPageChange = () => {}, // Default to an empty function
  totalItems,
  currentPage,
  totalPages
}) => {
  const [order, setOrder] = React.useState<"asc" | "desc">("asc");
  const [orderBy, setOrderBy] = React.useState<string>("");

  const handleRequestSort = (property: string) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const sortedRows = sorting
    ? rows.slice().sort((a, b) => {
        const aValue = a[orderBy];
        const bValue = b[orderBy];

        if (typeof aValue === "string" && typeof bValue === "string") {
          return order === "asc"
            ? aValue.localeCompare(bValue)
            : bValue.localeCompare(aValue);
        }

        return order === "asc" ? aValue - bValue : bValue - aValue;
      })
    : rows;

  // Calculate the rows to be displayed based on pagination
  const startIdx = page * rowsPerPage;
  const endIdx = startIdx + rowsPerPage;
  const displayedRows = sortedRows.slice(startIdx, endIdx);

  return (
    <div>
      <TableContainer component={Paper} className="h-[600px]">
        <Table sx={{ minWidth: 750 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              {headers.map((header, index) => (
                <StylesTableCell key={index}>
                  {sortableColumns.includes(header.toUpperCase()) ? (
                    <StyledTableSortLabel
                      active={orderBy === header.toLowerCase()}
                      direction={orderBy === header.toLowerCase() ? order : "desc"}
                      onClick={() => handleRequestSort(header.toLowerCase())}
                    >
                      {header}
                    </StyledTableSortLabel>
                  ) : (
                    header
                  )}
                </StylesTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {displayedRows.length > 0 ? (
              displayedRows.map((row, rowIndex) => {
                const normalizedRow = Object.keys(row).reduce((acc, key) => {
                  acc[key.toLowerCase()] = row[key];
                  return acc;
                }, {} as { [key: string]: any });

                return (
                  <TableRow
                    key={rowIndex}
                    sx={{
                      "&:last-child td, &:last-child th": { borderBottom: 0 },
                    }}
                    onClick={() => onRowClick && onRowClick(normalizedRow)} // Call the function if it exists
                  >
                    {headers.map((header, headerIndex) => (
                      <StylesTableCell
                        key={headerIndex}
                        align="left"
                        sx={{ padding: "8px" }}
                      >
                        {normalizedRow[header.toLowerCase()]}
                      </StylesTableCell>
                    ))}
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <StylesTableCell
                  className="flex items-center h-[600px]"
                  colSpan={headers.length} align="center"
                >
                  No data available
                </StylesTableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {rows.length > 0 && (
        <TablePagination
          rowsPerPageOptions={[10, 50, 100, 500]}
          component="div"
          count={totalItems} // Total count of sorted rows
          rowsPerPage={rowsPerPage}
          page={Math.max(
            0,
            Math.min(currentPage - 1, totalPages - 1)
          )}
          onPageChange={onPageChange}
          onRowsPerPageChange={onRowsPerPageChange}
        />
      )}
    </div>
  );
};

export default TabularComponents2;
