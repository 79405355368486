import { useEffect, useRef, useState } from "react";
import CardTwo from "../../components/dashBoardComponents/CardTwo";
import axios from "axios";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import tempIcon from "../../images/temp.png";
import humidity from "../../images/humidity.jpeg";
import "../../App.css";
import {
  formatTimestamp,
  generateCSV1,
  reportDataWithGraph,
} from "../../common-function/CommonFunction";
import LineChart from "../../components/apex-chart/LineChart";
import MobileTowerIcon from "../../components/apex-chart/HeatMapChart";
import LongMenu from "../../utils/longMenu/LongMenu";
import { Button, ButtonGroup } from "@material-ui/core";

const baseUrl = process.env.REACT_APP_BASE_URL;
const DashboardTemp1 = ({ selectedDevice, devices }: any) => {
  const [value, setValue] = useState(0);
  const [dashboardSts, setDashboardSts] = useState<any>({});
  // console.log('dashboardSts22',dashboardSts)
  const [temperatureData, setTemperatureData] = useState<any>([]);
  const [humidityData, setHumidityData] = useState<any>([]);
  const [apiData, setApiData] = useState<any[]>([]);
  const chartRef = useRef<HTMLDivElement>(null);

  const fetchData = async () => {
    const accessToken = localStorage.getItem("token");
    try {
      const response = await axios.get(baseUrl + `/device-data-log?last24=last24`, {
        params: { deviceId: selectedDevice },
        headers: { Authorization: `Bearer ${accessToken}` },
      });

      const data = response.data
        .map((d: any) => ({
          timestamp: formatTimestamp(d.timeStamp),
          temperature:
            d["Temperature"] !== "ERROR" ? d["Temperature"] : "ERROR",
          humidity: d["Humidity"] !== "ERROR" ? d["Humidity"] : "ERROR",
          rssi: d["rssi"],
        }))
        .filter((d: any) => {
          return !Object.values(d).some(
            (val: any) =>
              typeof val === "string" && val.toLowerCase() === "error"
          );
        });

      setDashboardSts(data.length ? data[0] : {});
      setTemperatureData(temperatureData);
      setHumidityData(humidityData);
      setApiData(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
    const intervalId = setInterval(fetchData, 120000);
    return () => clearInterval(intervalId);
  }, [selectedDevice]);

  const currentDate = new Date();
  const last24HoursData = apiData.filter((entry) => {
    const entryDate = new Date(entry.timestamp);
    const timeDifference = currentDate.getTime() - entryDate.getTime();
    return timeDifference <= 24 * 60 * 60 * 1000; // Include entries up to the current timestamp
  });

  const tlinechart =
    last24HoursData.length > 0
      ? last24HoursData.map(({ timestamp, temperature }: any) => ({
          timestamp,
          value: temperature,
        }))
      : [];
  const hlinechart =
    last24HoursData.length > 0
      ? last24HoursData.map(({ timestamp, humidity }: any) => ({
          timestamp,
          value: humidity,
        }))
      : [];

  const temp: any = [
    {
      name: "Temprature",
      color: "#34bf4b",
      // showDots: true,
      postfix: " \u2103",
      data: tlinechart.reverse(),
    },
  ];

  const hum: any = [
    {
      name: "Humidity",
      color: "#34bf4b",
      // showDots: true,
      postfix: " %",
      data: hlinechart.reverse(),
    },
  ];
  const handleExportBarChart = async (exportOption: string) => {
    let lineChartData =
      value === 0
        ? tlinechart?.map(({ timestamp, value }) => {
            return {
              TIMESTAMP: timestamp,
              TEMPERATURE: value,
              UNIT: " °C",
            };
          })
        : hlinechart.map(({ timestamp, value }) => {
            return {
              TIMESTAMP: timestamp,
              HUMIDITY: value,
              UNIT: " %",
            };
          });

    switch (exportOption) {
      case "Download as CSV":
        generateCSV1(lineChartData);
        break;
     
      case "Download as PDF":
        if (lineChartData && chartRef) {
          reportDataWithGraph(
            chartRef,
            lineChartData,
            devices?.deviceName,
            devices?.Customer?.name,
            "Temperature & Humidity Summary",
            last24HoursData.length > 0
              ? last24HoursData[last24HoursData.length - 1].timestamp +
                  " - " +
                  last24HoursData[0].timestamp
              : ""
          );
        } else {
        }
        return;
      default:
        return; // Unknown export option
    }
  };

  return (
    <div>
      <p className="flex p-[10px] mb-[20px]">
        <MobileTowerIcon levels={dashboardSts.rssi || 0} time={dashboardSts?.timestamp}/>
        <p className="ml-2">
          {"    Last Data Received at  " + dashboardSts?.timestamp ||
            "  YYYY-MM-DD HH:MM:SS"}
        </p>
      </p>
      <div className="border border-gray-400 rounded-md grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-6 xl:grid-cols-4 2xl:gap-7.5 mb-2 p-2">
        <CardTwo
          title="Temperature"
          temperature={`${dashboardSts.temperature + " " || 0} \u2103`}
          icon={tempIcon}
          // background="#ebbcc4"
        />
        <CardTwo
          title="Humidity"
          humidity={`${dashboardSts.humidity + " " || 0}%`}
          icon={humidity}
          // background="#d1ffbd"
        />
      </div>

      <div className="pt-[5px]"></div>

      <div
        className="border border-gray-400 rounded-md p-2"
        // style={{ border: "2px solid #EAECF0", padding: "10px" }}
      >
        <div className="relative">
          <div className="absolute top-0 right-0">
            <LongMenu
              options={[
                "Download as CSV",
                "Download as PDF",
              ]}
              onOptionClick={handleExportBarChart}
              id={1}
            />
          </div>
        </div>
        <p>
          {value === 0 ? "Temperature" : "Humidity"} Variation with time
          (between{" "}
          {last24HoursData.length > 0
            ? last24HoursData[last24HoursData.length - 1].timestamp +
              " to " +
              last24HoursData[0].timestamp
            : " "}
          )
        </p>
        <div className="pb-[20px] pt-[10px]">
          <div className="w-[250px]">
            <ul className="text-sm font-medium text-center text-gray-500 rounded-lg sm:flex">
              <li className="focus-within:z-10">
                <button
                 
                  className={`inline-block border border-gray-300 px-4 py-2 text-[1rem] text-gray-900 rounded-s-lg  transition-colors duration-300`}
                  aria-current="page"
                  style={value === 0 ? { backgroundColor: "#dadada" } : {}}
                  onClick={() => setValue(0)}
                >
                  Temperature
                </button>
              </li>

              <li className="focus-within:z-10">
                <button
                  className={`inline-block border border-gray-300 px-5 py-2 text-[1rem] text-gray-900 rounded-e-lg transition-colors duration-300`}
                  style={value === 1 ? { backgroundColor: "#dadada" } : {}}
                  onClick={() => setValue(1)}
                >
                  Humidity
                </button>
              </li>
            </ul>
          </div>
        </div>
        <div ref={chartRef}>
          {value === 0 ? (
            <LineChart
              yAxisName="Temperature ℃"
              stroke={true}
              data={temp}
              xAxisName="Time"
            />
          ) : (
            <LineChart
              yAxisName="Humidity %"
              stroke={true}
              data={hum}
              xAxisName="Time"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default DashboardTemp1;
