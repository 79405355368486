import { useEffect, useRef, useState } from "react";
import { http } from "../../helper/http";
import {
  formatDate,
  formatTimestamp,
  generateCSV1,
  reportDataWithGraph,
} from "../../common-function/CommonFunction";
import MobileTowerIcon from "../../components/apex-chart/HeatMapChart";
import CardTwo from "../../components/dashBoardComponents/CardTwo";
import LineChart from "../../components/apex-chart/LineChart";
import LongMenu from "../../utils/longMenu/LongMenu";
const baseUrl = process.env.REACT_APP_BASE_URL;
const Dashboard420LevelCustom = ({ devices, selectedDevice }: any) => {
  const [apiData, setApiData] = useState<any[]>([]);
  const [dashboardSts, setDashboardSts] = useState<any>([]);
  const chartRef = useRef<HTMLDivElement>(null);
  const [customTagData, setCustomTagData] = useState<any>([]);
  

  const fetchData = async () => {
    let response: any = null;
    let dof = new Date();
    const formattedDate = formatDate(dof);
    try {
      response = await http(
        baseUrl +
          `/device-data-log?deviceId=${selectedDevice}&last24=last24`
      );
      const _data = response
        .map((d: any, i: any) => {
          return {
            timestamp: formatTimestamp(d.timeStamp),
            rssi: d["rssi"],
            Level: d["Level"],
            unit: ["m"],
          };
        })
        .filter((d: any) => {
          return !Object.values(d).some((val: any) => {
            return typeof val === "string" && val.toLowerCase() === "error";
          });
        });
        

      setApiData(_data);
      setDashboardSts(_data.length ? _data[0] : "");
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  

  useEffect(() => {
    fetchData();
    if (devices?.DeviceTemplate?.tagJson) {
      const transformedTagJson = devices.DeviceTemplate.tagJson.reduce(
        (acc: any, tag: any) => {
          acc[tag.tagName] = tag;
          return acc;
        },
        {}
      );
      setCustomTagData(transformedTagJson);
    }
    const intervalId = setInterval(fetchData, 120000);
    return () => clearInterval(intervalId);
  }, [selectedDevice]);
  

  const levellinechartdata =
    apiData.length > 0
      ? apiData.map(({ timestamp, Level }: any) => ({
          timestamp,
          value: Level,
        }))
      : [];

  const flowlinegraph: any = [
    {
      name: `${customTagData?.["Level"]?.sensorAlias ||"Level"}`,
      color: "#3cbd2b",
      data: levellinechartdata.reverse(),
      postfix: ` ${customTagData?.["Level"]?.sensorUnit ||"m"}`,
    },
  ];

  const handleExportBarChart = async (exportOption: string) => {
    let lineChartData = levellinechartdata.map(({ timestamp, value }: any) => {
      return {
        Timestamp: timestamp,
        Level: value,
        Unit: ` ${customTagData?.["Level"]?.sensorUnit ||"m"}`,
      };
    });

    switch (exportOption) {
      case "Download as CSV":
        generateCSV1(lineChartData);
        break;
    
      case "Download as PDF":
        if (lineChartData && chartRef) {
          reportDataWithGraph(
            chartRef,
            lineChartData,
            devices?.deviceName,
            devices?.Customer?.name,
            "Level Summary",
            apiData?.length > 0
              ? apiData[apiData?.length - 1].timestamp + " to " +
                  apiData[0].timestamp
              : "",
              "Report_Level_Summary",
          );
        } else {
        }
        return;
      default:
        return; 
    }
  };

  return (
    <div>
      <p className=" flex  p-[10px] mb-[20px]">
        <MobileTowerIcon levels={dashboardSts?.rssi || 0} time={dashboardSts?.timestamp}/>
        <p className="ml-2">
          {"    Last Data Received at  " + dashboardSts?.timestamp ||
            "  YYYY-MM-DD HH:MM:SS"}
        </p>
      </p>

      <div className="border border-gray-400 rounded-md p-[10px] mb-[5px] grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-6 xl:grid-cols-4 2xl:gap-7.5">
      {customTagData?.["Level"]?.sensorStatus ? (
        <CardTwo title={customTagData?.["Level"]?.sensorAlias ||"Level"}
        amount={`${dashboardSts?.Level || 0}  ${
          customTagData?.["Level"]?.sensorUnit || "m"
        }`} />) : !customTagData?.["Level"] ? (
          <CardTwo title="Level"
        amount={`${dashboardSts?.Level || 0} m`} />) : null}
      </div>

      <div className="border border-gray-400 p-2 rounded-md">
        <div className="relative">
          <div className="absolute top-0 right-0">
            <LongMenu
              options={[
                "Download as CSV",
                "Download as PDF",
              ]}
              onOptionClick={handleExportBarChart}
              id={1}
            />
          </div>
          <p>
            Level data variation with time (Between{" "}
            {apiData.length > 0
              ? apiData[apiData.length - 1].timestamp +
                " to " +
                apiData[0].timestamp
              : " "}
            )
          </p>
        </div>
        <div ref={chartRef} style={{marginTop: "15px"}}>
          <LineChart
            stroke={true}
            yAxisName={`${customTagData?.["Level"]?.sensorAlias ||"Level"} (${customTagData?.["Level"]?.sensorUnit ||"m"})`}
            xAxisName="Time"
            data={flowlinegraph}
          />
        </div>
      </div>
    </div>
  );
};


export default Dashboard420LevelCustom