import { Box, Button, InputAdornment, Paper, Switch, Table, TableBody, TableCell, TableContainer, TablePagination, TableRow, TextField } from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import { http } from '../../../helper/http';
import EnhancedTableHeader from './EnhancedTableHeader';
import Loader from '../../../components/Loader/Loader';
import SearchIcon from '@mui/icons-material/Search';
import DownloadIcon from '@mui/icons-material/Download';
import { generateCSV1 } from '../../../common-function/CommonFunction';
import { NavLink } from 'react-router-dom';



const baseUrl = process.env.REACT_APP_BASE_URL;
function ACUserDashboard() {
    const [loading, setLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [allDevices, setAllDevices] = useState<any>([])
    const [totalDevices, setTotalDevices] = useState<any>("")
    const [activeDevices, setActiveDevices] = useState<number>(0)
    const [inactiveDevices, setInactiveDevices] = useState<number>(0)
    const [totalScheduleOn, setTotalScheduleOn] = useState<number>(0)
    const [totalScheduleOff, setTotalScheduleOff] = useState<number>(0)
    const [totalItems, setTotalItems] = useState(0);


    const handlePageChange = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    useEffect(() => {
        const fetchAllData = async () => {
            setLoading(true);
            try {
                const overviewResp = await http(`${baseUrl}/overview-dashboard?key=ac`) as any;
                setAllDevices(overviewResp.responseAc || [])
                setTotalItems(overviewResp.responseAc.length);
                setTotalDevices(overviewResp.totalDevices);
                setActiveDevices(overviewResp.totalActiveDevices)
                setInactiveDevices(overviewResp.totalDevices - overviewResp.totalActiveDevices)
                setTotalScheduleOn(overviewResp.totalScheduleOn)
                setTotalScheduleOff(overviewResp.totalScheduled - overviewResp.totalScheduleOn)
                setLoading(false);
            } catch (error) {
                console.error("Error fetching data:", error);
                setLoading(false);
            }
        };

        fetchAllData();
    }, [])

    const handleSearchChange = (event: any) => {
        setSearchTerm(event.target.value);
    };

    // console.log("all devices", allDevices);


    const filteredDevices = useMemo(() => {
        return allDevices.filter((device: any) => {
            const lowerSearchTerm = searchTerm.toLowerCase();
            const isActiveSearch = lowerSearchTerm === "active" || lowerSearchTerm === "a" || lowerSearchTerm === "ac" || lowerSearchTerm === "act" || lowerSearchTerm === "acti" || lowerSearchTerm === "activ";
            const isInactiveSearch = lowerSearchTerm === "inactive" || lowerSearchTerm === "in" || lowerSearchTerm === "ina" || lowerSearchTerm === "inac" || lowerSearchTerm === "inact" || lowerSearchTerm === "inacti" || lowerSearchTerm === "inactiv";
            const isONSearch = lowerSearchTerm === "on" || lowerSearchTerm === "o";
            const isOFFSearch = lowerSearchTerm === "off" || lowerSearchTerm === "o" || lowerSearchTerm === "of";
            const hasLogOn = device.deviceDataLogs?.some((log: any) => log.tagId === 189 && log.value === "ON");
            const hasLogOff = device.deviceDataLogs?.some((log: any) => log.tagId === 189 && log.value === "OFF");

            return (
                device.deviceName.toLowerCase().includes(lowerSearchTerm) ||
                device.deviceAlias.toLowerCase().includes(lowerSearchTerm) ||
                (isActiveSearch && device.status === true) ||
                (isInactiveSearch && device.status === false) ||
                (isONSearch && hasLogOn) ||
                (isOFFSearch && hasLogOff)
            );
        });
    }, [allDevices, searchTerm]);

    const visibleRows = useMemo(
        () => filteredDevices.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
        [page, rowsPerPage, filteredDevices]
    );

    const handleClickExport = () => {
        let excelData = allDevices?.map((device: any) => {
            let acStatus = "OFF";
            let temperature = "N/A";
            let humidity = "N/A";
            let set_temp = "N/A";
            let tempurary_sch_time_on = device.scheduling ? device.scheduling.schedulerOn : "";
            let tempurary_sch_time_off = device.scheduling ? device.scheduling.schedulerOff : "";
            let sch_time = tempurary_sch_time_on && tempurary_sch_time_off ? `${tempurary_sch_time_on} - ${tempurary_sch_time_off}` : "N/A";
            let tempurary_sch_status = device.scheduling ? device.scheduling.triggerStatus : false;
            let sch_status = tempurary_sch_status ? "ON" : "OFF";
            let deviceStatus = device.status ? "Active" : "Inactive";

            device.deviceDataLogs.forEach((log: any) => {
                switch (log.tagId) {
                    case 189:
                        acStatus = log.value;
                        break;
                    case 191:
                        temperature = log.value;
                        break;
                    case 188:
                        humidity = log.value;
                        break;
                    case 190:
                        set_temp = log.value;
                        break;
                    default:
                        break;
                }
            });

            return {
                "Device Name": device.deviceName,
                "Device Alias": device.deviceAlias,
                "AC Status": acStatus,
                "Temperature": temperature,
                "Humidity": humidity,
                "Set_Temp": set_temp,
                "SCH. Time": sch_time,
                "SCH. Status": sch_status,
                "Device Status": deviceStatus
            };
        });
        generateCSV1(excelData);

    }

    return (
        <>
            <div className="flex gap-4 ">
                <div className="bg-white p-4 shadow-sm mb-4 w-full border border-solid border-gray-300">
                    <h2 className="text-xl mb-2 font-sans-serif text-center">
                        Total Devices
                    </h2>
                    {loading ? (
                        <Loader />
                    ) : (
                        <p className="text-center text-xl font-bold text-blue-600">
                            {totalDevices}
                        </p>
                    )}
                </div>
                <div className="bg-white p-4 shadow-sm mb-4 w-full border border-solid border-gray-300">
                    <h2 className="text-xl mb-2 font-sans-serif text-center">
                        Active devices
                    </h2>
                    {loading ? (
                        <Loader />
                    ) : (
                        <p className="text-center text-xl font-bold text-green-600">
                            {activeDevices}
                        </p>
                    )}
                </div>
                <div className="bg-white p-4 shadow-sm mb-4 w-full border border-solid border-gray-300">
                    <h2 className="text-xl mb-2 font-sans-serif text-center">
                        Inactive Devices
                    </h2>
                    {loading ? (
                        <Loader />
                    ) : (
                        <p className="text-center text-xl font-bold text-red-600">
                            {inactiveDevices}
                        </p>
                    )}
                </div>
                <div className="bg-white p-4 shadow-sm mb-4 w-full border border-solid border-gray-300">
                    <h2 className="text-xl mb-2 font-sans-serif text-center">
                        Schedular On/Off
                    </h2>
                    {loading ? (
                        <Loader />
                    ) : (
                        <p className="text-center text-xl font-bold">
                            <span className="text-green-600">{totalScheduleOn}</span> /
                            <span className="text-red-600"> {totalScheduleOff}</span>
                        </p>
                    )}
                </div>
            </div>
            {loading ? (
                <Loader />
            ) : (
                <Box
                    sx={{ width: "100%", display: "flex", justifyContent: "center" }}
                >
                    <Paper sx={{ width: "99%", }}>
                        <div className='flex flex-row justify-between'>
                            <TextField
                                variant="outlined"
                                placeholder="Search..."
                                value={searchTerm}
                                onChange={handleSearchChange}
                                sx={{ margin: 2, width: '300px' }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleClickExport}
                                sx={{ margin: 2 }}
                            >
                                <DownloadIcon />Export Data
                            </Button>

                        </div>
                        <div className="h-[73vh] overflow-y-auto">
                            <TableContainer>
                                <Table
                                    className="min-w-750 bg-white"
                                    aria-labelledby="tableTitle"
                                    size="medium"
                                >
                                    <EnhancedTableHeader />
                                    <TableBody className="">
                                        {visibleRows.map((row: any) => (
                                            <TableRow
                                                key={row.id}
                                                hover
                                                className="cursor-pointer transition-all hover:bg-gray-100"
                                            >
                                                <TableCell
                                                    className="text-center"
                                                    style={{ textAlign: "start" }}
                                                >
                                                    <NavLink
                                                        to={`/dashboard`}
                                                        state={{
                                                            deviceId: row.id,
                                                            deviceName: row.deviceName,
                                                        }}
                                                        className="hover:underline"
                                                    >
                                                        {row.deviceName}
                                                    </NavLink>
                                                </TableCell>
                                                <TableCell
                                                    className="text-center"
                                                    style={{ textAlign: "start", padding: "10px" }}
                                                >
                                                    <NavLink
                                                        to={`/dashboard`}
                                                        state={{
                                                            deviceId: row.id,
                                                            deviceName: row.deviceName,
                                                        }}
                                                        className="hover:underline"
                                                    >
                                                        {row.deviceAlias}
                                                    </NavLink>
                                                </TableCell>

                                                <TableCell className="text-center" style={{ textAlign: "start" }}>
                                                    <span
                                                        className={`text-${row.deviceDataLogs.some((log: any) => log.tagId === 189 && log.value === "ON") ? "green-600" : "red-600"} 
                                                        ${row.deviceDataLogs.length > 0 && row.deviceDataLogs.some((log: any) => log.tagId === 189 && log.value === "ON") ? "bg-green-200" : "bg-red-100"} 
                                                        rounded-md 
                                                        ${row.deviceDataLogs.length > 0 && row.deviceDataLogs.some((log: any) => log.tagId === 189 && log.value === "ON") ? "px-4 py-2" : "px-3 py-2"}`}
                                                    >
                                                        {row.deviceDataLogs.some((log: any) => log.tagId === 189 && log.value === "ON") ? "ON" : "OFF"}
                                                    </span>
                                                </TableCell>

                                                <TableCell className="text-center" style={{ textAlign: "start", padding: "10px" }}>
                                                    {row.deviceDataLogs.length > 0
                                                        ? row.deviceDataLogs.find((log: any) => log.tagId === 191)?.value || "N/A"
                                                        : "N/A"}
                                                </TableCell>

                                                <TableCell className="text-center" style={{ textAlign: "start", padding: "10px" }}>
                                                    {row.deviceDataLogs.length > 0
                                                        ? row.deviceDataLogs.find((log: any) => log.tagId === 188)?.value || "N/A"
                                                        : "N/A"}
                                                </TableCell>

                                                <TableCell className="text-center" style={{ textAlign: "start", padding: "10px" }}>
                                                    {row.deviceDataLogs.length > 0
                                                        ? row.deviceDataLogs.find((log: any) => log.tagId === 190)?.value || "N/A"
                                                        : "N/A"}
                                                </TableCell>

                                                <TableCell className="text-center" style={{ textAlign: "start", padding: "10px", whiteSpace: "nowrap" }}>
                                                    {row.scheduling ? (
                                                        `${row.scheduling.schedulerOn} - ${row.scheduling.schedulerOff}`
                                                    ) : (
                                                        "N/A"
                                                    )}
                                                </TableCell>

                                                <TableCell className="text-center" style={{ textAlign: "start", padding: "10px" }}>
                                                    <Switch
                                                        checked={row.scheduling?.triggerStatus ?? false} // Use optional chaining and nullish coalescing
                                                        color="primary"
                                                    />
                                                </TableCell>

                                                <TableCell
                                                    className="text-center"
                                                    style={{ textAlign: "start" }}
                                                >
                                                    <span
                                                        className={`text-${row.status ? "green-600" : "red-600"
                                                            } 
                                                        ${row.status ? "bg-green-200" : "bg-red-100"} 
                                                        rounded-md 
                                                        ${row.status ? "px-4 py-2" : "px-3 py-2"} `}
                                                    >
                                                        {row.status ? "Active" : "Inactive"}
                                                    </span>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                        <TablePagination
                            component="div"
                            rowsPerPageOptions={[10, 25, 50, 100]}
                            count={totalItems}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handlePageChange}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            className="bg-gray-100"
                            labelRowsPerPage="Rows per page:"
                            labelDisplayedRows={({ from, to, count }) =>
                                `${from}-${to} of ${count}`
                            }
                        />
                    </Paper>
                </Box>
            )}
        </>
    )
}

export default ACUserDashboard