import Switch from "@mui/material/Switch";
import MobileTowerIcon from "../../components/apex-chart/HeatMapChart";
import Linechart from "../../components/apex-chart/LineChart";
import { useEffect, useState } from "react";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import axios from "axios";
import {
  formatTimestamp,
  getIndianCurrentTime,
} from "../../common-function/CommonFunction";
import { toast } from "react-toastify";
import { http } from "../../helper/http";
const baseUrl = process.env.REACT_APP_BASE_URL;

export default function DashboardAutomation1({ selectedDevice, devices }: any) {
  const [apiData, setApiData] = useState<any[]>([]);
  const [dashboardSts, setDashboardSts] = useState<any>([]);
  const [pumpStatus, setPumpStatus] = useState({
    pumpStatus1: false,
    pumpStatus2: false,
  });
  const updatePumpStatus = async (pumpNumber: string) => {
    return
    let deviceId = devices?.length > 0 ? devices.map((d:any) => d.deviceId) : '';

    setPumpStatus((prevState: any) => ({
      ...prevState,
      [pumpNumber]: !prevState[pumpNumber],
    }));

    try {
      let time = getIndianCurrentTime();
      const newData = {
        timestamp: time,
        flow: 0,
        pump1: pumpStatus.pumpStatus1 ? "0" : "1",
        pump2: pumpStatus.pumpStatus2,
      };

      const newData1 = {
        timestamp: time,
        flow: 0,
        pump1: pumpStatus.pumpStatus1,
        pump2: pumpStatus.pumpStatus2 ? "0" : "1",
      };
      let message: any;
      switch (pumpNumber) {
        case "pumpStatus1":
          if (pumpStatus.pumpStatus1 && pumpNumber === "pumpStatus1") {
            setApiData((prevData) => [newData, ...prevData]);
            message = {
              pump_1: pumpStatus.pumpStatus1 ? 0 : 1,
              pump_2: dashboardSts.pump2,
            };
          } else {
            message = {
              pump_1: pumpStatus.pumpStatus1 ? 0 : 1,
              pump_2: dashboardSts.pump2,
            };
            setApiData((prevData) => [newData, ...prevData]);
          }
          break;
        case "pumpStatus2":
          if (pumpStatus.pumpStatus2 && pumpNumber === "pumpStatus2") {
            setApiData((prevData) => [newData1, ...prevData]);
            message = {
              pump_2: pumpStatus.pumpStatus2 ? 0 : 1,
              pump_1: dashboardSts.pump1,
            };
          } else {
            message = {
              pump_2: pumpStatus.pumpStatus2 ? 0 : 1,
              pump_1: dashboardSts.pump1,
            };
            setApiData((prevData) => [newData1, ...prevData]);
          }
          break;
      }
      console.log("message: " + JSON.stringify(message));

      // return
      const resp: any = await http("/mqtt-publish?start=1", "POST", {
        deviceId: deviceId,
        message: JSON.stringify(message),
      });
      toast.success(resp.message);
    } catch (err: any) {
      toast.error(err);
    }
  };

  const fetchData = async () => {
    const accessToken = localStorage.getItem("token");

    let response: any = null;
    try {
      response = await axios.get(baseUrl + `/device-data-log`, {
        params: {
          deviceId: selectedDevice,
        },
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      const _data = response.data.map((d: any, i: any) => {
        return {
          timestamp: formatTimestamp(d.timeStamp),
          rssi: d["rssi"],
          pump1: d["pump_1"] || 0,
          pump2: d["pump_2"] || 0,
        };
      });
      // console.log(_data);
      



      setPumpStatus((prevState) => ({
        ...prevState,
        pumpStatus1: _data.length && +_data[0]?.pump1 === 0 ? false : true ,
        pumpStatus2: _data.length && +_data[0]?.pump2 === 0 ? false : true,
      }));
      setApiData(_data);
      setDashboardSts(_data.length ? _data[0] : "");
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
    const intervalId = setInterval(fetchData, 120000);
    return () => clearInterval(intervalId);
  }, []);
  const currentDate = new Date();
  const last24HoursData = apiData.filter((entry) => {
    const entryDate = new Date(entry.timestamp);
    const timeDifference = currentDate.getTime() - entryDate.getTime();
    return timeDifference <= 24 * 60 * 60 * 1000; // Include entries up to the current timestamp
  });

  const pump1line =
    last24HoursData.length > 0
      ? last24HoursData.map(({ timestamp, pump1 }: any) => ({
          timestamp,
          value: +pump1 === 1 ? 10 : 0,
        }))
      : [];

  const pump2line =
    last24HoursData.length > 0
      ? last24HoursData.map(({ timestamp, pump2 }: any) => ({
          timestamp,
          value: +pump2 === 1 ? 9 : 0,
        }))
      : [];

  const dummyData: any = [
    {
      name: "Pump 1",
      color: "#5b98d9",
      data: pump1line.reverse(),
    },
    {
      name: "Pump 2",
      color: "#c94747",
      data: pump2line.reverse(),
    },
  ];
  return (
    <div>
      <div>
        <div className="p-2 mb-4">
          <section>
            <p className=" flex ">
              <MobileTowerIcon levels={dashboardSts?.rssi || 0} time={dashboardSts?.timestamp} />

              {"    Last Data Received at  " + dashboardSts?.timestamp ||
                "  YYYY-MM-DD HH:MM:SS"}
            </p>
          </section>
        </div>
        <div className="border border-gray-400 rounded-md grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-6 xl:grid-cols-4 2xl:gap-7.5 mb-2 p-2">
          <div
            className="rounded-md border border-stroke p-6 bg-white shadow-lg"
            style={{ boxShadow: "lightgrey 3px 3px 30px 5px" }}
          >
            <h2 className="font-medium" style={{ whiteSpace: "nowrap" }}>
              Pump Status 1
            </h2>

            <div className="mt-2 flex items-end justify-between">
              <div>
                <h4
                  className="text-title-md text-3xl"
                  style={{ color: pumpStatus.pumpStatus1 ? "green" : "red" }}
                >
                  {pumpStatus.pumpStatus1 ? "ON" : "OFF"}{" "}
                  {/* Display text indicating switch state */}
                </h4>
              </div>
              <Switch
                className="ml-20"
                checked={pumpStatus.pumpStatus1}
                onChange={() => {
                  updatePumpStatus("pumpStatus1");
                }}
                color="primary"
                sx={{
                  "& .MuiSwitch-switchBase.Mui-checked": {
                    color: "green",
                  },
                  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
                    backgroundColor: "green",
                  },
                  "& .MuiSwitch-track": {
                    backgroundColor: "red",
                  },
                }}
              />
            </div>
          </div>

          <div
            className="rounded-md border border-stroke p-6 bg-white shadow-lg"
            style={{ boxShadow: "lightgrey 3px 3px 30px 5px" }}
          >
            <h2 className="font-medium" style={{ whiteSpace: "nowrap" }}>
              Pump Status 2
            </h2>

            <div className="mt-2 flex items-end justify-between">
              <div>
                <h4
                  className="text-title-md text-3xl"
                  style={{ color: pumpStatus.pumpStatus2 ? "green" : "red" }}
                >
                  {pumpStatus.pumpStatus2 ? "ON" : "OFF"}{" "}
                  {/* Display text indicating switch state */}
                </h4>
              </div>
              <Switch
                className="ml-20"
                checked={pumpStatus.pumpStatus2}
                onChange={() => {
                  updatePumpStatus("pumpStatus2");
                }}
                color="primary"
                sx={{
                  "& .MuiSwitch-switchBase.Mui-checked": {
                    color: "green",
                  },
                  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
                    backgroundColor: "green",
                  },
                  "& .MuiSwitch-track": {
                    backgroundColor: "red",
                  },
                }}
              />
            </div>
          </div>
        </div>
        <div className="pt-[5px]"></div>
        <div
          className="border border-gray-400 rounded-md p-1"
          // style={{ border: "2px solid #EAECF0", padding: "10px" }}
        >
          <div className="mt-7 mb-11">
            <h1 className="relative p-[0.2rem] h-fit w-[15rem]">
              <p>Consumption History</p>
              <p className="text-sm pt-[0.2rem] text-gray-600">
                <AccessTimeIcon className="pr-[0.2rem]" />
                Realtime - Last 24 Hour | IST
              </p>
            </h1>
            <Linechart stroke={true} yAxisName="Status" data={dummyData} />
          </div>
        </div>
      </div>
    </div>
  );
}
